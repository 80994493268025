import {Media, Province} from '@/store/enums/pipeline_enums';

export const provinceByBrands: any = {
  DA: [Province.QC, Province.OTHER],
  DI: [Province.ON, Province.AB, Province.OTHER],
  DAN: [Province.ON, Province.NB, Province.AB, Province.OTHER],
};

export const perspectivePercentMonthByBrands: any = {
  DA: {
    '1': 6,
    '2': 7,
    '3': 7,
    '4': 7,
    '5': 7,
    '6': 7,
    '7': 8,
    '8': 10,
    '9': 12,
    '10': 11,
    '11': 12,
    '12': 6,
  },
  DI: {
    '1': 12,
    '2': 11,
    '3': 7,
    '4': 7,
    '5': 7,
    '6': 7,
    '7': 8,
    '8': 6,
    '9': 7,
    '10': 7,
    '11': 12,
    '12': 9,
  },
  DAN: {
    '1': 7,
    '2': 7,
    '3': 7,
    '4': 12,
    '5': 11,
    '6': 12,
    '7': 10,
    '8': 6,
    '9': 7,
    '10': 7,
    '11': 7,
    '12': 7,
  },
};

export const allMedias: Media[] = [
  Media.AD_WEB,
  Media.ADDRESSED_DM,
  Media.EMAIL,
  Media.PHONE,
  Media.OUTDOOR,
  Media.PPC_BRANDED,
  Media.PPC_UNBRANDED,
  Media.RADIO,
  Media.TV,
  // Media.UNADDRESSED_DM, // Media Removed, not used anymore
  // Media.NEWSPAPER, // Media Removed, not used anymore
];
