














































































































































































































































































































































import { Component, Emit, Prop } from 'vue-property-decorator';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';
import { namespace } from 'vuex-class';
import {
  FileInfo,
  FileRequest,
  FileWithName,
  FileWithNameAndStatus,
  ParsedFileInfo,
  UploadFilesRequest,
  UploadHistoryInfo,
} from '@/store/models/upload_models';
import { UISnackBar } from '@/store/models/ui_models';
import LevioLabLoader from '@/components/shared/LevioLabLoader.vue';
import UploadHistory from '@/components/history/UploadHistory.vue';
import { ProcessingJob } from '@/store/models/processing_jobs_models';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import CSVReaderDialog from '@/components/shared/CSVReaderDialog.vue';
import { PipelineOutput, UploadValidationData } from '@/store/models/pipeline_models';
import SimpleInfo from '@/components/shared/SimpleInfo.vue';
import dayjs from 'dayjs';
import LevioLabToolBarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';
import LevioLabToolBarCTA from '@/components/shared/levioToolBar/LevioLabToolBarCTA.vue';
import ETLSuggestionDialog from '@/components/etl/ETLSuggestionDialog.vue';
import PipelineService from '@/store/services/PipelineService';
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import Rules from '@/utils/rules';
import { RequestErrorType } from '@/store/enums/api_enum';
import { translateEnum } from '@/utils/localisation';

const upload = namespace('UploadStore');
const processing = namespace('ProcessingJobStore');
const globalUI = namespace('GlobalUIStore');
const pipeline = namespace('PipelineStore');

@Component({
  components: {
    UploadHistory,
    LevioLabLoader,
    LevioLabTitle,
    CSVReaderDialog,
    SimpleInfo,
    LevioLabToolbar,
    closeButton: LevioLabToolBarCloseButton,
    ctaButton: LevioLabToolBarCTA,
    etlSuggestionDialog: ETLSuggestionDialog,
  },
})
export default class UploadFilesCard extends mixins(validationMixin) {
  private confirmText: string = this.$t('common.cta.upload').toString();
  private confirmIcon: string = 'fa-cloud';
  private filesWithStatus: FileWithNameAndStatus[] = [];
  private temporaryFiles: FileWithName[] = [];
  private uploadJobId: string | null = null;

  private isValidating: boolean = false;
  private isLoading: boolean = false;
  private isFilesWithError: boolean = false;
  private isValidationStep: boolean = true;
  private isELTSuggestDialogOpen: boolean = false;

  private isDeleting: boolean = false;

  // Fields
  @Validate({ required })
  private attributionTitle: string | null = null;
  private attributionDescription: string = '';
  private requestError: string = '';

  // CSV Reader
  private isCSVReaderVisible: boolean = false;
  private fileToDisplay!: FileWithName | null;
  private summaryFileToDisplay!: FileWithName | null;
  private errorFileToDisplay!: FileWithName | null;

  @upload.Getter
  private requiredFileList!: FileInfo[];

  @upload.Getter
  private filesToBeUploaded!: FileWithName[];

  @upload.Getter
  private fileData!: FileWithName | null;

  @pipeline.Getter
  private uploadValidationData!: UploadValidationData | null;

  @pipeline.Mutation
  private clearUploadValidationData!: () => void;

  @upload.Getter
  private uploadHistory!: UploadHistoryInfo[];

  @upload.Mutation
  private setFilesToBeUploaded!: (files: FileWithName[]) => void;

  @upload.Action
  private syncHistory!: () => Promise<UploadHistoryInfo[]>;

  @upload.Action
  private fetchFileData!: (fileRequest: FileRequest) => Promise<FileWithName>;

  @processing.Action
  private syncProcessingJob!: () => Promise<ProcessingJob[]>;

  @upload.Action
  private addFileToUpload!: (file: FileWithName) => void;

  @upload.Action
  private uploadFiles!: (uploadFilesRequest: UploadFilesRequest) => Promise<string | RequestErrorType | null>;

  @upload.Action
  private deleteUpload!: (folderName: string) => Promise<void>;

  @globalUI.Action
  private showSnackBar!: (payload: UISnackBar) => UISnackBar;

  @pipeline.Action
  private fetchUploadValidationData!: (jobId: string) => Promise<UploadValidationData>;

  @Prop({
    default: true,
  })
  private isUploadVerified!: boolean;

  @Prop()
  private hideToolbar?: boolean | null;

  private get lastYear(): number {
    return dayjs().year() - 1;
  }

  private get files(): FileWithName[] {
    if (this.filesToBeUploaded) {
      return this.filesToBeUploaded;
    } else {
      return [];
    }
  }

  private get matchedParsedRequiredFileList(): ParsedFileInfo[] {
    return this.parsedRequiredFileList.filter((item) => item.matched);
  }

  private get unmatchedParsedRequiredFileList(): ParsedFileInfo[] {
    return this.parsedRequiredFileList.filter((item) => !item.matched);
  }

  private get parsedRequiredFileList(): ParsedFileInfo[] {
    const parsedFile: ParsedFileInfo[] = [];

    if (this.requiredFileList) {
      for (const requiredFile of this.requiredFileList) {
        let match;
        if (this.files.length > 0) {
          match = this.files.find((file) => requiredFile.pattern.test(file.file.name));
        }

        parsedFile.push({
          name: requiredFile.name,
          matched: match !== undefined,
          description: requiredFile.description,
          example: requiredFile.example,
          fileName: match ? match.file.name : '',
        });
      }
    }

    parsedFile.sort((file1, file2) => {
      if (file1.matched && !file2.matched) {
        return 1;
      } else if (!file1.matched && file2.matched) {
        return -1;
      } else {
        return file1.name.localeCompare(file2.name);
      }
    });

    return parsedFile;
  }

  private get isUploadValid(): boolean {
    if (this.isLoading) {
      return false;
    }

    if (this.isValidationStep) {
      let isValid = false;

      if (!this.isUploadVerified) {
        isValid = this.files.length !== 0;
      } else if (this.parsedRequiredFileList) {
        isValid = true;
        for (const file of this.parsedRequiredFileList) {
          isValid = isValid && file.matched;
        }
      }

      return isValid;

    } else {
      let isValid = true;

      this.filesWithStatus.forEach((file) => {
        if (file.errorCodes.length > 0) {
          isValid = true; // Button should be active in that case.
          this.confirmText = this.$t('common.cta.redo').toString();
          this.confirmIcon = 'fa-redo';
          this.isFilesWithError = true;
        }
      });

      return isValid;
    }
    // eslint-enable  no-unreachable
  }

  private getTitleErrorMessages(field: Validation & ValidationProperties<any>): string[] {
    if (this.requestError !== '') {
      return [this.requestError];
    } else {
      return this.getRequiredErrors(field);
    }
  }

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }

  private addFile(e: DragEvent) {
    if (e && e.dataTransfer) {
      const droppedFiles: FileList = e.dataTransfer.files;
      if (!droppedFiles) {
        return;
      }

      ([...droppedFiles]).forEach((f: File) => {
        const requiredFile = this.requiredFileList.find((file) => file.pattern.test(f.name));
        if (!this.isUploadVerified) {
          this.addFileToUpload({
            file: f,
            name: f.name,
          });
        } else if (requiredFile) {
          if (!this.files.find((file) => file.file.name === f.name)) {
            this.addFileToUpload({
              file: f,
              name: requiredFile.description.replace('files.', '').trim(),
            });
          } else {
            this.showSnackBar({
              text: this.$t('upload.error2') as string,
              visible: true,
              color: 'warning',
            });
          }
        } else {
          this.showSnackBar({
            text: this.$t('upload.error1') as string,
            visible: true,
            color: 'error',
          });
        }
      });
    }
  }

  private addFileFromInput(files: File[]) {
    if (files && files.length > 0) {
      files.forEach((f) => {
        const requiredFile = this.requiredFileList.find((file) => file.pattern.test(f.name));
        if (!this.isUploadVerified) {
          this.addFileToUpload({
            file: f,
            name: f.name,
          });
        } else if (requiredFile) {
          if (!this.files.find((file) => file.file.name === f.name)) {
            this.addFileToUpload({
              file: f,
              name: requiredFile.description.replace('files.', '').trim(),
            });
          } else {
            this.showSnackBar({
              text: this.$t('upload.error2').toString(),
              visible: true,
              color: 'warning',
            });
          }
        } else {
          this.showSnackBar({
            text: this.$t('upload.error1').toString(),
            visible: true,
            color: 'error',
          });
        }
      });
    }
  }

  private removeFile(file) {
    this.setFilesToBeUploaded(this.files.filter((f) => {
      return f.file !== file;
    }));
  }

  private findFile(fileName) {
    return this.files.find((file) =>
      file.file.name.localeCompare(fileName) === 0);
  }

  private async uploadAndValidate() {

    const request: string | RequestErrorType | null = await this.uploadFiles({
      validated: this.isUploadVerified,
      jobName: this.attributionTitle as string,
      jobDescription: this.attributionDescription,
    });
    if (request === RequestErrorType.TITLE_DUPLICATE) {
      this.requestError = translateEnum(request! as RequestErrorType, 'requestErrorType') as string;
    } else {

      this.uploadJobId = request;

      if (this.filesToBeUploaded.length === 0) {
        this.clearUploadValidationData();
        this.isValidating = true;

        const validationPullInterval = window.setInterval(() => {
          if (this.uploadValidationData) {
            clearInterval(validationPullInterval);

            const validationData = this.uploadValidationData!.additionalProperties.validation_results;

            // Building status list for UI display.
            this.temporaryFiles.forEach((localFile) => {
              for (const key of Object.keys(validationData)) {
                if (key === localFile.name) {
                  this.filesWithStatus.push({
                    name: localFile.name,
                    file: localFile.file,
                    errorCodes: validationData[key].error_codes,
                  });
                }
              }
            });

            this.isValidating = false;
            this.showUploadPageContent();
          }

          this.fetchUploadValidationData(this.uploadJobId!);
        }, 5000);

      }
    }
  }

  private showUploadPageContent() {
    this.isValidationStep = false;
    this.confirmText = this.$t('common.cta.proceed').toString();
    this.confirmIcon = 'fa-calculator';
  }

  private showValidationPageContent() {
    this.setFilesToBeUploaded(this.temporaryFiles);
    this.filesWithStatus = [];
    this.confirmText = this.$t('common.cta.upload').toString();
    this.confirmIcon = 'fa-cloud';
    this.isValidationStep = true;
  }

  private async confirmAction() {
    if (this.isValidationStep) {
      this.temporaryFiles = this.filesToBeUploaded;
      await this.uploadAndValidate();
    } else if (!this.isFilesWithError) {
      await this.openETLSuggestionDialog();
    } else {
      this.showValidationPageContent();
    }
  }

  // CSV Reader
  private clearReaderData() {
    this.fileToDisplay = null;
    this.summaryFileToDisplay = null;
    this.errorFileToDisplay = null;
  }

  private showCSVReader(csvInfo: FileWithName) {
    this.clearReaderData();
    this.fileToDisplay = csvInfo;
    this.isCSVReaderVisible = true;
  }

  private async showCSVReaderWithSummary(csvInfo: FileWithName) {
    this.clearReaderData();

    const fileName: string = csvInfo.name + '_summary.csv';
    await this.fetchFileData({
      jobId: this.uploadValidationData!.jobId,
      fileName,
    });

    this.fileToDisplay = csvInfo;
    this.summaryFileToDisplay = this.fileData;
    this.isCSVReaderVisible = true;
  }

  private async showCSVReaderWithErrors(csvInfo: FileWithName) {
    this.clearReaderData();

    const fileName: string = csvInfo.name + '_errors.csv';
    await this.fetchFileData({
      jobId: this.uploadValidationData!.jobId,
      fileName,
    });

    this.errorFileToDisplay = this.fileData;
    this.isCSVReaderVisible = true;
  }

  private closeCSVDialog() {
    this.isCSVReaderVisible = false;
  }

  private openETLSuggestionDialog(): void {
    this.isELTSuggestDialogOpen = true;
  }

  private async deleteUploadDialog(): Promise<void> {
    this.isDeleting = true;
    const output: PipelineOutput | null = await PipelineService.getPipelineOutputById(this.uploadJobId as string);
    if (output) {
      await this.deleteUpload(output!.folderName);
      this.isELTSuggestDialogOpen = false;
      this.closeDialog();
    }
    this.isDeleting = false;
  }

  private saveCloseELTSuggestDialog(): void {
    this.isELTSuggestDialogOpen = false;
    this.closeDialog();
  }

  @Emit('openETLJob')
  private openETLJobDialog(): void {
    this.isELTSuggestDialogOpen = false;
  }

  @Emit('closeDialog')
  private closeDialog(): void {
    return;
  }
}
