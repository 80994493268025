






















import {Component, Vue} from 'vue-property-decorator';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';


@Component({components: {
    LevioLabTitle,
  }})
export default class ConfigurationLandingPage extends Vue {
}
