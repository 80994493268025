



























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CompletePasswordForm } from '@/store/models/auth_models';
import Rules, { passwordValid } from '@/utils/rules';
import { validationMixin } from 'vuelidate';
import { Validate } from 'vuelidate-property-decorators';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { form } from '@/utils/common';

const auth = namespace('AuthStore');

@Component
export default class CompletePassword extends mixins(validationMixin) {

  @auth.Action
  private completePassword!: (completePasswordData: CompletePasswordForm) => Promise<void>;

  @auth.Action
  private logout!: () => Promise<void>;

  @auth.Getter
  private isUserToCompleteNotNull!: boolean;

  private isValid: boolean = false;

  @Validate({ required, minLength: minLength(8), passwordValid })
  private newPassword: string = '';

  @Validate({ required, sameAs: sameAs('newPassword') })
  private confirmPassword: string = '';

  public created() {
    if (!this.isUserToCompleteNotNull) {
      this.logout().then(() => this.$router.replace('/signin'));
    }
  }

  private validate(): void {
    this.isValid = form(this.$refs.completeForm).validate();
    if (this.$v.$anyDirty && this.isValid) {
      const formToSend: CompletePasswordForm = {
        newPassword: this.newPassword!,
      };
      this.completePassword(formToSend).then(() => {
        this.logout().then(() => {
          this.$router.replace('/signin');
        });
      }).catch(() => {
        // removing trace
      });
    }
  }

  private get passwordErrors(): string[] {
    return Rules.getPasswordValidationMessages(this.$v.newPassword);
  }

  private get confirmPasswordErrors(): string[] {
    return Rules.getConfirmPasswordValidationMessages(this.$v.confirmPassword);
  }
}
