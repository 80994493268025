







import { Component, Prop, Mixins } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { AmChartSerie } from '@/store/models/amcharts_models';
import { PointerOrientation } from '@amcharts/amcharts4/core';
import ChartFrame from '@/components/shared/amcharts/base/ChartFrame.vue';
import ChartToolsMixin from '@/components/shared/amcharts/base/ChartToolsMixin';

@Component({
  components: {
    ChartFrame,
  },
})
export default class AmBarChart extends Mixins(ChartToolsMixin) {

  @Prop({
    default: undefined,
  })
  public max!: number | undefined;

  @Prop({
    default: false,
  })
  public isComparative!: boolean;

  protected mounted() {
    this.createBarChart();
  }

  private createBarChart(): void {

    if (this.isComparative) {
      this.setComparativeColorTheme();
    } else {
      this.setDefaultAmChartsColorTheme();
    }
    const chart = am4core.create(this.$refs[this.refName] as HTMLElement, am4charts.XYChart);

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = this.category;
    categoryAxis.fontSize = 10;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.max = this.max ? this.max : undefined;

    chart.legend = new am4charts.Legend();

    this.series.forEach((serieItem: AmChartSerie) => {
      const serie = chart.series.push(new am4charts.ColumnSeries());
      serie.name = serieItem.name;
      serie.dataFields.categoryX = this.category;
      serie.dataFields.valueY = serieItem.valueY;
      serie.stacked = serieItem.stacked ? serieItem.stacked : false;
      serie.columns.template.tooltipText = serieItem.toolTipText ? serieItem.toolTipText : '{valueY}';
      serie.tooltip!.pointerOrientation =
          serieItem.pointerOrientation ? serieItem.pointerOrientation as PointerOrientation : 'vertical';
    });

    this.chart = chart;
    this.chart.data = this.data;

  }
}
