import {DialogContentType, UIDialog} from '@/store/models/ui_models';


export class DialogComponentFactory implements ComponentFactory<DialogContentType> {
  public static getComponentByType(dialogContent: DialogContentType): string {
    return this.localInstance.getComponentByType(dialogContent);
  }

  public static getDialogByType(dialogContent: DialogContentType): UIDialog {
    return this.localInstance.getDialogByType(dialogContent);
  }

  private static localInstance = new DialogComponentFactory();

  public getComponentByType(dialogContent: DialogContentType): string {
    switch (dialogContent) {
      case DialogContentType.INACTIVITY:
        return 'DialogContentInactivity';

      case DialogContentType.AGREEMENT:
        return 'DialogContentAgreement';

      case DialogContentType.LOGGED_OUT:
        return 'DialogContentLoggedOut';

      default:
        return 'DialogContentNone';

    }
  }

  public getDialogByType(dialogContent: DialogContentType): UIDialog {
    const dialogContentTypeString = this.getComponentByType(dialogContent);

    switch (dialogContent) {

      case DialogContentType.INACTIVITY:
        return {
          component: dialogContentTypeString,
          width: 400,
          isPersistent: false,
        };

      case DialogContentType.AGREEMENT:
        return {
          component: dialogContentTypeString,
          width: 800,
          isPersistent: true,
        };

      case DialogContentType.LOGGED_OUT:
        return {
          component: dialogContentTypeString,
          width: 400,
          isPersistent: false,
        };

      default:
        return {
          component: dialogContentTypeString,
          width: 1,
          isPersistent: false,
        };
    }
  }
}
