


















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';

import { Validate } from 'vuelidate-property-decorators';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import Rules, { passwordValid } from '@/utils/rules';

import { UISnackBar } from '@/store/models/ui_models';
import { CognitoUserData } from '@/store/models/auth_models';

const auth = namespace('AuthStore');
const globalUI = namespace('GlobalUIStore');

@Component({
  components: {},
})
export default class SignIn extends mixins(validationMixin) {
  @auth.Getter
  private authorized!: boolean;

  @auth.Action
  private login!: (authData: CognitoUserData) => Promise<void>;

  @globalUI.Action
  private showSnackBar!: (payload: UISnackBar) => UISnackBar;

  private isLoading: boolean = false;
  private showPassword: boolean = false;
  private isValid: boolean = false;

  @Validate({ required, email })
  private username: string = '';

  @Validate({ required, minLength: minLength(8), passwordValid })
  private password: string = '';

  private mounted(): void {
    this.$v.username.$touch();
    this.$v.password.$touch();
  }

  private validate(): void {
    this.isLoading = true;
    this.isValid = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (this.$v.$anyDirty && this.isValid) {
      const formData: CognitoUserData = {
        email: this.username.toLowerCase(),
        password: this.password,
      };
      this.login(formData).then(() => {
        this.isLoading = false;
        if (this.authorized) {
          this.$router.replace('/dashboard');
        }
      }).catch(() => {
        this.isLoading = false;
      });
    }
  }

  private get usernameErrors(): string[] {
    return Rules.getEmailValidationMessages(this.$v.username);
  }

  private get passwordErrors(): string[] {
    return Rules.getPasswordValidationMessages(this.$v.password);
  }
}
