
















import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {VMoney} from 'v-money';
import {getBrowserLanguageShort, getNumberFromComplexString} from '@/utils/common';

@Component({
  directives: {
    money: VMoney,
  },
})
export default class CurrencyField extends Vue {
  @Prop({
    required: true,
  })
  public value!: string | number;

  @Prop({
    required: true,
  })
  public label!: string;

  @Prop({
    default: '',
  })
  public hint!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  public errorMessages!: any;

  private fieldValue: string = '';

  protected created(): void {
    this.fieldValue = this.value as string;
  }

  @Watch('fieldValue')
  private onFieldValueChanged(): void {
    this.emitValue();
  }

  @Emit('input')
  private emitValue(): number {
    return getNumberFromComplexString(this.fieldValue);
  }

  @Emit('blur')
  private onBlur(): void {
    return;
  }

  protected get money(): any {
    const language: string = getBrowserLanguageShort();
    if (language === 'fr') {
      return {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: ' $',
        precision: 2,
        allowBlank: true,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      };
    }
    return {
      decimal: '.',
      thousands: ',',
      prefix: '$ ',
      suffix: '',
      precision: 2,
      allowBlank: true,
      min: 0,
      max: Number.MAX_SAFE_INTEGER,
    };
  }
}
