


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AlertWidget extends Vue {
  @Prop({
    default: 'warning',
  })
  public type!: string;

  @Prop({
    default: 'fa-exclamation-triangle',
  })
  public icon!: string;

}
