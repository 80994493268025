














































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import LevioLabLoader from '@/components/shared/LevioLabLoader.vue';

@Component({
  components: {
    LevioLabToolbar,
    LevioLabLoader,
  },
})
export default class ETLSuggestionDialog extends Vue {
  // Properties
  @Prop({
    required: true,
  })
  public isOpen!: boolean;

  @Prop({
    required: false,
  })
  public isLoading!: boolean;

  // Local Variables

  // Public Functions

  @Emit('startETL')
  public startETL(): void {
    return;
  }

  @Emit('closeDialog')
  public closeDialog(): void {
    return;
  }

  @Emit('deleteUpload')
  public deleteUpload(): void {
    return;
  }

// Private Functions

}
