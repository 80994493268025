var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.processingOutputHeaders,"items":_vm.outputs,"sort-by":"jobId","sort-desc":"","item-key":"id","loading":_vm.isLoading,"search":_vm.search,"footer-props":{
        itemsPerPageText: _vm.$t('common.table.itemsPerPage'),
        itemsPerPageOptions: [10, 30, 50]
      }},scopedSlots:_vm._u([{key:"item.creationTime",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLocalDateStringFromJobId(item.jobId))+" ")]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[(item.name && item.name !== 'NA')?[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"caption ml-1"},[_vm._v("("+_vm._s(item.jobId)+")")])]:[_vm._v(" "+_vm._s(item.jobId)+" ")]],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }