
















































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Brand, Province } from '@/store/enums/pipeline_enums';
import { translateEnum } from '@/utils/localisation';
import Instructions from '@/components/shared/Instructions.vue';
import { validationMixin } from 'vuelidate';
import { Validate } from 'vuelidate-property-decorators';
import { minValue, required } from 'vuelidate/lib/validators';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    Instructions,
  },
})
export default class BudgetAllocation extends mixins(validationMixin) {
  @Prop({
    default: true,
  })
  public isRequired!: boolean;

  @Prop({
    default: false,
  })
  public isHidden!: boolean;

  @Prop({
    required: true,
  })
  public brand!: Brand;

  @Prop({
    required: true,
  })
  public provinces!: Province[];

  @Validate({ required, minValue: minValue(0.01) })
  private provincesBudget: { [key: string]: number; } = {};

  @Emit('input')
  public emitData(): { [key: string]: number; } {
    return this.provincesBudget;
  }

  private get totalProvinces(): number {
    let percentTotal: number = 0;
    Object.values(this.provincesBudget).forEach((value) => {
      percentTotal += Number(value);
    });
    return percentTotal;
  }

  private get isFailingTotal(): boolean {
    if (Object.values(this.provincesBudget).length < this.provinces.length) {
      return false;
    }
    return this.totalProvinces !== 100;
  }

  private getProvinceName(province: Province): string {
    return translateEnum(province, 'province') as string;
  }

  @Watch('provincesBudget', { deep: true })
  private onProvincesBudgetChanged(): void {
    this.emitData();
  }

  private provincesValidationErrors(province: Province): string[] {
    let errors: string[] = [];
    if (this.provincesBudget[this.provinces[0]] == 0
        && province == this.provinces[0]
    ) {
      errors.push(this.$t('common.validation.number.higherThanZero') as string);
    }
    return errors;
  }
}
