






import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class Instructions extends Vue {
  @Prop({
    default: 'info--text text--darken-2',
  })
  private textColor!: string;
}
