































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import { translateEnum, translateEnumVSelect } from '@/utils/localisation';
import { provinceByBrands } from '@/utils/business-logic';
import Rules from '@/utils/rules';
import {
  Brand,
  BudgetPlanType,
  OptimizationBudgetType,
  OptimizationObjectiveType,
  Province,
} from '@/store/enums/pipeline_enums';

import {
  BudgetDefinitionData,
  BudgetPlanConfig,
  BudgetPlanExecutionRequest,
  PipelineOutput,
} from '@/store/models/pipeline_models';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import BudgetDefinition from '@/components/budgetPlan/formComponents/BudgetDefinition.vue';
import BudgetAllocation from '@/components/budgetPlan/formComponents/BudgetAllocation.vue';
import SimpleInfo from '@/components/shared/SimpleInfo.vue';
import BudgetMediaBreakdown from '@/components/budgetPlan/formComponents/BudgetMediaBreakdown.vue';
import Instructions from '@/components/shared/Instructions.vue';
import { form, isError, nextYears } from '@/utils/common';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { AnnualStoreMixin } from '@/mixins/store/AnnualStoreMixin';
import { AnnualOfficialData } from '@/store/models/annual_models';
import PipelineService from '@/store/services/PipelineService';
import LevioLabToolBarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';
import LevioLabToolBarCTA from '@/components/shared/levioToolBar/LevioLabToolBarCTA.vue';
import { RequestError } from '@/store/models/common_models';
import { RequestErrorType } from '@/store/enums/api_enum';

const globalUI = namespace('GlobalUIStore');
const pipeline = namespace('PipelineStore');

@Component({
  components: {
    LevioLabToolbar,
    closeButton: LevioLabToolBarCloseButton,
    ctaButton: LevioLabToolBarCTA,
    LevioLabTitle,
    BudgetDefinition,
    BudgetAllocation,
    SimpleInfo,
    BudgetMediaBreakdown,
    Instructions,
  },
})
export default class OptimizationCreationDialog extends mixins(AnnualStoreMixin) {
  @Prop()
  private closeDialog!: () => void;

  @pipeline.Action
  private startBudgetOptimizationProcessingJob!: (request: BudgetPlanExecutionRequest) => Promise<void | RequestError>;

  @globalUI.Getter
  private isDemoMode!: boolean;

  private syncInterval!: number;

  private valid: boolean = false;
  private requestError: string = '';

  private budgetPlanType: BudgetPlanType = BudgetPlanType.OPTIMIZATION;

  @Validate({ required })
  private optimizationTitle: string = '';

  private optimizationDescription: string = '';

  @Validate({ required })
  private referenceYear: number | null = null;

  @Validate({ required })
  private selectedYear: number | null = null;

  @Validate({ required })
  private selectedBrand: Brand | null = null;

  @Validate({ required })
  private selectedMethod: OptimizationBudgetType | null = null;

  @Validate({ required })
  private selectedObjective: OptimizationObjectiveType | null = null;

  private budgetDefinition: BudgetDefinitionData = {};
  private budgetAllocation: { [key: string]: number; } = {};
  private mediasBudget: { [key: string]: number; } = {};

  public created() {
    this.fetchData();
  }

  public destroyed() {
    if (this.syncInterval) {
      clearTimeout(this.syncInterval);
    }
  }

  private async fetchData(): Promise<void> {
    await this.fetchAnnualOfficialData();
    this.resetForm();
  }

  private async createBudgetOptimizationProcessingJob() {
    form(this.$refs.optimizationForm).validate();
    this.valid = this.isReadyToSubmit;
    if (this.valid) {
      const optimizationConfig: BudgetPlanConfig = {
        category: this.budgetPlanType,
        year: this.selectedYear!,
        evaluatedYear: this.referenceYear!,
        brand: this.selectedBrand as Brand,
        method: this.selectedMethod as OptimizationBudgetType,
        objective: this.selectedObjective as OptimizationObjectiveType,
        budgetDefinition: this.budgetDefinition,
      };

      if (this.selectedObjective !== OptimizationObjectiveType.MIN_BUDGET) {
        optimizationConfig.budgetAllocation = this.budgetAllocation;
      }

      if (this.selectedMethod === OptimizationBudgetType.MEDIA) {
        optimizationConfig.mediasBreakdown = this.mediasBudget;
      }

      const optimizationRequest: BudgetPlanExecutionRequest = {
        jobName: this.optimizationTitle,
        jobDescription: this.optimizationDescription,
        config: optimizationConfig,
      }

      const id_brand = `${this.referenceYear!.toString()}-${this.selectedBrand!.toString().toLowerCase()}`;
      const annual_brand_dataset: AnnualOfficialData | null = await this.fetchOfficialAnnualDataById(id_brand);
      let pipeline_output_dataset: PipelineOutput | null = null;
      if (annual_brand_dataset && annual_brand_dataset.datasetJobId) {
        pipeline_output_dataset = await PipelineService.getPipelineOutputById(annual_brand_dataset.datasetJobId!);
      }

      if (pipeline_output_dataset) {
        optimizationRequest.outputId = pipeline_output_dataset.id;
        // console.log(JSON.stringify(optimizationRequest));
        const error: RequestError | void = await this.startBudgetOptimizationProcessingJob(optimizationRequest)
        if (error && isError(error)) {
          if ((error as RequestError).type === RequestErrorType.TITLE_DUPLICATE) {
            this.requestError = translateEnum((error as RequestError).type as RequestErrorType, 'requestErrorType') as string;
          }
        } else {
          this.resetForm();

          if (this.closeDialog) {
            this.closeDialog();
          }

          await this.$router.replace('/processing');
        }
      }
    }
  }

  private resetForm(): void {
    this.valid = false;
    this.optimizationTitle = '';
    this.optimizationDescription = '';
    this.referenceYear = null;
    this.selectedYear = null;
    this.selectedBrand = null;
    this.selectedMethod = null;
    this.selectedObjective = null;
    this.budgetDefinition = {};
    this.budgetAllocation = {};
    this.$v.$reset();
    this.$nextTick();
    this.budgetPlanType = BudgetPlanType.OPTIMIZATION;
  }

  @Watch('selectedBrand')
  private onSelectedBrandChanged(): void {
    this.selectedMethod = null;
    this.selectedObjective = null;
    this.budgetAllocation = {};
    form(this.$refs.optimizationForm).resetValidation();
  }

  @Watch('selectedMethod')
  private onSelectedMethodChanged(): void {
    this.selectedObjective = null;
    this.budgetAllocation = {};
    form(this.$refs.optimizationForm).resetValidation();
  }

  /* GETTERS */

  private get mainProvinceLabel(): string {
    return translateEnum(this.provinces[0], 'province') as string;
  }

  private getTitleErrorMessages(field: Validation & ValidationProperties<any>): string[] {
    if (this.requestError !== '') {
      return [this.requestError];
    } else {
      return this.getRequiredErrors(field);
    }
  }

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }

  private get years(): number[] {
    return nextYears();
  }

  private get referenceYears(): number[] {
    let years: number[] = this.annualOfficialDataItems
      .filter((dataItem: AnnualOfficialData) => {
        return dataItem.attributionsJobId;
      })
      .map((dataItem: AnnualOfficialData) => {
        return dataItem.year;
      })
      .sort((a, b) => a > b ? 1 : -1);
    return years;
  }

  private get brands(): Array<{ text: string, value: Brand }> {
    return translateEnumVSelect(Brand, 'brands').filter((item: { text: string, value: Brand }) => {
      return item.value !== Brand.SYNERGY;
    });
  }

  private get methods(): { text: string, value: OptimizationBudgetType }[] {
    let methods: { text: string, value: any }[] = translateEnumVSelect(OptimizationBudgetType, 'methodType');
    return methods
  }

  private get objectives() {
    const rawObjectives: Array<{ text: string, value: OptimizationObjectiveType }> =
      translateEnumVSelect(OptimizationObjectiveType, 'objective');
    return rawObjectives.filter((item: { text: string, value: string }) => {
      return item.value === OptimizationObjectiveType.MAX_QUOTES ||
        (this.selectedMethod === OptimizationBudgetType.FIXED &&
          this.budgetPlanType === BudgetPlanType.OPTIMIZATION);
    });
  }

  private get provinces(): Province[] {
    return provinceByBrands[this.selectedBrand as string];
  }

  private get acquisitionBudget(): number {
    return this.budgetDefinition.acquisitionBudget || 0;
  }

  private get growthPlan(): number {
    return this.budgetDefinition.growthPlan || 0;
  }

  private get caisseAssurancesBudget(): number {
    return this.budgetDefinition.caisseAssurance || 0;
  }

  private get usableRemainingAmount(): number {
    let usable: number = 0;
    if (this.isBudgetMedia) {
      usable = Number((this.budgetAllocation[this.provinces[0]] * this.acquisitionBudget) / 100) || 0;
    } else {
      usable = Number((this.budgetAllocation[this.provinces[0]] * this.acquisitionBudget) / 100) + Number(this.caisseAssurancesBudget) || 0;
    }
    return Math.round(usable * 100) / 100;
  }

  private get remainingBudget(): number {
    let usedBudget: number = 0;
    Object.values(this.mediasBudget).forEach((value) => {
      usedBudget += value;
    });
    return this.usableRemainingAmount - Math.round(usedBudget * 100) / 100;
  }

  private get totalProvinces(): number {
    let percentTotal: number = 0;
    Object.values(this.budgetAllocation).forEach((value) => {
      percentTotal += Number(value);
    });
    return percentTotal;
  }

  /* FLAGS */

  private get isReadyForParameters(): boolean {
    return this.selectedYear !== 0 && !!this.selectedBrand && !!this.selectedMethod && !!this.selectedObjective;
  }

  private get isFailingTotal(): boolean {
    if (Object.values(this.budgetAllocation).length < this.provinces.length) {
      return true;
    }
    return this.totalProvinces !== 100;
  }

  private get isReadyToSubmit(): boolean {
    let isValid = this.valid;
    if (isValid) {
      if (this.selectedMethod === OptimizationBudgetType.MEDIA) {
        isValid = this.acquisitionBudget > 0 && this.usableRemainingAmount > 0
          && this.remainingBudget === 0;
      }
      if (this.selectedMethod === OptimizationBudgetType.FIXED) {
        if (this.selectedObjective === OptimizationObjectiveType.MAX_QUOTES) {
          isValid = this.acquisitionBudget > 0 && this.usableRemainingAmount > 0;
        }
        if (this.selectedObjective === OptimizationObjectiveType.MIN_BUDGET) {
          isValid = this.growthPlan > 0;
        }
      }
    }
    return isValid;
  }

  private get isBudgetMedia(): boolean {
    return this.selectedMethod === OptimizationBudgetType.MEDIA;
  }

  private get isMinBidget(): boolean {
    return this.selectedObjective === OptimizationObjectiveType.MIN_BUDGET;
  }

  private get isMaxQuotes(): boolean {
    return this.selectedObjective === OptimizationObjectiveType.MAX_QUOTES;
  }

  private get isCaisseAssurances(): boolean {
    return this.selectedBrand === Brand.DA;
  }

  private get budgetPlanTypeLabel(): string {
    return translateEnum(this.budgetPlanType, 'budgetPlanType') as string;
  }

  private get predictionType(): BudgetPlanType {
    return BudgetPlanType.PREDICTION;
  }

  private get optimizationType(): BudgetPlanType {
    return BudgetPlanType.OPTIMIZATION;
  }
}
