
















import {Vue, Component} from 'vue-property-decorator';

@Component({
  components: {

  },
})
export default class LevioLabToolbar extends Vue {

}
