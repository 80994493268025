









import { Component, Mixins } from 'vue-property-decorator';
import CSVDownloadMixin from '@/mixins/CSVDownloadMixin';

@Component({
  components: {},
})
export default class ContextualDrawer extends Mixins(CSVDownloadMixin) {
  private isMini: boolean | null = true;
  private showDrawer: boolean | null = true;
}
