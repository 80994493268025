






























import {Component, Emit, Vue} from 'vue-property-decorator';
import {pbiSchedule} from "@/utils/data/pbi_schedule";
import SimpleInfo from "@/components/shared/SimpleInfo.vue";
import {conceptsThemes} from "@/plugins/themes";
import LevioLabToolbar from "@/components/shared/levioToolBar/LevioLabToolbar.vue";
import LevioLabToolbarCloseButton from "@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue";

@Component({
  components: {
    LevioLabToolbarCloseButton,
    LevioLabToolbar,
    SimpleInfo,
  },
})
export default class Schedule extends Vue {
  private schedules: { title: string, text: string }[] = [
    {
      title: this.$t('attributions.title') as string,
      text: pbiSchedule.attributionsFullReport,
    },
    {
      title: this.$t('attributions.businessContext.title') as string,
      text: pbiSchedule.businessContextReport,
    },
    {
      title: this.$t('budgetPlan.title') as string,
      text: pbiSchedule.budgetPlanReport,
    },
    {
      title: this.$t('budgetPlan.comparativeView.title') as string,
      text: pbiSchedule.comparativeReport,
    },
  ]

  private get scheduleIcon(): string {
    return conceptsThemes.schedule.icon;
  }

  @Emit('closePopup')
  private closePopup(): void {
    return;
  }
}
