











































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Papa, {ParseResult} from 'papaparse';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import {FileWithName} from '@/store/models/upload_models';
import {camelCaseToTile} from '@/utils/localisation';
import LevioLabToolBarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';

@Component({
  components: {
    LevioLabToolbar,
    closeButton: LevioLabToolBarCloseButton,
  },
})
export default class CSVReaderDialog extends Vue {
  @Prop()
  private csv!: FileWithName;

  @Prop()
  private summaryCSV!: FileWithName;

  @Prop()
  private errorCSV!: FileWithName;

  @Prop({required: true})
  private closeDialog!: () => void;

  private isSampleLoading: boolean = false;
  private summaryRows: any[] = [];
  private isSummaryLoading: boolean = false;
  private sampleRows: any[] = [];
  private isErrorLoading: boolean = false;
  private errorRows: any[] = [];

  private dialogTabs: string = 'test';

  private created() {
    const ref = this;
    this.isSampleLoading = true;
    this.isSummaryLoading = true;
    this.isErrorLoading = true;

    if (this.errorCSV) {
      Papa.parse(this.errorCSV.file, {
        worker: true,
        complete(results: ParseResult<any>) {
          ref.isErrorLoading = false;
          ref.errorRows = results.data;
        },
      });
    }

    if (this.summaryCSV) {
      Papa.parse(this.summaryCSV.file, {
        worker: true,
        complete(results: ParseResult<any>) {
          ref.isSummaryLoading = false;
          ref.summaryRows = results.data;
        },
      });
    }

    if (this.csv) {
      Papa.parse(this.csv.file, {
        preview: 3000,
        fastMode: true,
        worker: true,
        complete(results: ParseResult<any>) {
          ref.isSampleLoading = false;
          ref.sampleRows = results.data;
        },
      });
    }
  }

  private get dialogTitle(): string {
    if (this.csv) {
      return camelCaseToTile(this.csv.name);
    }
    if (this.errorCSV) {
      return this.errorCSV.name;
    }
    if (this.summaryCSV) {
      return camelCaseToTile(this.summaryCSV.name);
    }

    return '';
  }

  private get hasTabs(): boolean {
    let propCount: number = 0;

    if (this.csv) {
      propCount += 1;
    }
    if (this.summaryCSV) {
      propCount += 1;
    }
    if (this.errorCSV) {
      propCount += 1;
    }

    if (propCount > 1) {
      return true;
    } else {
      return false;
    }
  }

  // Summary
  private get summaryHeaders(): any[] {
    if (this.summaryRows.length > 0) {
      return this.summaryObjectKeys.map((key) => {
        return {
          // Should handle _ and abbreviations to be used with the current data.
          // text: key[0].toUpperCase() + key.substr(1).toLowerCase(),
          text: key,
          value: key,
          align: 'start',
          sortable: false,
        };
      });
    } else {
      return [];
    }
  }

  private get summaryObjectKeys(): any[] {
    if (this.summaryRows.length > 0) {
      return this.summaryRows[0];
    } else {
      return [];
    }
  }

  // Sample
  private get sampleHeaders(): any[] {
    if (this.sampleRows.length > 0) {
      return this.sampleObjectKeys.map((key) => {
        return {
          text: key,
          value: key,
          align: 'start',
          sortable: false,
        };
      });
    } else {
      return [];
    }
  }

  private get sampleObjectKeys(): any[] {
    if (this.sampleRows.length > 0) {
      return this.sampleRows[0];
    } else {
      return [];
    }
  }

  // Errors
  private get errorHeaders(): any[] {
    if (this.errorRows.length > 0) {
      return this.errorObjectKeys.map((key) => {
        return {
          text: key,
          value: key,
          align: 'start',
          sortable: false,
        };
      });
    } else {
      return [];
    }
  }

  private get errorObjectKeys(): any[] {
    if (this.errorRows.length > 0) {
      return this.errorRows[0];
    } else {
      return [];
    }
  }
}
