import { render, staticRenderFns } from "./AlertWidget.vue?vue&type=template&id=604013e4&scoped=true&"
import script from "./AlertWidget.vue?vue&type=script&lang=ts&"
export * from "./AlertWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "604013e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
