










import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class ContextualDrawerDivider extends Vue {

}
