














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LevioLabToolBarCTA extends Vue {
  // Properties
  @Prop({
    required: false,
    default: '',
  })
  public icon!: string;

  @Prop({
    required: false,
    default: false,
  })
  public disabled!: boolean;

  // Public Functions

  @Emit('onClick')
  public onClick(): any {
    return;
  }
}
