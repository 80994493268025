import BaseService from '@/store/services/BaseService';
import { AnnualContext, AnnualOfficialData } from '@/store/models/annual_models';

export default class AnnualService extends BaseService {
  public static ANNUAL_CONTEXT_URL = '/annual/context/';
  public static ANNUAL_DATA_URL = '/annual/data/';

  //Annual Contexts
  public static async getAnnualContext(): Promise<any> {
    return await super.getJSON(AnnualService.ANNUAL_CONTEXT_URL);
  }

  public static async getAnnualContextById(id: string): Promise<any> {
    return await super.getJSON(AnnualService.ANNUAL_CONTEXT_URL + id);
  }

  public static async postAnnualContext(context: AnnualContext): Promise<any> {
    return await super.postJSON(AnnualService.ANNUAL_CONTEXT_URL, context);
  }

  //Official Annual Data
  public static async getOfficialAnnualDataById(id: string): Promise<AnnualOfficialData | null> {
    return super.getJSON(AnnualService.ANNUAL_DATA_URL + id);
  }

  public static async getOfficialAnnualData(): Promise<any> {
    return await super.getJSON(AnnualService.ANNUAL_DATA_URL);
  }

  public static async postOfficialAnnualData(request: AnnualOfficialData): Promise<any> {
    return await super.postJSON(AnnualService.ANNUAL_DATA_URL, request);
  }
}
