/* eslint-disable-next-line no-console */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import axios from 'axios';
import {
  Attribution,
  BudgetPlanConfig,
  BudgetPlanExecutionRequest,
  BudgetOptimizationInfo,
  BudgetOptimizationResult,
  ModellingTrainDatasetEvent,
  PipelineOutput,
  UploadValidationData,
} from '@/store/models/pipeline_models';
import { PipelineStepType, StepType } from '@/store/enums/pipeline_enums';
import { removeBrandFromJobId } from '@/utils/localisation';
import { StartETLRequest } from '@/store/models/upload_models';
import PipelineService from '@/store/services/PipelineService';
import { isError } from '@/utils/common';
import { RequestError } from '@/store/models/common_models';
import BudgetPlanificationService from '@/store/services/BudgetPlanificationService';

@Module({ namespaced: true, name: 'pipelineModule' })
export default class PipelineStore extends VuexModule {

  private $tableauPreparationOutputs: PipelineOutput[] = [];
  private $etlOutputs: PipelineOutput[] = [];
  private $attributionOutputs: PipelineOutput[] = [];
  private $optimizationEvents: PipelineOutput[] = [];
  private $latestAttributionOutputs: PipelineOutput[] | null = [];
  private $budgetOptimizationOutputs: PipelineOutput[] = [];
  private $outputs: PipelineOutput[] = [];
  private $currentOptimizationInfo: BudgetOptimizationInfo | null = null;
  private $currentOptimizationResult: BudgetOptimizationResult | null = null;
  private $currentOptimizationConfig: BudgetPlanConfig | null = null;
  private $currentJobOutput: PipelineOutput | null = null;
  private $currentOptimizationInfoChild: BudgetOptimizationInfo | null = null;
  private $currentOptimizationChildrenOutputs: PipelineOutput[] = [];
  private $currentAttribution: Attribution | null = null;
  private $uploadValidationData: UploadValidationData | null = null;
  private $uploadOutputs: PipelineOutput[] = [];

  public get tableauPreparationOutputs(): PipelineOutput[] {
    return this.$tableauPreparationOutputs;
  }

  public get attributionOutputs(): PipelineOutput[] {
    return this.$attributionOutputs;
  }

  public get optimizationOutputs(): PipelineOutput[] {
    return this.$optimizationEvents;
  }

  public get etlOutputs(): PipelineOutput[] {
    return this.$etlOutputs;
  }

  public get budgetOptimizationOutputs(): PipelineOutput[] {
    return this.$budgetOptimizationOutputs;
  }

  public get outputs(): PipelineOutput[] {
    return this.$outputs;
  }

  public get currentJobOutput(): PipelineOutput | null {
    return this.$currentJobOutput;
  }

  public get latestAttributionOutputs(): PipelineOutput[] | null {
    return this.$latestAttributionOutputs;
  }

  public get currentAttribution(): Attribution | null {
    return this.$currentAttribution;
  }

  public get currentOptimizationChildrenOutputs(): PipelineOutput[] {
    return this.$currentOptimizationChildrenOutputs;
  }

  public get currentOptimizationInfo(): BudgetOptimizationInfo | null {
    return this.$currentOptimizationInfo;
  }

  public get currentOptimizationResult(): BudgetOptimizationResult | null {
    return this.$currentOptimizationResult;
  }

  public get currentOptimizationInfoChild(): BudgetOptimizationInfo | null {
    return this.$currentOptimizationInfoChild;
  }

  public get currentOptimizationJobConfig(): BudgetPlanConfig | null {
    return this.$currentOptimizationConfig;
  }

  public get uploadValidationData(): UploadValidationData | null {
    return this.$uploadValidationData;
  }

  public get uploadOutputs(): PipelineOutput[] {
    return this.$uploadOutputs;
  }

  private static getInfoObject(results: any): BudgetOptimizationInfo {
    const optimizationInfo: BudgetOptimizationInfo = results.data;
    const resultObject = Object.assign(new BudgetOptimizationResult(), results.data.results);
    optimizationInfo.results = resultObject;
    return optimizationInfo;
  }

  @Mutation
  public setTableauPreparationOutputs(outputs: PipelineOutput[]) {
    this.$tableauPreparationOutputs = outputs;
  }

  @Mutation
  public setETLOutputs(outputs: PipelineOutput[]) {
    this.$etlOutputs = outputs;
  }

  @Mutation
  public setAttributionOutputs(outputs: PipelineOutput[]) {
    this.$attributionOutputs = outputs;
  }

  @Mutation
  public setOptimizationOutputs(outputs: PipelineOutput[]) {
    this.$optimizationEvents = outputs;
  }

  @Mutation
  public setBudgetOptimizationOutputs(outputs: PipelineOutput[]) {
    this.$budgetOptimizationOutputs = outputs;
  }

  @Mutation
  public setOutputs(outputs: PipelineOutput[]) {
    this.$outputs = outputs;
  }

  @Mutation
  public setCurrentOptimizationChildrenOutputs(outputs: PipelineOutput[]) {
    this.$currentOptimizationChildrenOutputs = outputs;
  }

  @Mutation
  public setCurrentJobOutput(output: PipelineOutput) {
    this.$currentJobOutput = output;
  }

  @Mutation
  public setCurrentOptimizationInfo(jobResult: BudgetOptimizationInfo | null) {
    this.$currentOptimizationInfo = jobResult;
  }

  @Mutation
  public setCurrentOptimizationJobResult(jobResult: BudgetOptimizationResult | null) {
    this.$currentOptimizationResult = jobResult;
  }

  @Mutation
  public setCurrentOptimizationInfoChild(optimizationInfo: BudgetOptimizationInfo | null) {
    this.$currentOptimizationInfoChild = optimizationInfo;
  }

  @Mutation
  public setBudgetOptimizationJobConfig(jobConfig: BudgetPlanConfig) {
    this.$currentOptimizationConfig = jobConfig;
  }

  @Mutation
  public setLatestAttributionOutputs(output: PipelineOutput[]) {
    this.$latestAttributionOutputs = output;
  }

  @Mutation
  public setCurrentAttribution(attribution: Attribution) {
    this.$currentAttribution = attribution;
  }

  @Mutation
  public clearChildrenOptimization() {
    this.$currentOptimizationInfoChild = null;
    this.$currentOptimizationChildrenOutputs = [];
  }

  @Mutation
  public setUploadValidationData(uploadValidationData: UploadValidationData) {
    this.$uploadValidationData = uploadValidationData;
  }

  @Mutation
  public clearUploadValidationData() {
    this.$uploadValidationData = null;
  }

  @Mutation
  public setUploadOutputs(outputs: PipelineOutput[]) {
    this.$uploadOutputs = outputs;
  }

  @Action({ commit: 'setUploadOutputs' })
  public async fetchUploadOutputs(): Promise<PipelineOutput[]> {
    try {
      const uploadOutputs: PipelineOutput[] = await PipelineService.getPipelineOutputsByStepType(StepType.UPLOAD_VALIDATION);
      return (uploadOutputs as PipelineOutput[])
        .sort((a, b) => a.jobId.localeCompare(b.jobId))
        .reverse();
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return [];
    }
  }

  // TODO: Temporary solution, needs to be handled on the backend.
  @Action({ commit: 'setLatestAttributionOutputs' })
  public async fetchLatestAttributionOutputs(): Promise<PipelineOutput | null> {
    try {
      const attributionOutputs = await this.context.dispatch('fetchAttributionOutputs');
      const latestAttributionJobId = attributionOutputs[0].jobId;
      const latestAttributionJobIdWithoutBrand = removeBrandFromJobId(latestAttributionJobId);

      return (attributionOutputs.filter((output) => output.jobId.includes(latestAttributionJobIdWithoutBrand)));
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setCurrentAttribution' })
  public async fetchAttributionByJobId(jobId: string): Promise<Attribution | null> {
    try {
      const data = await axios.get('/attributions/' + jobId + '/results');
      return (data.data as Attribution);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setUploadValidationData' })
  public async fetchUploadValidationData(jobId: string): Promise<UploadValidationData | null> {
    try {
      const data = await axios.get('/pipeline/output',
        {
          params: {
            sourceJobId: jobId,
          },
        });

      const dataList = data.data as UploadValidationData[];

      if (dataList.length !== 0) {
        return (dataList[0]);
      } else {
        return null;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setTableauPreparationOutputs' })
  public async fetchTableauPreparationOutputs(): Promise<PipelineOutput[]> {
    const { data } = await axios.get('/pipeline/output',
      {
        params: {
          stepType: PipelineStepType.TABLEAU_PREPARATION.toString(),
        },
      });

    return (data as PipelineOutput[])
      .sort((a, b) => a.jobId.localeCompare(b.jobId))
      .reverse();
  }

  @Action({ commit: 'setETLOutputs' })
  public async fetchETLOutputs(): Promise<PipelineOutput[]> {
    const { data } = await axios.get('/pipeline/output',
      {
        params: {
          stepType: PipelineStepType.ETL.toString(),
        },
      });

    return (data as PipelineOutput[])
      .sort((a, b) => a.jobId.localeCompare(b.jobId))
      .reverse();
  }

  @Action({ commit: 'setAttributionOutputs' })
  public async fetchAttributionOutputs(): Promise<PipelineOutput[]> {
    const { data } = await axios.get('/pipeline/output',
      {
        params: {
          stepType: PipelineStepType.MODELLING_ATTRIBUTIONS.toString(),
        },
      });

    return (data as PipelineOutput[])
      .sort((a, b) => a.jobId.localeCompare(b.jobId))
      .reverse();
  }

  @Action({ commit: 'setBudgetOptimizationOutputs' })
  public async fetchBudgetOptimizationOutputs(): Promise<PipelineOutput[]> {
    const { data } = await axios.get('/pipeline/output?isChildren=false',
      {
        params: {
          stepType: PipelineStepType.BUDGET_OPTIMIZATION.toString(),
        },
      });

    return (data as PipelineOutput[])
      .sort((a, b) => a.jobId.localeCompare(b.jobId))
      .reverse();
  }

  @Action({ commit: 'setOutputs', rawError: true })
  public async fetchOutputs(): Promise<PipelineOutput[]> {
    const { data } = await axios.get('/pipeline/output');

    return (data as PipelineOutput[]).filter((output) => output.jobId != null)
      .sort((a, b) => a.jobId.localeCompare(b.jobId))
      .reverse();
  }

  // Added only for graph comparison.
  @Action({ commit: 'setCurrentJobOutput' })
  public async fetchCurrentJobOutput(jobId: string): Promise<PipelineOutput | null> {
    try {
      const data = await axios.get('/pipeline/output/' + jobId);
      return (data.data as PipelineOutput);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setCurrentOptimizationInfo' })
  public async fetchBudgetOptimizationInfo(jobId: string): Promise<BudgetOptimizationInfo | null> {
    this.context.commit('setCurrentOptimizationInfo', null);
    try {
      const results = await axios.get('/optimization/' + jobId + '/info');
      return PipelineStore.getInfoObject(results);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setBudgetOptimizationJobConfig' })
  public async fetchBudgetOptimizationJobConfig(jobId: string): Promise<BudgetPlanConfig | null> {
    this.context.commit('setBudgetOptimizationJobConfig', null);
    try {
      const results = await axios.get('/optimization/' + jobId + '/config');
      return results.data as BudgetPlanConfig;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setCurrentOptimizationJobResult' })
  public async fetchBudgetOptimizationJobResult(jobId: string): Promise<BudgetOptimizationResult | null> {
    this.context.commit('setCurrentOptimizationJobResult', null);
    try {
      const results = await axios.get('/optimization/' + jobId + '/results');
      return Object.assign(new BudgetOptimizationResult(), results.data);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setCurrentOptimizationChildrenOutputs' })
  public async fetchChildrenFromOptimization(sourceJobId: string): Promise<PipelineOutput[] | null> {
    try {
      const results = await axios.get(`/pipeline/output?stepType=BUDGET_OPTIMIZATION&isChildren=true&sourceJobId=${sourceJobId}`);
      return results.data;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }

  @Action({ commit: 'setOptimizationOutputs' })
  public async fetchOptimizationList(): Promise<PipelineOutput[]> {
    try {
      const results = await PipelineService.getOptimizationEvents();
      return results as PipelineOutput[];
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return [];
    }
  }

  @Action
  public async startETL(request: StartETLRequest): Promise<void | RequestError> {
    const etlOutput: PipelineOutput | RequestError = await PipelineService.startETL(request);
    if (isError(etlOutput)) {
      return etlOutput as RequestError;
    }
  }

  @Action
  public async startModellingAttributions(modellingJobs: ModellingTrainDatasetEvent[]): Promise<void | RequestError> {
    const modellingAttributionOutput: PipelineOutput | RequestError = await PipelineService.startAttributions(modellingJobs);
    if (modellingAttributionOutput && isError(modellingAttributionOutput)) {
      return modellingAttributionOutput as RequestError;
    }
  }

  @Action
  public async startBudgetOptimizationProcessingJob(request: BudgetPlanExecutionRequest): Promise<void | RequestError> {
    const budgetOptimizationOutput: PipelineOutput | RequestError = await BudgetPlanificationService.startPlanificationOptimization(request);
    if (isError(budgetOptimizationOutput)) {
      return budgetOptimizationOutput as RequestError;
    }
  }
}
