































import dayjs from 'dayjs';
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AgreementContract, DialogContentType, UIDialog, UISnackBar} from '@/store/models/ui_models';
import MainNavigation from '@/components/shared/MainNavigation.vue';
import GlobalDialog from '@/components/globalUI/GlobalDialog.vue';
import {DialogComponentFactory} from '@/store/factories/DialogComponentFactory';

const auth = namespace('AuthStore');
const globalUI = namespace('GlobalUIStore');

@Component({
  components: {
    MainNavigation,
    GlobalDialog,
  },
})
export default class App extends Vue {

  @globalUI.Getter
  private dialog!: UIDialog;

  @globalUI.Getter
  private isDialogVisible!: boolean;

  @globalUI.Action
  private hideSnackBar!: (payload: UISnackBar) => UISnackBar;

  @globalUI.Getter
  private snackBar!: UISnackBar;

  @auth.Getter
  private authorized!: boolean;

  @globalUI.Getter
  private inactivityBaseAmount!: number;

  @globalUI.Getter
  private inactivityThreshold!: number;

  @globalUI.Action
  private showDialogByContent!: (content: DialogContentType) => Promise<boolean>;

  @auth.Action
  private logout!: () => Promise<void>;

  private inactivityRemainingSeconds: number = 1800;
  private inactivityInterval: any = null;

  public created() {
    this.inactivityInterval = window.setInterval(() => {
      if (this.authorized) {
        this.inactivityRemainingSeconds--;
        if (!this.isDialogVisible) {
          if (this.inactivityRemainingSeconds < this.inactivityThreshold) {
            this.showDialogByContent(DialogContentType.INACTIVITY);
          }
        } else if (this.isDialogVisible && this.dialog.component ===
            DialogComponentFactory.getComponentByType(DialogContentType.INACTIVITY)) {
          if (this.inactivityRemainingSeconds <= 0) {
            this.showDialogByContent(DialogContentType.LOGGED_OUT);
            this.logout().then(() => this.$router.replace('/signin'));
          }
        }
      }
    }, 1000);

    document.addEventListener('click', () => {
      this.inactivityRemainingSeconds = this.inactivityBaseAmount;
    });

  }

  public destroyed(): void {
    clearInterval(this.inactivityInterval);
  }

  public updated(): void {
    this.checkDisclaimerAcceptation();
  }

  private get isDialogVisibleInApp(): boolean {
    return this.isDialogVisible;
  }

  private checkDisclaimerAcceptation(): void {
    if (this.authorized) {
      const storage: string | null = localStorage.getItem('agreementContract');
      let hasAccess: boolean = false;
      if (storage) {
        const agreementContract: AgreementContract = JSON.parse(storage);
        if (this.isDateVerified(agreementContract.timestamp) && agreementContract.accept) {
          hasAccess = true;
        } else {
          localStorage.removeItem('agreementContract');
        }
      }

      if (!hasAccess) {
        this.showDialogByContent(DialogContentType.AGREEMENT);
      }
    }
  }

  private isDateVerified(expirationDate: string): boolean {
    return dayjs(expirationDate).isAfter(dayjs());
  }
}
