



































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Media } from '@/store/enums/pipeline_enums';
import { allMedias } from '@/utils/business-logic';
import { translateEnum } from '@/utils/localisation';
import Instructions from '@/components/shared/Instructions.vue';
import CurrencyField from '@/components/shared/currency/CurrencyField.vue';

@Component({
  components: {
    Instructions,
    CurrencyField,
  },
})
export default class BudgetMediaBreakdown extends Vue {
  @Prop({
    default: false,
  })
  public isHidden!: boolean;

  @Prop({
    default: 0,
  })
  public totalBudget!: number;

  private mediasBudget: { [key: string]: number; } = {};

  @Emit('input')
  public emitData(): { [key: string]: number; } {
    return this.mediasBudget;
  }

  @Watch('mediasBudget', { deep: true })
  private onMediaChanged(): void {
    this.emitData();
  }

  private getMediaName(media: Media): string {
    return translateEnum(media, 'media') as string;
  }

  private get medias(): Media[] {
    return allMedias;
  }

  private get remainingBudget(): number {
    let usedBudget: number = 0;
    Object.values(this.mediasBudget).forEach((value) => {
      usedBudget += Number(value);
    });
    return Math.round((this.totalBudget - Number(usedBudget)) * 100) / 100;
  }

  private get budgetColor(): string {
    if (this.remainingBudget < 0) {
      return 'error--text';
    } else if (this.remainingBudget === 0) {
      return 'success--text';
    }
    return '';
  }

}
