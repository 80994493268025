const FR_LOCALES = {
  app: {
    title: 'Impact marketing',
  },
  common: {
    brand: 'Marque',
    default: 'Défaut',
    description: 'Description',
    error: 'Érreur',
    id: 'Identifiant',
    jobId: 'Identifiant de l\'exécution',
    message: 'Message',
    name: 'Nom',
    objective: 'Objectif',
    optional: 'facultatif',
    quotes: 'soumissions',
    results: 'Résultats',
    status: 'Statut',
    summary: 'Sommaire',
    title: 'Titre',
    titleExample: 'Voici comment votre titre sera affiché dans les rapports:',
    totalBudget: 'Budget total',
    totalQuotes: 'Nombre total de soumissions',
    type: 'Type',
    upload: 'Téléversement',
    year: 'Année',
    years: 'Années',
    author: 'Auteur',
    cta: {
      accept: 'Accepter',
      close: 'Fermer',
      continue: 'Continuer',
      create: 'Créer',
      delete: 'Supprimer',
      officialize: 'Officialiser',
      proceed: 'Procéder',
      redo: 'Recommencer',
      save: 'Enregistrer',
      send: 'Envoyer',
      select: 'Sélectionner un item',
      submit: 'Soumettre',
      upload: 'Téléverser',
    },
    table: {
      itemsPerPage: 'Lignes par page',
    },
    location: {
      province: 'Province',
    },
    time: {
      year: 'Année',
      referenceYear: 'Année de référence',
    },
    validation: {
      fieldRequired: 'Ce champs est requis.',
      duplicateTitleInList: '\'{value}\' est utilisé à plusieurs reprises. Le champs <strong>{valueType}</strong> doit être unique.',
      usedTitleInList: 'La valeur \'{value}\' a déjà été utilisée pour une exécution précédente. Chaque valeur de <strong>{valueType}</strong> doit être unique.',
      email: {
        required: 'L\'adresse courriel est requise.',
        invalid: 'L\'adresse courriel doit être valide.',
      },
      confirmationCode: {
        required: 'Le code de confirmation est requis.',
        numeric: 'Le code de confirmation est composé seulement de chiffres.',
      },
      password: {
        required: 'Le mot de passe est requis.',
        minLength: 'Le mot de passe doit avoir 8 lettres.',
        invalid: 'Le mot de passe doit avoir une majuscule, une minuscule et un chiffre.',
        confirm: 'Vous devez confirmer le mot de passe.',
        matchPassword: 'Les mots de passes doivent correspondre.',
      },
      number: {
        notZero: 'La valeur ne peut être 0.',
        higherThanZero: 'Cette valeur doit être supérieure à 0.',
      },
    },
  },
  advancedTools: {
    title: 'Outils avancés',
    perspective: {
      createLabel: 'Nouvelle perspective mensuelle',
      dialog: {
        title: 'Objectifs globaux des soumissions mis en perspective de MROI',
        objective: 'Objectif global de soumissions',
        attributedTitle: 'Pourcentage attribué',
        fillInstructions: 'Entrez une année, une marque et un objectif global de soumissions pour plus d\'options',
      },
      results: {
        title: 'Perspective mensuelle des objectifs de soumissions',
      },
    },
  },
  agreementWarning: {
    title: 'Mise-en-garde',
    content: '<ul>\n' +
        '    <li>\n' +
        '        Les observations suivantes se concentrent sur les effets à court terme des investissements marketing sur les\n' +
        '        soumissions et ne prennent pas en compte d\'autres aspects qui sont également essentiels aux efforts de marketing :\n' +
        '        <ul>\n' +
        '            <li>Accroître la notoriété (marque, produit, solutions) ;</li>\n' +
        '            <li>Renforcer la considération pour la marque ;</li>\n' +
        '            <li>\n' +
        '                Nuances entre les campagnes publicitaires (par exemple, les objectifs de la campagne, les types de\n' +
        '                création utilisés, les médias et les stratégies de placement utilisés, etc.)\n' +
        '            </li>\n' +
        '        </ul>\n' +
        '    </li>\n' +
        '    <li>\n' +
        '        Les données présentées dans la plateforme Impact marketing doivent être utilisées comme un outil ainsi que comme une ligne directrice pour informer ' +
        '        les décisions de marketing et d\'investissement, et doivent être coordonnées avec d\'autres analyses.\n' +
        '    </li>\n' +
        '    <li>\n' +
        '        Les résultats de la modélisation du marketing mix ne remplacent pas le besoin d\'experts et ne constituent pas\n' +
        '        une "solution miracle" pour répondre à toutes les questions d’affaires.\n' +
        '    </li>\n' +
        '</ul>\n',
  },
  annualContext: {
    form: {
      titleEN: 'Titre en anglais',
      titleFR: 'Titre en français',
      descriptionEN: 'Description en anglais',
      descriptionFR: 'Description en français',
    },
    title: 'Éditer les contextes annuels',
  },
  attributions: {
    title: 'Allocations et Attributions',
    subtitle: 'Résultats des dernières allocations budgétaires et des attributions générées',
    current: 'Résultats d\'attributions',
    create: 'Créer une nouvelle attribution',
    yearlyData: 'Historique',
    downloadPBIFile: 'Télécharger le fichier PBI',
    filterYear: 'Année d\'officialisation',
    filterBrand: 'Filtre de marque',
    notFound: 'Aucune attribution correspondante',
    analysis: {
      title: 'Attribution en analyse',
    },
    businessContext: {
      title: 'Contexte d\'affaires',
    },
    upload: {
      button: 'Tâche de traitement',
      title: 'Téléverser les fichiers requis',
      subtitle: 'Téléverser les fichiers nécessaires pour démarrer une tâche de traitement de génération d\'attributions.',
    },
    modellingDialog: {
      title: 'Modéliser une attribution',
      countLabel: 'Nombres d\'exécutions de modélisation planifiées',
    },
    officialization: {
      attribution: 'Officialiser une attribution',
      etl: 'Officialiser un ETL',
      attributionTooltip: 'Selectionner les attributions à officialiser.',
      dialog: {
        title: 'Êtes-vous sûr?',
        text1: 'Désirez-vous officialiser cette exécution?',
        text2: 'Cette action aura des conséquences sur les rapports et visualisations graphiques.',
        cancel: 'Annuler',
      },
    },
  },
  auth: {
    username: 'Courriel',
    password: 'Mot de passe',
    newPassword: 'Mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    login: {
      cta: 'Connexion',
    },
    completePassword: {
      title: 'Compléter le mot de passe',
    },
    forgotPassword: {
      title: 'Mot de passe oublié',
      sendCode: 'Envoyer le code',
      code: 'Code',
      alert: {
        title: 'Laisser cet onglet ouvert.',
        text: 'Un code de réinitialisation vous sera envoyé par courriel. Cela peut prendre jusqu\'à 2 minutes. ' +
            'Entrez votre code dans le champ ci-dessous et saisissez votre nouveau mot de passe.',
        junkMailCaption: 'Si vous ne recevez pas un nouveau message, veuillez vérifier vos courriels indésirables.',
      },
    },
    error: {
      NotAuthorizedException: 'Courriel ou mot de passe incorrect.',
      Unknown: 'Erreur lors de l\'authentification.',
    },
  },
  budgetPlan: {
    title: 'Planification budgétaire',
    prediction: {
      ctaOfficialize: 'Officialiser une prédiction budgétaire',
    },
    optimization: {
      ctaOfficialize: 'Officialiser une optimisation budgétaire',
    },
    comparativeView: {
      title: 'Bilan comparatif',
    },
  },
  cloudSharing: {
    title: 'Partage de fichiers',
    subtitle: 'Téléverser des fichiers divers',
    files: {
      title: 'Fichiers téléversés',
      description: 'Liste de tous les fichiers téléversés',
    },
  },
  csv: {
    sample: 'Échantillon',
  },
  dashboard: {
    title: 'Tableau de bord',
    logout: 'Déconnexion',
  },
  disclaimer: {
    title: 'Avis de non-responsabilité',
    modeling: {
      title: 'Avis sur la modélisation',
      content: '<ul class="text-body-1">\n' +
          '       <li>\n' +
          '         Les observations suivantes se concentrent sur les effets à court terme des dépenses de marketing sur ' +
          '         les soumissions et ne prennent pas en compte d\'autres aspects qui sont également essentiels aux efforts de marketing :\n' +
          '         <ul class="text-body-1 grey--text text--darken-2">\n' +
          '           <li>Accroître la notoriété (marque, produit, solutions) ;</li>\n' +
          '           <li>Renforcer la considération pour la marque ;</li>\n' +
          '           <li>\n' +
          '             Nuances entre les campagnes publicitaires (par exemple, les objectifs de la campagne, les ' +
          '             types de création utilisés, les médias et les stratégies de placement utilisés, etc.)\n' +
          '           </li>\n' +
          '         </ul>\n' +
          '       </li>\n' +
          '       <li>\n' +
          '         Les données présentées dans la plateforme Impact marketing doivent être utilisées comme un outil ainsi que comme une ligne directrice pour ' +
          '         informer les décisions de marketing et d\'investissement, et doivent être coordonnées avec d\'autres analyses.\n' +
          '       </li>\n' +
          '       <li>\n' +
          '         Les résultats de la modélisation du marketing mix ne remplacent pas le besoin d\'experts et ne ' +
          '         constituent pas une "solution miracle" pour répondre à toutes les questions d’affaires.\n' +
          '       </li>\n' +
          '     </ul>',
    },
    modelDetails: {
      title: 'Détails sur le model',
      content: '       <h4>DA</h4>\n' +
          '            <ul class="text-body-1">\n' +
          '              <li>\n' +
          '                Les soumissions modélisées pour les efforts de marketing de DA et CA comprennent :\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Les soumissions pour l\'automobile et la propriété</li>\n' +
          '                  <li>Les canaux CCC et Web</li>\n' +
          '                  <li>Modèle hebdomadaire à partir de janvier 2015 jusqu\'à décembre 2020</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                Les dimensions du modèle sont les suivantes :\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Base: RTA</li>\n' +
          '                  <li>Marchés : MTL, Québec et reste du Québec</li>\n' +
          '                  <li>Canal de distribution : CCC et Web</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '            </ul>\n' +
          '            <h4>DI-Direct</h4>\n' +
          '            <ul class="text-body-1">\n' +
          '              <li>\n' +
          '                Les soumissions modélisées pour les efforts de marketing de DI-Direct comprennent :\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Les soumissions pour l\'automobile et la propriété</li>\n' +
          '                  <li>Les canaux CCC, Web et KAN</li>\n' +
          '                  <li>Modèle hebdomadaire à partir de janvier 2015 jusqu\'à décembre 2020</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                Les dimensions du modèle sont les suivantes :\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Base: RTA</li>\n' +
          '                  <li>Marchés : GTA, Ottawa et reste de l’Ontario</li>\n' +
          '                  <li>Canal de distribution : CCC, Web et KAN</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '            </ul>\n' +
          '            <h4>DI-DAN</h4>\n' +
          '            <ul class="text-body-1">\n' +
          '              <li>\n' +
          '                Les soumissions modélisées pour les efforts de marketing de DAN comprennent :\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Les soumissions pour l\'automobile et la propriété</li>\n' +
          '                  <li>Les canaux CCC et WEB</li>\n' +
          '                  <li>Modèle hebdomadaire à partir de janvier 2018 jusqu\'à décembre 2020</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                Les dimensions du modèle sont les suivantes :\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Base: RTA</li>\n' +
          '                  <li>Marchés : GTA, Ottawa et reste de l’Ontario</li>\n' +
          '                  <li>Canal de distribution : CCC et Web</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '            </ul>',
    },
    lexicon: {
      title: 'Définitions des variables',
      brands: {
        title: 'Marques',
        content: {
          da: {
            label: 'DA',
            description: 'Desjardins Assurance',
          },
          dan: {
            label: 'DI-DAN',
            description: 'Desjardins Agent Network',
          },
          di: {
            label: 'DI-DIRECT',
            description: 'Desjardins Insurance',
          },
        },
      },
      medias: {
        title: 'Médias',
        content: {
          addressedDm: {
            label: 'Adressed_DM',
            description: 'Courrier adressé aux membres et aux clients de la DGAG. Le volume utilisé dans la plateforme, est le nombre réel de courriers envoyés par FSA.',
          },
          email: {
            label: 'Email',
            description: 'Courriel envoyé à une base de donnée de manière numérique. Le volume utilisé dans la plateforme, est le nombre réel de courriels envoyés par FSA.',
          },
          newspaper: {
            label: 'Newspaper',
            description: 'Placement médias dans les magazines et journaux. Pour ces médias, le coût du placement média est utilisé en tant que volume.',
          },
          outdoor: {
            label: 'Outdoor',
            description: 'Ce média concerne les affiches publicitaires sur les autoroutes et routes principales. Pour ces médias, le coût du placement média est utilisé en tant que volume.',
          },
          organic: {
            label: 'Organic',
            description: 'Le média organique combine les médias suivants: Google trends, jours fériés, price variation (ON) et les données spatiotemporelles (données temporelles et démographiques) ',
          },
          phone: {
            label: 'Phone',
            description: 'Nombre d’appels fait par l\'équipe de télémarketing. Le volume utilisé dans la plateforme, est le nombre réel d’appels fait par FSA.',
          },
          ppcBranded: {
            label: 'PPC Branded',
            description: 'Pay per click (PPC) branded est la pratique qui consiste à enchérir sur des termes ou des mots clés de la marque de l’organisation ou d’un compétiteur.',
          },
          unbrandedPC: {
            label: 'Unbranded PPC',
            description: 'Pay per click (PPC) unbranded est la pratique qui consiste à enchérir sur des termes beaucoup plus génériques.',
          },
          radio: {
            label: 'Radio',
            description: 'Ce média concerne la transmission de messages publicitaires par la radio publique. Le volume utilisé est le “Gross Rating Point” (GRP), ou point d’exposition brut (PEB) en français, qui est un estimé de la proportion de la population qu’on a atteint avec le message.',
          },
          referrals: {
            label: 'Références',
            description: 'Soumissions faites via la référence d’un autre service Desjardins. Le volume utilisé dans la plateforme, est le nombre réel de soumissions produites par FSA.',
          },
          tv: {
            label: 'TV',
            description: 'Ce média concerne la transmission de messages publicitaires par la télévision. Le volume utilisé est le “Gross Rating Point” (GRP), ou point d’exposition brut (PEB) en français, qui est un estimé de la proportion de la population qu’on a atteint avec le message.',
          },
          unaddressedDm: {
            label: 'Unadressed_DM',
            description: 'Courrier non-adressé. Les données des courriers non-adressés sont agrégées différemment selon la province.',
          },
          webAd: {
            label: 'Web-ad',
            description: 'Ce média concerne les annonces Web sur les sites interactifs, les réseaux sociaux et les pages de recherche ainsi que d’autres campagnes Web utilisant des annonces imagées (display). Puisque nous n’avons pas les données de volumes pour les annonces Web, le coût est utilisé.',
          },
        },
      },
      quotesChannels: {
        title: 'Canaux de soumissions',
        content: {
          ccc: {
            label: 'CCC',
            description: 'Centre d’appel',
          },
          eax: {
            label: 'EAX',
            description: 'Réseau des agents exclusifs',
          },
          kan: {
            label: 'KAN',
            description: 'Kanetix',
          },
          web: {
            label: 'WEB',
            description: 'Site internet de DGAG où l’on peut faire une demande de soumission en ligne.',
          },
        },
      },
      others: {
        title: 'Autres',
        content: {
          fsa: {
            label: 'FSA',
            description: 'Forward Sortation Area, c’est les 3 premiers caractères du code postal. Le FSA est la même chose que le RTA.',
          },
          totalQuotes: {
            label: 'Total Quotes',
            description: 'Nombre réel de soumissions générées',
          },
          simulatedQuotes: {
            label: 'Simulated Quotes',
            description: 'Nombre de soumissions estimées par la modélisation',
          },
        },
      },
    },
  },
  enum: {
    brands: {
      da: 'DA',
      dan: 'DI-DAN',
      di: 'DI-DIRECT',
      synergy: 'Synergie DI-Direct et DI-DAN',
    },
    budgetPlanType: {
      prediction: 'Prédiction',
      optimization: 'Optimisation',
    },
    media: {
      addressedDm: 'DM Adressés',
      unaddressedDm: 'DM Non-adressés',
      postal: 'Publicité Postale',
      email: 'Courriel',
      billboard: 'Panneaux',
      phone: 'Téléphone',
      radio: 'Radio',
      tv: 'Télévision',
      newspaper: 'Presse écrite',
      outdoor: 'Extérieur',
      ppcBranded: 'PPC avec marques',
      ppcUnbranded: 'PPC sans marques',
      references: 'References',
      adWeb: 'Publicité Web',
    },
    methodType: {
      fixed: 'Contraintes fixes',
      media: 'Budget par média',
    },
    objective: {
      maxQuotes: 'Maximiser les soumissions',
      minBudget: 'Minimiser le budget',
    },
    province: {
      qc: 'Québec',
      on: 'Ontario',
      nb: 'Nouveau Brunswick',
      ab: 'Alberta',
      other: 'Autre',
    },
    errorType: {
      test_cols: 'Le fichier que vous avez fourni comporte des colonnes manquantes ou superflues.',
      test_no_nan: 'Le fichier que vous avez fourni comporte des données manquantes.',
      test_no_neg: 'Le fichier que vous avez fourni a des valeurs numériques négatives.',
      test_isocalendar: 'Le fichier que vous avez fourni comporte des semaines qui ne respectent pas le calendrier iso.',
      test_cat_values: 'Le fichier que vous avez fourni comporte des catégories inconnues.',
    },
    requestErrorType: {
      default: 'Une erreur est survenu dans la requête.',
      titleDuplicate: 'Ce nom/titre est déjà utilisé.',
    },
    stepType: {
      etl: 'ETL',
      modellingAttributions: 'Modelling Attributions',
      modellingDataset: 'Modelling Dataset',
      optimization: 'Optimization',
      tableauPreparation: 'Tableau Preparation',
      upload: 'Upload',
      uploadValidation: 'Upload Validation',
    },
  },
  etl: {
    jobDialog: {
      title: 'Calculer un ETL',
      ctaButton: 'Commencer les calculs',
      uploadsSelect: 'Téléversements',
      tooltip: 'Si votre téléversement précédent n\'est pas présent dans la liste, appuyer pour rafraîchir dans 10 secondes.',
    },
    suggestionDialog: {
      title: 'Voulez-vous débuter un calcul de l\'ETL?',
      text: 'Voulez-vous débuter un calcul (ETL), enregistrer ce téléchargement ou le supprimer?',
      etlButton: 'Calculer un ETL',
    },
  },
  files: {
    addressedDM: 'Fichier CSV contenant le volume de courrier adressé directement à tous les RTA pour chaque marque et produit.',
    clv: 'Fichier CSV contenant la valeur de la CLV globale pour chaque marque et canal de soumission.',
    desjardinsHolidays: 'Fichier CSV contenant le nombre de jours fériés pour chaque semaine.',
    email: 'Fichier CSV contenant le volume de courriels envoyés à tous les RTA pour chaque marque et produit.',
    geoPopulation: 'Fichier CSV contenant la population de chaque RTA selon le dernier recensement.',
    googleTrends: 'Fichier CSV contenant le volume de requêtes de recherche spécifiques. Les données sont les mêmes pour tous les RTA au cours d\'une même semaine.',
    marketingExpenses: 'Fichier CSV contenant le coût de production et le coût média associés à chaque média pour une année et une province spécifique.',
    phone: 'Fichier CSV contenant le volume et le coût du télémarketing pour tous les RTA et est présenté par marque et produit.',
    ppc: 'Fichier CSV qui contient le nombre total de clics, d\'impressions et de coûts par catégorie PPC et moteur pour tous les RTA en fonction de la proportion de leur population et est présenté par marque et produit.',
    priceIndex: 'Fichier CSV présentant la variation de prix de l\'assurance en fonction du trimestre précédent. La variation de prix est la même pour chaque RTA au cours d\'une même période. Ces données ne sont disponibles que pour les produits DI.',
    print: 'Fichier CSV contenant le coût de production pour une impression journal, magazine ou de panneaux extérieur pour tous les RTA en fonction de la proportion de sa population et est présenté par marque et produit.',
    productionCosts: 'Fichier CSV contenant les coûts de production par média.',
    quotes: 'Fichier CSV contenant le volume de soumissions générées pour tous les RTA par canaux de soumission, marque et produit. ',
    radio: 'Fichier CSV contenant le GRP pour tous les RTA en fonction de l\'exposition de la campagne et de la population atteinte pour une station de radio spécifique. Le GRP pour chaque RTA est basé sur la proportion de leur population.',
    references: 'Fichier CSV contenant le volume de références directes et différées faites pour tous les RTA et est présenté par marque et pr  oduit.',
    sales: 'Fichier CSV contenant le volume des ventes générées pour tous les RTA par canaux de vente, marque et produit.',
    television: 'Fichier CSV contenant le GRP de tous les RTA en fonction de l\'exposition de la campagne et de la population atteinte pour une chaîne de télévision spécifique. Le GRP pour chaque RTA est basé sur la proportion de leur population.',
    unaddressedDM: 'Fichier CSV contenant le volume de courrier direct non adressé envoyé à tous les RTA pour chaque marque et produit. ',
    webAdvertising: 'Fichier CSV contenant les clics, les impressions et le coût des investissements interactifs, sociaux et de recherche pour tous les RTA. Le volume de chaque RTA est basé sur la proportion de sa population.',
  },
  inactivityDialog: {
    title: 'Êtes-vous là?',
    text1: 'En raison de votre inactivité, votre session de connexion va expirer. ' +
        'Cliquez sur Continuer pour rester connecté.',
    text2: 'secondes restantes',
    text3: 'Vous avez été déconnecté par cause d\'inactivité.',
  },
  modelGovernance: {
    title: 'Performance des modèles',
    attributions: 'Attributions',
    optimizations: 'Optimisations',
  },
  optimization: {
    title: 'Optimisation',
    create: 'Créer une planification budgétaire',
    optimize: 'Optimiser',
    downloadButton: 'Télécharger CSV',
    dialog: {
      title: 'Nouvelle planification budgétaire',
      subtitle: 'Configurer et créer une planification budgétaire.',
      job: 'Tâche d\'attribution officialisée',
      jobTooltip: 'Choisir une tâche d\'attribution officialisée à utiliser pour cette planification.',
      budgetPlanType: 'Type de planification budgétaire',
      referenceYearLabel: 'Année de référence du budget',
      referenceYearHint: 'L’année de référence sélectionnée doit représenter le scénario qui sera réalisé.',
      method: 'Méthode de planification',
      parameters: 'Paramètres',
      budgetDefinition: 'Définition du budget',
      modellingVariables: 'Variables de modélisation',
      informativeVariables: 'Variables informatives',
      acquisitionBudget: 'Budget d\'acquisition',
      caisseAssurancesLabel: 'Budget Caisse Assurances',
      growthPlan: 'Plan de croissance',
      commBudget: 'Budget pour les communications client',
      withoutCA: 'Caissassurances exclue',
      total: 'Total du budget marketing',
      budgetAllocation: 'Pourcentage d\'allocation du budget par province',
      modelProvince: 'Province modélisée',
      errorAllocation: 'La somme des allocations est de <span class="font-weight-bold">{total}%</span>. Elle devrait être de 100%.',
      remainingBudget: 'Budget d\'acquisition restant pour {province} ',
      remainingBudgetTooltipDA: 'Comprend le budget total de Caisse Assurance + le budget alloué pour la province de Québec',
      budgetBreakdown: 'Découpage du budget',
      remaining: 'Budget d’acquisition non utilisé',
      requiredBudget: 'Budget requis (optionel)',
      totalQuotesObjectives: 'Objectif global de soumissions',
      fillInstructionsPart1: 'Sélectionnez une méthode d\'optimisation et un objectif pour plus d\'options',
      fillInstructionsAllocation: 'Vous devez fournir un budget d\'acquisition pour procéder.',
      fillInstructionsBreakDown: 'Vous devez fournir un pourcentage d\'allocation pour la province modélisée pour procéder.',
    },
    graphs: {
      quarterLabel: 'Trimestre',
      mediasLabel: 'Médias',
      totalBudgetLabel: 'Budget total',
      totalQuotesLabel: 'Total des soumissions',
      titleMediasQuarters: 'Répartition des médias par trimestre',
      titleMediasQuartersPercent: 'Répartition en pourcentage des médias par trimestre',
      titleTableBudget: 'Information détaillée de la répartition des médias',
      titleTableQuotes: 'Information détaillée des soumissions par média',
      previousOptimizationLabel: 'Optimisation initiale',
    },
  },
  processing: {
    title: 'Tâches de traitement',
    subtitle: 'Examiner et gérer les tâches de traitement',
    creationTime: 'Heure de création',
    duration: 'Durée',
    search: 'Recherche',
    tab: {
      jobs: 'Tâches',
      outputs: 'Sorties',
    },
    output: {
      step: 'Étape',
      creationTime: 'Heure de création',
    },
  },
  schedule: {
    title: 'Horaire de mises-à-jour des rapports PowerBI',
  },
  support: {
    title: 'Soutien',
    subtitle: 'Documentation et aide pour la platforme',
    userGuide: {
      tooltip: 'Guide de l\'usager',
    },
    noRole: 'Aucun rôle ne vous a été assigné. Veuillez communiquer avec nous ou avec les mandataires de cette plateforme chez Desjardins.',
    copyToClipboard: 'L\'adresse courriel du soutien technique a été copié dans votre presse-papier.',
    openMailingApp: 'Votre message à notre équipe de support a été transféré à l\'application de messagerie selectionnée',
  },
  upload: {
    title: 'Téléversement de fichiers',
    subtitle: 'Téléverser des fichiers',
    text1: 'Glisser-déposer les fichiers ici',
    text2: 'Fichiers manquants',
    tooltip: 'Exemple',
    validate: 'Accepter',
    validating: 'Validation en cours...',
    history: {
      date: 'Date',
      uploader: 'Auteur',
      action: 'Actions',
      fileName: 'Fichiers',
      folderName: 'Nom du dossier',
      deleteDialogTitle: 'Supprimer le téléversement',
      deleteDialogText: 'Êtes-vous sûr de vouloir supprimer ce téléversement de façon définitive?',
      deleteDialogCancelButton: 'Annuler',
      deleteDialogConfirmButton: 'Confirmer',
      verified: 'Validé',
      historyInfoTitle: 'Liste des fichiers téléversés',
    },
    type: {
      label: 'Type de vérification',
      verified: 'Vérification par fichiers manquants',
      unverified: 'Sans vérification',
    },
    error1: 'Aucune pair trouvé pour un ou plusieurs fichiers.',
    error2: 'Un ou plusieurs fichiers déjà sélectionné.',
    error3: 'Erreur lors de l\'envoie des fichiers.',
    loading: {
      title: 'Téléversement des fichiers...',
      done: 'Préparation des fichiers...',
    },
  },
};

export default FR_LOCALES;
