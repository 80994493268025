







































import {Component, Prop, Vue} from 'vue-property-decorator';
import IconTooltip from '@/components/shared/IconTooltip.vue';

@Component({
  components: {
    IconTooltip,
  },
})
export default class SimpleInfo extends Vue {

  @Prop({
    required: true,
  })
  public label!: string;

  @Prop({
    required: true,
  })
  public value!: string;

  @Prop({
    default: false,
  })
  public isValueEmphasis!: boolean;

  @Prop({
    default: false,
  })
  public isCurrency!: boolean;

  @Prop({
    default: 'theme2--text',
  })
  public emphasisColor!: string;

  @Prop({
    default: false,
  })
  public isRightAligned!: boolean;

  @Prop({
    default: '',
  })
  public tooltipLabel!: string;

  @Prop({
    default: '',
  })
  public icon!: string;

  private getColor(isValue: boolean): string {
    if (
      (this.isValueEmphasis && isValue) ||
      (!this.isValueEmphasis && !isValue)
    ) {
      return this.emphasisColor;
    }
    return '';
  }
}
