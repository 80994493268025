








































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';
import {namespace} from 'vuex-class';
import {UISnackBar} from '@/store/models/ui_models';
import LevioLabLoader from '@/components/shared/LevioLabLoader.vue';
import {DataTableHeader} from 'vuetify';
import {ProcessingJob} from '@/store/models/processing_jobs_models';
import ProcessingResultDialog from '@/components/processing/ProcessingResultDialog.vue';
import {BudgetOptimizationInfo, BudgetOptimizationResult} from '@/store/models/pipeline_models';

const globalUI = namespace('GlobalUIStore');
const processing = namespace('ProcessingJobStore');
const pipeline = namespace('PipelineStore');

@Component({
  components: {
    ProcessingResultDialog,
    LevioLabLoader,
    LevioLabTitle,
  },
})
export default class ProcessingJobHistory extends Vue {
  @processing.Getter
  public processingJobs!: ProcessingJob[];

  @processing.Action
  public syncProcessingJob!: () => Promise<ProcessingJob[]>;

  @processing.Action
  public stopProcessingJob!: (jobName: string) => Promise<void>;

  @globalUI.Action
  public showSnackBar!: (payload: UISnackBar) => UISnackBar;

  @pipeline.Action
  private fetchBudgetOptimizationInfo!: (jobId: string) => Promise<BudgetOptimizationInfo>;

  @pipeline.Action
  private fetchOptimizationParentResult!: (jobId: string) => Promise<BudgetOptimizationResult>;
  //

  @Prop()
  private search!: string;

  private expanded: string[] = [];
  private isLoading: boolean = true;
  private syncInterval!: number;

  private processingResultDialogIsVisible = false;
  private processingResultDialogTitle: string = '';

  public created() {
    this.syncProcessingJob().then(() => this.isLoading = false);
    this.syncInterval = window.setInterval(() => {
      this.syncProcessingJob();
    }, 10000);
  }

  public destroyed() {
    if (this.syncInterval) {
      clearTimeout(this.syncInterval);
    }
  }

  private async onRowClicked(item: ProcessingJob) {
    if (item.jobType === 'budget-optimization') {
      // console.log(item.jobId);
      // const budgetOptimizationInfo: BudgetOptimizationInfo = await this.fetchBudgetOptimizationInfo(item.jobId);
      // console.log(budgetOptimizationInfo.output?.id);
      //
      // await this.fetchOptimizationParentResult(budgetOptimizationInfo!.output!.sourceJobId[0] as string);
      //
      // this.processingResultDialogIsVisible = true;
      // this.processingResultDialogTitle = item.jobId;
    }
  }

  private async processingResultDialogClose() {
    this.processingResultDialogIsVisible = false;
  }

  private localStopProcessingJob(jobName: string) {
    this.stopProcessingJob(jobName).then(() => {
      this.syncProcessingJob();
    });
  }

  private get processingJobsHeaders(): DataTableHeader[] {
    return [
      {
        text: '',
        value: 'progress',
        sortable: false,
        width: 30,
      },
      {
        text: this.$t('common.status') as string,
        value: 'jobStatus',
        sortable: true,
      },
      {
        text: this.$t('common.id') as string,
        value: 'jobId',
        sortable: true,
        width: '26%',
      },
      {
        text: this.$t('common.author') as string,
        value: 'author',
        sortable: true,
      },
      {
        text: this.$t('common.type').toString(),
        value: 'jobType',
        sortable: true,
      },
      // {
      //   text: this.$t('processing.jobName').toString(),
      //   value: 'jobName',
      //   sortable: true,
      // },
      {
        text: this.$t('processing.creationTime') as string,
        value: 'creationTime',
        sortable: true,
      },
      {
        text: this.$t('processing.duration') as string,
        value: 'duration',
        sortable: true,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ];
  }
}
