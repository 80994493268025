import Vue from 'vue';
import Amplify from 'aws-amplify';
import Vuelidate from 'vuelidate';

import awsConfig from './config/aws.config';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import '@/styles/scss/main.scss';
import './config/axios.config.ts';
import vuetify from './plugins/vuetify';
import store from '@/store/store';
import { Roles } from '@/plugins/roles';

Vue.config.productionTip = false;

Amplify.configure(awsConfig);

Vue.filter('kb', (val) => {
  return Math.floor(val / 1024);
});

Vue.filter('mb', (val) => {
  return Math.floor(val / 1024 / 1024);
});

Vue.use(Roles);
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
