import { FileInfo } from '@/store/models/upload_models';

const requiredFilesListData: FileInfo[] = [
  {
    name: 'Email',
    pattern: /^dgag_email_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.email',
    example: 'dgag_email_2020-08-13_1.csv',
  },
  {
    name: 'Price Index',
    pattern: /^dgag_price-index_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.priceIndex',
    example: 'dgag_price-index_2020-08-13_1.csv',
  },
  {
    name: 'Addressed DM',
    pattern: /^dgag_addressed-dm_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.addressedDM',
    example: 'dgag_addressed-dm_2020-08-13_1.csv',
  },
  {
    name: 'Unaddressed DM',
    pattern: /^dgag_unaddressed-dm_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.unaddressedDM',
    example: 'dgag_unaddressed-dm_2020-08-13_1.csv',
  },
  {
    name: 'Print',
    pattern: /^dgag_print_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.print',
    example: 'dgag_print_2020-08-13_1.csv',
  },
  {
    name: 'Phone',
    pattern: /^dgag_phone_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.phone',
    example: 'dgag_phone_2020-08-13_1.csv',
  },
  {
    name: 'PPC',
    pattern: /^dgag_ppc_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.ppc',
    example: 'dgag_ppc_2020-08-13_1.csv',
  },
  {
    name: 'Radio',
    pattern: /^dgag_radio_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.radio',
    example: 'dgag_radio_2020-08-13_1.csv',
  },
  {
    name: 'References',
    pattern: /^dgag_references_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.references',
    example: 'dgag_references_2020-08-13_1.csv',
  },
  {
    name: 'Quotes',
    pattern: /^dgag_quotes_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.quotes',
    example: 'dgag_quotes_2020-08-13_1.csv',
  },
  {
    name: 'Sales',
    pattern: /^dgag_sales_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.sales',
    example: 'dgag_sales_2020-08-13_1.csv',
  },
  {
    name: 'Television',
    pattern: /^dgag_television_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.television',
    example: 'dgag_television_2020-08-13_1.csv',
  },
  {
    name: 'Web Advertising',
    pattern: /^dgag_web-ad_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.webAdvertising',
    example: 'dgag_web-ad_2020-08-13_1.csv',
  },
  {
    name: 'Google Trends',
    pattern: /^dgag_google-trends_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.googleTrends',
    example: 'dgag_google-trends_2020-08-13_1.csv',
  },
  {
    name: 'Population Geography',
    pattern: /^dgag_geo-population_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.geoPopulation',
    example: 'dgag_geo-population_2020-08-14_1.csv',
  },
  {
    name: 'Desjardins Holidays',
    pattern: /^dgag_desjardins-holidays_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.desjardinsHolidays',
    example: 'dgag_desjardins-holidays_2020-09-28_1.csv',
  },
  {
    name: 'Production Costs',
    pattern: /^dgag_production-cost_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.productionCosts',
    example: 'dgag_production-cost_2020-09-28_1.csv',
  },
  {
    name: 'CLV',
    pattern: /^dgag_clv_\d{4}-\d{1,2}-\d{1,2}_\d+\.csv$/,
    description: 'files.clv',
    example: 'dgag_clv_2020-09-28_1.csv',
  },
];

export const requiredFilesList: FileInfo[] = requiredFilesListData;
