import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { validationMixin } from 'vuelidate';
import { mixins } from 'vue-class-component';
import { AnnualContext, AnnualOfficialData } from '@/store/models/annual_models';

const annual = namespace('AnnualStore');

@Component({})
export class AnnualStoreMixin extends mixins(validationMixin) {

  /* Getters */
  @annual.Getter
  public annualContexts!: AnnualContext[];

  @annual.Getter
  public annualOfficialDataItems!: AnnualOfficialData[];

  @annual.Getter
  public officialYears!: number[];

  /* Mutations */
  @annual.Mutation
  public setAnnualContexts!: (value: AnnualContext[]) => void;

  /* Actions */
  @annual.Action
  public postAnnualContext!: (data: AnnualContext) => Promise<void>;

  @annual.Action
  public fetchAnnualContext!: () => Promise<AnnualContext[]>;

  @annual.Action
  public fetchAnnualOfficialData!: () => Promise<AnnualOfficialData[]>;

  @annual.Action
  public fetchOfficialAnnualDataById!: (id: string) => Promise<AnnualOfficialData | null>;

  @annual.Action
  public postOfficialAnnual!: (data: AnnualOfficialData) => Promise<void>;
}
