










import {Component, Vue} from 'vue-property-decorator';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import {namespace} from 'vuex-class';
import OptimizationResult from '@/components/budgetPlan/OptimizationResult.vue';
import PowerBIElement from '@/components/shared/PowerBIElement.vue';
import {pbiVisualsList} from '@/utils/data/pbi_visuals';
import {PBIElement} from '@/store/models/powerBi_models';
import AlertWidget from '@/components/shared/AlertWidget.vue';

const globalUI = namespace('GlobalUIStore');
const pipeline = namespace('PipelineStore');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pbiReports = namespace('PowerBIStore');

@Component({
  components: {
    LandingPageLayout,
    OptimizationResult,
    PowerBIElement,
    AlertWidget,
  },
})
export default class ModelGovernanceLandingPage extends Vue {

  @globalUI.Getter
  private isDemoMode!: boolean;

  private get visuals(): { [name: string]: PBIElement } {
    return pbiVisualsList;
  }

  private get governanceReport(): PBIElement {
    return this.visuals.modelGovernanceReport;
  }
}
