
















import { Component, Vue } from 'vue-property-decorator';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import UploadHistory from '@/components/history/UploadHistory.vue';

@Component({
  components: {
    UploadHistory,
    LandingPageLayout,
  },
})
export default class HistoryLandingPage extends Vue {

}
