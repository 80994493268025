











































































































































































import i18n from '@/plugins/i18n';
import { namespace } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { mixins } from 'vue-class-component';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';

import { getAttributionBrand, getAttributionYear } from '@/utils/easy-getters';
import Rules from '@/utils/rules';

import { Brand, BudgetPlanType, StepType } from '@/store/enums/pipeline_enums';
import { Attribution, PipelineOutput } from '@/store/models/pipeline_models';
import { AnnualStoreMixin } from '@/mixins/store/AnnualStoreMixin';

import BudgetTable from '@/components/budgetPlan/specificInfos/BudgetTable.vue';
import InformationSummary from '@/components/shared/InformationSummary.vue';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import LevioLabToolBarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';
import LevioLabToolBarCTA from '@/components/shared/levioToolBar/LevioLabToolBarCTA.vue';
import OfficializationOutputItem from '@/components/officialization/OfficializationOuputItem.vue';
import { allAvailableYears, nextYears } from '@/utils/common';
import OfficializedDataCard from '@/components/officialization/OfficializedDataCard.vue';
import { translateEnum } from '@/utils/localisation';
import { AnnualOfficialData } from '@/store/models/annual_models';

const pipeline = namespace('PipelineStore');

@Component({
  components: {
    BudgetTable,
    InformationSummary,
    LevioLabToolbar,
    closeButton: LevioLabToolBarCloseButton,
    ctaButton: LevioLabToolBarCTA,
    OfficializationOutputItem,
    OfficializedDataCard,
  },
})
export default class OfficializationDialog extends mixins(AnnualStoreMixin) {
  @Prop()
  private closeDialog!: () => void;

  @Prop({ default: false })
  private latestAttributionMode!: boolean;

  @Prop({
    default: StepType.MODELLING_ATTRIBUTIONS,
  })
  private officializationType!: StepType;

  @Prop({
    default: null,
  })
  private budgetPlanType!: BudgetPlanType | null;

  @pipeline.Getter
  private etlOutputs!: PipelineOutput[];

  @pipeline.Getter
  private tableauPreparationOutputs!: PipelineOutput[];

  @pipeline.Getter
  private optimizationOutputs!: PipelineOutput[];

  @pipeline.Getter
  private currentAttribution!: Attribution;

  @pipeline.Getter
  private latestAttributionOutputs!: PipelineOutput[];

  @pipeline.Action
  private fetchAttributionByJobId!: (jobId: string) => Promise<Attribution>;

  @pipeline.Action
  private fetchTableauPreparationOutputs!: () => Promise<PipelineOutput[]>;

  @pipeline.Action
  private fetchETLOutputs!: () => Promise<PipelineOutput[]>;

  @pipeline.Action
  private fetchOptimizationList!: () => Promise<PipelineOutput[]>;

  private selectedOutput: PipelineOutput | null = null;
  private isLoading: boolean = true;

  @Validate({ required })
  private selectedBrand: { value: Brand, text: string } | { value: Brand, text: string } [] | null = null;

  @Validate({ required })
  private selectedYear: number | number[] | null = null;

  private isOfficializationConfirmationDialogVisible = false;

  protected async mounted() {
    this.isLoading = true;

    await this.fetchAnnualOfficialData();

    switch (this.officializationType) {
      case StepType.ETL:
        await this.fetchAnnualOfficialData();
        await this.fetchETLOutputs();
        break;
      case StepType.OPTIMIZATION:
        await this.fetchOptimizationList();
        break
      case StepType.MODELLING_ATTRIBUTIONS:
      default:
        await this.fetchTableauPreparationOutputs();
        if (this.tableauPreparationOutputs) {
          await this.updateAttributionData();
        }
        break;
    }

    this.isLoading = false;
  }

  private get brandsList() {
    const brandsItems: { value: string, text: string }[] = [
      {
        value: Brand.DA,
        text: this.$t('enum.brands.da') as string,
      },
      {
        value: Brand.DI,
        text: this.$t('enum.brands.di') as string,
      },
      {
        value: Brand.DAN,
        text: this.$t('enum.brands.dan') as string,
      },
    ];

    if (this.officializationType === StepType.MODELLING_ATTRIBUTIONS) {
      brandsItems.push(
        {
          value: Brand.SYNERGY,
          text: this.$t('enum.brands.synergy') as string,
        },
      );
    }

    return {
      select: [],
      items: brandsItems,
    };
  }

  // Filtering
  private async onBrandsChanged(): Promise<void> {
    this.$v.selectedBrand.$touch();
    this.selectedOutput = this.tableauPreparationOutputs[0];
  }

  private get brandStringInId(): string {
    let brandString: string = this.selectedBrand ? (this.selectedBrand as { text: string, value: string }).value : '';
    if ((this.selectedBrand as { text: string, value: string }).value === Brand.SYNERGY) {
      brandString = brandString.toLowerCase();
    } else {
      brandString = brandString.toUpperCase();
    }
    return brandString;
  }

  private get filteredOutputs() {
    let filteredOutputsItems: PipelineOutput[] = [];

    switch (this.officializationType) {
      case StepType.ETL:
        filteredOutputsItems = this.etlOutputs;
        break;
      case StepType.OPTIMIZATION:
        if (this.budgetPlanType) {
          filteredOutputsItems = this.getBudgetOptimizationOutputsByType(this.budgetPlanType);
        } else {
          filteredOutputsItems = [];
        }
        break;
      case StepType.MODELLING_ATTRIBUTIONS:
      default:
        filteredOutputsItems = this.tableauPreparationOutputs;
        break;
    }

    if (this.isAttributionType || this.isOptimizationType) {
      if (this.selectedYear) {
        filteredOutputsItems = filteredOutputsItems.filter((output: PipelineOutput) => {
          return getAttributionYear(output) === this.selectedYear!;
        });
      }

      if (this.selectedBrand) {
        filteredOutputsItems = filteredOutputsItems.filter((output: PipelineOutput) => {
          if (this.isAttributionType) {
            const lastIndex = output.additionalProperties.attributionsResultsSummary!.jobId.length - (this.selectedBrand as { text: string, value: string })!.value.length;
            return output.additionalProperties.attributionsResultsSummary!.jobId.indexOf(this.brandStringInId) === lastIndex;
          } else if (this.isOptimizationType) {
            return getAttributionBrand(output) === (this.selectedBrand as { value: Brand, text: string }).value;
          }
        });
      }
    }

    return filteredOutputsItems;
  }

  @Watch('selectedOutput')
  private async onAttributionChange() {
    if (this.isAttributionType) {
      this.isLoading = true;
      await this.updateAttributionData();
      this.isLoading = false;
    }
  }

  private async updateAttributionData() {
    if (this.selectedOutput) {
      await this.fetchAttributionByJobId(this.selectedOutput.sourceJobId[0]);
    }
  }

  private showConfirmationDialog() {
    this.isOfficializationConfirmationDialogVisible = true;
  }

  private hideConfirmationDialog() {
    this.isOfficializationConfirmationDialogVisible = false;
  }

  private async officialize() {
    this.isLoading = true;
    this.hideConfirmationDialog();

    if (this.isETLType) {
      const years: number[] = this.selectedYear! as number[];
      const selectedBrands: { text: string, value: string }[] = this.selectedBrand as { text: string, value: string }[];
      for (const year of years) {
        for (const brand of selectedBrands) {
          await this.postOfficialAnnual(
            {
              id: year + '-' + brand.value.toLowerCase(),
              year: +year,
              brand: brand.value,
              etlJobId: this.selectedOutput!.jobId,
            },
          );
        }
      }
    } else {
      const year: number = this.selectedYear! as number;
      const selectedBrand: { text: string, value: string } = this.selectedBrand as { text: string, value: string };
      let annualData: AnnualOfficialData | null = null;

      if (this.officializationType === StepType.MODELLING_ATTRIBUTIONS) {
        annualData = {
          id: year + '-' + selectedBrand.value.toLowerCase(),
          year: +year,
          brand: selectedBrand.value,
          attributionsJobId: this.selectedOutput!.jobId,
        };
      } else if (this.officializationType === StepType.OPTIMIZATION) {
        if (this.budgetPlanType && this.budgetPlanType === BudgetPlanType.OPTIMIZATION) {
          annualData = {
            id: year + '-' + selectedBrand.value.toLowerCase(),
            year: +year,
            brand: selectedBrand.value,
            optimizationJobId: this.selectedOutput!.jobId,
          };
        } else if (this.budgetPlanType && this.budgetPlanType === BudgetPlanType.PREDICTION) {
          annualData = {
            id: year + '-' + selectedBrand.value.toLowerCase(),
            year: +year,
            brand: selectedBrand.value,
            predictionJobId: this.selectedOutput!.jobId,
          };
        }
      }

      if (annualData) {
        await this.postOfficialAnnual(annualData);
      }
    }
    await this.fetchAnnualOfficialData();
    this.selectedOutput = null;
    this.selectedBrand = null;
    this.selectedYear = null;
    this.isLoading = false;
  }

  /* Getters */

  private get jobListLabel(): string {
    let label: string = '';
    if (this.filteredOutputs.length === 0) {
      label = this.$t('attributions.notFound') as string;
    } else {
      if (this.budgetPlanType) {
        label = `${this.$t('common.jobId') as string} (${translateEnum(this.budgetPlanType, 'budgetPlanType') as string})`;
      } else {
        label = `${this.$t('common.jobId') as string} (${translateEnum(this.officializationType, 'stepType') as string})`;
      }
    }
    return label;
  }

  public get attributionInfo(): Array<{ label: string, value: string }> {
    if (this.currentAttribution) {
      return [
        {
          label: i18n.t('common.location.province') as string,
          value: this.currentAttribution.summary.province,
        },
        {
          label: i18n.t('common.totalQuotes') as string,
          value: this.currentAttribution.summary.totalQuotes.toString(),
        },
      ];
    } else {
      return [];
    }

  }

  private get comboYears(): number[] {
    let years: number[] = [];
    if (this.officializationType === StepType.OPTIMIZATION) {
      years = nextYears()
    } else {
      years = allAvailableYears().reverse();
    }
    return years;
  }

  private getBudgetOptimizationOutputsByType(budgetPlanType: BudgetPlanType): PipelineOutput[] {
    let filteredPredictions: PipelineOutput[] = [];
    let config: any = '';

    filteredPredictions = this.optimizationOutputs.filter((output: PipelineOutput) => {
      if (output.stepParams['CONFIG']) {
        config = JSON.parse(output.stepParams['CONFIG']);
        return config.category && config.category === budgetPlanType;
      }
      return false;
    });

    return filteredPredictions;
  }

  private get isAttributionListDisabled(): boolean {
    return this.filteredOutputs.length < 1 || !this.selectedBrand;
  }

  private get isOfficializeDisabled(): boolean {
    return this.isLoading || !this.selectedBrand;
  }

  private get isETLType(): boolean {
    return this.officializationType === StepType.ETL;
  }

  private get isAttributionType(): boolean {
    return this.officializationType === StepType.MODELLING_ATTRIBUTIONS;
  }

  private get isOptimizationType(): boolean {
    return this.officializationType === StepType.OPTIMIZATION;
  }

  private get isPrediction(): boolean {
    return this.budgetPlanType === BudgetPlanType.PREDICTION;
  }

  private get isBudgetOptimization(): boolean {
    return this.budgetPlanType === BudgetPlanType.OPTIMIZATION;
  }

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }
}
