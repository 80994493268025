export enum PBIElementType {
  REPORT = 'report',
  DASHBOARD = 'dashboard',
}

export enum PBIVisualViewType {
  VIEW_ONLY,
  VIEW_FILTER,
  EDIT,
}
