var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.processingJobsHeaders,"items":_vm.processingJobs,"sort-by":"CreationTime","sort-desc":"","expanded":_vm.expanded,"item-key":"ProcessingJobName","loading":_vm.isLoading,"search":_vm.search,"footer-props":{
        itemsPerPageText: _vm.$t('common.table.itemsPerPage'),
        itemsPerPageOptions: [10, 30, 50]
      }},on:{"click:row":_vm.onRowClicked,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.progress",fn:function(ref){
      var item = ref.item;
return [(item.jobStatus === 'InProgress' || item.jobStatus === 'Stopping')?[_c('v-progress-circular',{attrs:{"size":"20","width":"2","indeterminate":"","color":"primary"}})]:_vm._e()]}},{key:"item.jobStatus",fn:function(ref){
      var item = ref.item;
return [(item.jobStatus === 'InProgress')?[_c('span',{staticClass:"grey--text text--darken-1 font-weight-bold"},[_vm._v(_vm._s(item.jobStatus))])]:(item.jobStatus === 'Completed')?[_c('span',{staticClass:"green--text text--darken-1 font-weight-bold"},[_vm._v(" "+_vm._s(item.jobStatus)+" ")])]:(item.jobStatus === 'Failed')?[_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(_vm._s(item.jobStatus))])]:(item.jobStatus === 'Stopping')?[_c('span',{staticClass:"grey--text text--darken-2  font-weight-bold"},[_vm._v(" "+_vm._s(item.jobStatus)+" ")])]:(item.jobStatus === 'Stopped')?[_c('span',{staticClass:"text--darken-2 font-weight-bold"},[_vm._v(_vm._s(item.jobStatus))])]:[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.jobStatus))])]]}},{key:"item.jobId",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[(item.name && item.name !== 'NA')?[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"caption ml-1"},[_vm._v("("+_vm._s(item.jobId)+")")])]:[_vm._v(" "+_vm._s(item.jobId)+" ")]],2)]}},{key:"item.author",fn:function(ref){
      var item = ref.item;
return [(item.author)?[_vm._v(" "+_vm._s(item.author)+" ")]:[_vm._v(" N/A ")]]}},{key:"item.creationTime",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.creationTime.toLocaleString())+" ")]}},{key:"item.duration",fn:function(ref){
      var item = ref.item;
return [(item.endTime)?[_vm._v(" "+_vm._s(Math.round((item.endTime.getTime() - item.creationTime.getTime()) / 1000 / 60))+" minutes ")]:[_vm._v(" "+_vm._s(Math.round((Date.now() - item.creationTime.getTime()) / 1000 / 60))+" minutes ")]]}},{key:"item.failureReason",fn:function(ref){
      var item = ref.item;
return undefined}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{attrs:{"dense":""}},[(item.failureReason)?[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,true)},[_c('p',[_vm._v(_vm._s(item.failureReason))])])],1)]:_vm._e(),(item.jobStatus === 'InProgress')?[_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.localStopProcessingJob(item.jobName)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-stop ")])],1)],1)]:_vm._e()],2)]}}])})],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.processingResultDialogIsVisible),callback:function ($$v) {_vm.processingResultDialogIsVisible=$$v},expression:"processingResultDialogIsVisible"}},[_c('ProcessingResultDialog',{attrs:{"close-dialog":_vm.processingResultDialogClose,"job-title":_vm.processingResultDialogTitle}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }