import axios from 'axios';
import { getDuplicateLabel, isProd, parseRequestErrorType } from '@/utils/common';
import { RequestErrorType } from '@/store/enums/api_enum';
import { RequestError } from '@/store/models/common_models';

export default class BaseService {
  public static async getJSON(url: string, params?: any): Promise<any | RequestErrorType> {
    try {
      const { data } = await axios.get(url, {
        params,
      });
      return data;
    } catch (e) {
      if (!isProd()) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return BaseService.getError(e);
    }
  }

  public static async postJSON(url: string, payload: any): Promise<any | RequestError> {
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (e) {
      if (!isProd()) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return BaseService.getError(e);
    }
  }

  private static getError(e: any): RequestError {
    const type: RequestErrorType = parseRequestErrorType(e.response.data.error);
    let erroredValue: any = '';
    if (type === RequestErrorType.TITLE_DUPLICATE) {
      erroredValue = getDuplicateLabel(e.response.data.error);
    }
    return {
      type,
      erroredValue,
      fullError: e.response.data.error,
    } as RequestError;
  }
}
