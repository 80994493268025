import {Vue} from 'vue-property-decorator';
import Acl from 'vue-browser-acl';
import store from '@/store/store';
import {
  AdvancedTools,
  Attribution,
  Dashboard,
  ModelGovernance,
  Optimization,
  Processing,
  Staff,
  Support,
  Upload,
} from '@/store/models/permission_models';
import {UserData} from '@/store/models/auth_models';
import {UserRoles} from '@/store/enums/auth_enums';

const currentUserData = (): UserData => {
  return store.getters['AuthStore/currentUserData'] as UserData;
};

Vue.use(Acl, currentUserData, (acl) => {
  acl.register(Dashboard, 'Dashboard');
  acl.register(Attribution, 'Attribution');
  acl.register(Optimization, 'Optimization');
  acl.register(AdvancedTools, 'AdvancedTools');
  acl.register(Processing, 'Processing');
  acl.register(Upload, 'Upload');
  acl.register(ModelGovernance, 'ModelGovernance');
  acl.register(Support, 'Support');

  acl.rule('view', Dashboard, isEveryone);

  acl.rule('view', Attribution, isAtLeastAuditor);
  acl.rule('create', Attribution, isDataScientistOrAdmin);
  acl.rule('analyze', Attribution, isDataScientistOrAdmin);
  acl.rule('officialize', Attribution, isDataScientistOrAdmin);

  acl.rule('view', Optimization, isAtLeastAuditor);
  acl.rule('create', Optimization, isMarketingSpecialistOrAdmin);

  acl.rule('view', Processing, isAtLeastMarketingSpecialist);

  acl.rule('view', AdvancedTools, isAtLeastMarketingSpecialist);
  acl.rule('create', AdvancedTools, isAtLeastMarketingSpecialist);

  acl.rule('view', Upload, isDataScientistOrAdmin);
  acl.rule('create', Upload, isDataScientistOrAdmin);

  acl.rule('manage', Staff, isAdministrator);

  acl.rule('view', ModelGovernance, isAdministrator);

  acl.rule('view', Support, isEveryone);
});

export const Roles = {
  install(vue) {
    vue.mixin({});
  },
};

// Base Comparisons
const hasRole = (userData: UserData): boolean => {
  return userData.roles && userData.roles.length > 0;
};

function isEveryone(): boolean {
  return true;
}

function isAuditor(userData: UserData | null): boolean {
  if (userData && hasRole(userData)) {
    return userData!.roles[0] === UserRoles.AUDITOR;
  } else {
    return false;
  }
}

function isDataScientist(userData: UserData | null): boolean {
  if (userData && hasRole(userData)) {
    return userData!.roles[0] === UserRoles.DATA_SCIENTIST;
  } else {
    return false;
  }
}

function isMarketingSpecialist(userData: UserData | null): boolean {
  if (userData && hasRole(userData)) {
    return userData!.roles[0] === UserRoles.MARKETING_SPECIALIST;
  } else {
    return false;
  }
}

function isAdministrator(userData: UserData | null): boolean {
  if (userData && hasRole(userData)) {
    return userData!.roles[0] === UserRoles.ADMINISTRATOR;
  } else {
    return false;
  }
}

// Composite Comparisons
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function isAtLeastAuditor(userData: UserData): boolean {
  return isAuditor(userData) || isAtLeastMarketingSpecialist(userData);
}

function isAtLeastMarketingSpecialist(userData: UserData): boolean {
  return isMarketingSpecialist(userData) || isDataScientistOrAdmin(userData);
}

function isDataScientistOrAdmin(userData: UserData): boolean {
  return isDataScientist(userData) || isAdministrator(userData);
}

function isMarketingSpecialistOrAdmin(userData: UserData): boolean {
  return isMarketingSpecialist(userData) || isAdministrator(userData);
}



