import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { Auth } from 'aws-amplify';

import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  CompletePasswordForm,
  User,
  CognitoUserData,
  UserData,
  NewPasswordForm,
} from '@/store/models/auth_models';
import router from '@/router';
import axios from 'axios';

@Module({ namespaced: true, name: 'authModule' })
export default class AuthStore extends VuexModule {
  // Levio
  private $currentUserData: UserData | null = null;

  // Cognito
  private $authorized: boolean = false;
  private $user: User | null = null;
  private $userToComplete: CognitoUser | null = null;

  public get authorized(): boolean {
    return this.$authorized;
  }

  public get user(): User | null {
    return this.$user;
  }

  public get userToComplete(): CognitoUser | null {
    return this.$userToComplete;
  }

  public get isUserToCompleteNotNull(): boolean {
    return this.$userToComplete != null;
  }

  @Mutation
  public setUser(user: User) {
    this.$authorized = !!user && user.attributes != null && user.attributes.email_verified;
    this.$user = user;
  }

  @Mutation
  public setUserToComplete(userToComplete: CognitoUser) {
    this.$userToComplete = userToComplete;
  }

  @Action
  public async login(authData: CognitoUserData): Promise<void> {
    try {
      const user = await Auth.signIn(authData.email, authData.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.context.commit('setUserToComplete', user);
        await router.replace('/completePassword');
      } else {
        await this.context.dispatch('fetchUser');
        await this.context.dispatch('fetchCurrentUserData');
      }
    } catch (err) {
      if (err) {
        if (err.code) {
          await this.context.dispatch('GlobalUIStore/showSnackBar',
            {
              color: 'error',
              text: 'auth.error.' + err.code,
            },
            { root: true },
          );
          throw new Error(err.code);
        } else {
          await this.context.dispatch('GlobalUIStore/showSnackBar',
            {
              color: 'error',
              text: 'auth.error.Unknown',
            },
            { root: true },
          );
        }
      }
      return;
    }
  }

  @Action
  public async completePassword(completePasswordData: CompletePasswordForm): Promise<void> {
    if (this.context.getters.userToComplete) {
      Auth.completeNewPassword(this.context.getters.userToComplete, completePasswordData.newPassword, {})
        .then(() => {
          this.context.commit('setUserToComplete', null);
        })
        .catch((err) => {
          this.context.dispatch('GlobalUIStore/showSnackBar',
            {
              color: 'error',
              text: 'auth.error.' + err.code,
            },
          );
          throw new Error(err.code);
        });
    } else {
      await router.replace('/signin');
    }
  }

  @Action
  public async authState(state) {
    if (state === 'signedIn') {
      await this.context.dispatch('fetchUser');
    } else {
      this.context.commit('setUser', null);
    }
  }

  @Action
  public async fetchUser() {
    try {
      const user: User = await Auth.currentAuthenticatedUser();

      if (user != null && user.getSignInUserSession() != null) {
        const expires = user.getSignInUserSession()!.getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000);

        setTimeout(async () => {
          await this.context.dispatch('fetchUser');
        }, expires * 1000);
        this.context.commit('setUser', user);
      } else {
        this.context.commit('setUser', null);
      }
    } catch (err) {
      this.context.commit('setUser', null);
    }
  }

  @Action
  public async logout() {
    await Auth.signOut();
    clearInterval(this.context.getters['GlobalUIStore/inactivityInterval']);
    this.context.commit('setUser', null);
    this.context.commit('DashboardStore/setDashboardUrls', [], { root: true });
  }

  @Action
  public async forgotPassword(email: string) {
    await Auth.forgotPassword(email,
    ).catch((err) => {
      this.context.dispatch('GlobalUIStore/showSnackBar',
          {
            color: 'error',
            text: 'auth.error.' + err.code,
          },
      );
      throw new Error(err.code);
    });
  }

  @Action
  public async submitNewPassword(newPasswordData: NewPasswordForm) {
    await Auth.forgotPasswordSubmit(newPasswordData.email, newPasswordData.code, newPasswordData.newPassword,
    ).then(() => {
      return true;
    }).catch((err) => {
      this.context.dispatch('GlobalUIStore/showSnackBar',
          {
            color: 'error',
            text: 'auth.error.' + err.code,
          },
      );
      throw new Error(err.code);
    });
  }

  // Levio
  // Current User Data
  public get currentUserData(): UserData | null {
    return this.$currentUserData;
  }

  @Mutation
  public setCurrentUserData(userData: UserData) {
    this.$currentUserData = userData;
  }

  @Action({ commit: 'setCurrentUserData' })
  public async fetchCurrentUserData(): Promise<UserData | null | undefined> {
    try {
      const data = await axios.get('/user/self');
      const user: UserData =  (data.data as UserData);
      const brandQC: string[] = ['DA'];
      const brandON: string[] = ['DI-Direct', 'DI-DAN'];

      user.brands = [...brandON, ...brandQC];
      return user;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }
}
