























































import {Component, Vue, Watch} from 'vue-property-decorator';
import BottomDialog from '@/components/shared/BottomDialog.vue';
import {DefinitionItem} from '@/store/models/definition_models';
import {lexiconBrands, lexiconChannels, lexiconMedias, lexiconOthers} from '@/utils/data/lexicon';
import {DefinitionType} from '@/store/enums/defininition_enum';

@Component({
  components: {
    BottomDialog,
  },
})
export default class AttributionDisclaimerDialog extends Vue {

  private openItem: number | null = 0;
  private search: string = '';
  private lexiconList: DefinitionItem[] = [];

  private mounted(): void {
    this.generateLexiconList();
  }

  private get disclaimersInfos(): Array<{ title: string, content: string }> {
    return [
      {
        title: 'disclaimer.modeling.title',
        content: 'disclaimer.modeling.content',
      },
      {
        title: 'disclaimer.modelDetails.title',
        content: 'disclaimer.modelDetails.content',
      },
      {
        title: 'disclaimer.lexicon.title',
        content: 'disclaimer.modelDetails.content',
      },
    ];
  }

  @Watch('search')
  private generateLexiconList(): void {
    let finalContent: DefinitionItem[] = [];
    let filteredBrands = lexiconBrands;
    let filteredMedias = lexiconMedias;
    let filteredChannels = lexiconChannels;
    let filteredOthers = lexiconOthers;

    if (this.search && this.search.length >= 2) {
      filteredBrands = lexiconBrands.filter((item) => this.isSearchStringFound(item));
      filteredMedias = lexiconMedias.filter((item) => this.isSearchStringFound(item));
      filteredChannels = lexiconChannels.filter((item) => this.isSearchStringFound(item));
      filteredOthers = lexiconOthers.filter((item) => this.isSearchStringFound(item));
    }

    if (filteredBrands.length > 0) {
      finalContent = finalContent.concat({
        label: 'disclaimer.lexicon.brands.title',
        type: DefinitionType.TITLE,
      }).concat(filteredBrands);
    }

    if (filteredMedias.length > 0) {
      finalContent = finalContent.concat({
        label: 'disclaimer.lexicon.medias.title',
        type: DefinitionType.TITLE,
      }).concat(filteredMedias);
    }

    if (filteredChannels.length > 0) {
      finalContent = finalContent.concat({
        label: 'disclaimer.lexicon.quotesChannels.title',
        type: DefinitionType.TITLE,
      }).concat(filteredChannels);
    }

    if (filteredOthers.length > 0) {
      finalContent = finalContent.concat({
        label: 'disclaimer.lexicon.others.title',
        type: DefinitionType.TITLE,
      }).concat(filteredOthers);
    }

    this.lexiconList = finalContent;
  }

  private isSearchStringFound(item: DefinitionItem): boolean {
    const localeLabel: string = (this.$t(item.label) as string).toLowerCase();
    const localeDesc: string = item.description ? (this.$t(item.description) as string).toLowerCase() : '';
    const searchTerm: string = this.search.toLocaleLowerCase();
    return localeLabel.indexOf(searchTerm) !== -1 || localeDesc.indexOf(searchTerm) !== -1;
  }

  private isTitle(item: DefinitionItem): boolean {
    return item.type === DefinitionType.TITLE;
  }
}
