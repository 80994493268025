import moment from 'moment';
import dayjs from 'dayjs';
import { Vue } from 'vue-property-decorator';
import { RequestErrorType } from '@/store/enums/api_enum';
import { RequestError } from '@/store/models/common_models';

export const isProd = (): boolean => {
  return process.env.NODE_ENV !== 'development';
};

export const getBrowserLanguage = (): string => {
  const nav: any = navigator;
  if (nav.languages && nav.languages.length) {
    return nav.languages[0];
  } else {
    return nav.userLanguage || nav.language || nav.browserLanguage || 'en';
  }
};

export const getBrowserLanguageShort = (): string => {
  return getBrowserLanguage().substr(0, 2);
};

export const form = (element: Vue | Element | Vue[] | Element[]): Vue & {
  validate: () => boolean,
  reset: () => boolean,
  resetValidation: () => boolean,
} => {
  return element as Vue & {
    validate: () => boolean,
    reset: () => boolean,
    resetValidation: () => boolean,
  };
};

export const isStringNumber = (value) => !isNaN(Number(value));

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function hasDuplicates<T>(arr: T[]): boolean {
  return new Set(arr).size < arr.length;
}

export function getDuplicates(arr: any[]): any[] {
  return arr.reduce(function(acc, el, i, arr) {
    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
  }, [])
}

/* -------------------------- */
/* Timing and Dates functions */
/* -------------------------- */

export const getDate = (): moment.Moment => {
  return moment();
};

export const getHours = (time: Date): string => {
  return moment(time).format('HH');
};

export const getMinutes = (time: Date): string => {
  return moment(time).format('mm');
};

export const getNumberFromComplexString = (
  value: string, units: number = 2,
): number => {
  // Validation when the v-money directive is being set.
  if (typeof value.replace !== 'function') {
    return 0;
  }
  return Number(value.replace(/[^\d-]/g, '')) / Math.pow(10, units);
};

export function getNextYears(amountYears: number = 2, startYear: number | string = 'current'): number[] {
  let i: number = 1;
  const years: number[] = [];
  const firstYear: number = startYear !== 'current' && typeof startYear === 'number'? startYear : dayjs().year();
  years.push(firstYear);
  while (i <= amountYears) {
    years.push(firstYear + i);
    i++;
  }
  return years;
}

export function rangeOfYears(start, end) {
  return Array(end - start + 1).fill(start).map((year, index) => year + index);
}

export const allAvailableYears = () => rangeOfYears(2015, dayjs().year());

export const nextYears = () => {
    const currentYear: number = dayjs().year();
    return getNextYears(4, currentYear - 1);
};

/* ----- */
/* Enums */
/* ----- */

export function isEnum<T>(enumType: T, value: any) {
  return getEnumValues(enumType).find((item) => item === value) !== undefined;
}

export function getEnumValues<T>(enumType: T) {
  // if your enum has string value, get rid of the "Exclude<>" wrapper
  type ValType = T extends {[k: string]: infer X} ? Exclude<X, string> : any;

  const entryNames = Object.keys(enumType).filter(key=>!/[0-9]+/.test(key[0]));
  return entryNames.map(name=>enumType[name] as ValType );
}

/* ------ */
/* Errors */
/* ------ */

export function isError(object: any): boolean {
  return isEnum(RequestErrorType, (object as RequestError).type)
}

export const parseRequestErrorType = (error: string): RequestErrorType => {
  if(error.indexOf('already used') !== -1){
    return RequestErrorType.TITLE_DUPLICATE;
  }
  return RequestErrorType.DEFAULT;
}

export const getDuplicateLabel = (error: string): string => {
  if(error.indexOf('already used') !== -1){
    error = error.substring(0, error.indexOf('already used'));
  }
  return error;
}
