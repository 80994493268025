import { Component, Vue } from 'vue-property-decorator';
import { PipelineOutput } from '@/store/models/pipeline_models';
import { namespace } from 'vuex-class';

const upload = namespace('UploadStore');

@Component({})
export default class CSVDownloadMixin extends Vue {

  @upload.Action
  private getUrlForFile!: (key: string) => Promise<string>;

  public async downloadFileFromJob(job: PipelineOutput | null): Promise<void> {
    if (job) {
      const url = await this.getUrlForFile(`budget-optimization-output/results/${job.jobId}/results.csv`);

      if (url) {
        const fileLink = document.createElement('a');

        fileLink.href = url;
        fileLink.setAttribute('download', job.fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    }
  }
}
