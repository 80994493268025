






































import { Vue, Component, Prop } from 'vue-property-decorator';
import { UIContextualDrawerItem } from '@/store/models/ui_models';

@Component({})
export default class ContextualDrawerItem extends Vue {

  @Prop({
    required: true,
  })
  private item!: UIContextualDrawerItem;

  private get iconColor(): string {
    if (this.item.isDisabled) {
      return 'grey lighten-2';
    } else if (this.item.color) {
      return this.item.color;
    } else {
      return '';
    }
  }
}
