














import { Vue, Component, Prop } from 'vue-property-decorator';
import SimpleInfo from '@/components/shared/SimpleInfo.vue';

@Component({
  components: {
    SimpleInfo,
  },
})
export default class InformationSummary extends Vue {
  @Prop({
    required: true,
  })
  public dataList!: Array<{ label: string, value: string }>;

  @Prop({
    default: false,
  })
  public isRightAligned!: boolean;
}
