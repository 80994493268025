























import dayjs from 'dayjs';
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AgreementContract} from '@/store/models/ui_models';

const globalUI = namespace('GlobalUIStore');

@Component({})
export default class DialogContentAgreement extends Vue {

  @globalUI.Mutation
  public setDialogVisible!: (isVisible: boolean) => void;

  private onAccept(): void {
    const agreementContract: AgreementContract = {
      timestamp: dayjs().add(3, 'days').toISOString(),
      accept: true,
    };

    localStorage.setItem('agreementContract', JSON.stringify(agreementContract));
    this.setDialogVisible(false);
  }

}
