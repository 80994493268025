export const appUrl = process.env.VUE_APP_API_URL;
export const awsRegion = process.env.VUE_APP_AWS_REGION;
export const awsUserPoolId = process.env.VUE_APP_USER_POOL_ID;
export const awsUserPoolWebClientId = process.env.VUE_APP_USER_POOL_CLIENT_ID;
export const reportsURL = process.env.VUE_APP_REPORTS_URL;

export default {
  appUrl,
  awsRegion,
  awsUserPoolId,
  awsUserPoolWebClientId,
  reportsURL,
};
