import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { mainTheme } from '@/plugins/themes';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: mainTheme,
  },
  icons: {
    iconfont: 'fa',
  },
});
