import { render, staticRenderFns } from "./BudgetPlanLandingPage.vue?vue&type=template&id=2ef5d139&scoped=true&"
import script from "./BudgetPlanLandingPage.vue?vue&type=script&lang=ts&"
export * from "./BudgetPlanLandingPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ef5d139",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VContainer,VDialog,VRow})
