










import { Component, Emit, Vue } from 'vue-property-decorator';

@Component({})
export default class LevioLabToolBarCloseButton extends Vue {

  @Emit('onClick')
  public onClick(): any {
    return;
  }
}
