var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chart-frame',{attrs:{"title":_vm.$t('optimization.graphs.titleTableBudget')}},[_c('v-data-table',{attrs:{"headers":_vm.trimestersDataTableHeaders,"items":_vm.budgetResult.dataBudgetTrimestreMedia,"items-per-page":_vm.budgetResult.dataBudgetTrimestreMedia.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.trimestre1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.trimestre1, 'currency'))+" ")]}},{key:"item.trimestre2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.trimestre2, 'currency'))+" ")]}},{key:"item.trimestre3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.trimestre3, 'currency'))+" ")]}},{key:"item.trimestre4",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.trimestre4, 'currency'))+" ")]}},{key:"item.trimestreTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.trimestreTotal, 'currency'))+" ")]}},{key:"item.quoteTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.quoteTotal, 'currency'))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }