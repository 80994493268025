import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DialogMixin extends Vue {
  @Prop({
    required: true,
  })
  public isOpen!: boolean;

  @Emit('onClose')
  public closeDialog(): void {
    return;
  }
}
