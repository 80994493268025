import {awsRegion, awsUserPoolId, awsUserPoolWebClientId} from '@/config/app.config';

const awsConfig = {
  Auth: {
    region: awsRegion,
    userPoolId: awsUserPoolId,
    userPoolWebClientId: awsUserPoolWebClientId,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
};

export default awsConfig;
