












































import {Component, Vue} from 'vue-property-decorator';
import ProcessingJobHistory from '@/components/processing/ProcessingJobHistory.vue';
import ProcessingOutputHistory from '@/components/processing/ProcessingOutputHistory.vue';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';

@Component({
  components: {
    LandingPageLayout,
    ProcessingOutputHistory,
    ProcessingJobHistory,
  },
})
export default class ProcessingLandingPage extends Vue {

  private tab: string = 'processing.tab.jobs';
  private tabs: string[] = ['processing.tab.jobs', 'processing.tab.outputs'];
  private search: string = '';
}
