





























































































import { Component, Emit, Watch } from 'vue-property-decorator';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import Rules from '@/utils/rules';
import { form, rangeOfYears } from '@/utils/common';
import { AnnualStoreMixin } from '@/mixins/store/AnnualStoreMixin';
import { mixins } from 'vue-class-component';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { AnnualContext } from '@/store/models/annual_models';
import dayjs from 'dayjs';
import LevioLabToolBarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';
import LevioLabToolBarCTA from '@/components/shared/levioToolBar/LevioLabToolBarCTA.vue';

@Component({
  components: {
    LevioLabToolbar,
    closeButton: LevioLabToolBarCloseButton,
    ctaButton: LevioLabToolBarCTA,
  },
})
export default class AttributionAnnualContextForm extends mixins(AnnualStoreMixin) {

  private selectedTab: number = 1;

  // Form Data
  private isValid: boolean = true;
  private availableYears: number[] = rangeOfYears(2015, dayjs().year());
  private selectedYear: number = dayjs().year();

  @Validate({ required })
  private titleEN: string = '';

  private descriptionEN: string = '';

  @Validate({ required })
  private titleFR: string = '';

  private descriptionFR: string = '';

  @Emit('onClose')
  public closeDialog(): void {
    return;
  }

  @Emit('onSave')
  public saveInfos(): void {
    return;
  }

  @Watch('selectedYear')
  private onSelectedYearChange(newValue): void {
    form(this.$refs.form).resetValidation();
    const context: AnnualContext | undefined =
      this.annualContexts.find((obj: AnnualContext) => obj.year === newValue);
    if (context) {
      this.titleFR = context.titleFR;
      this.titleEN = context.titleEN;
      this.descriptionFR = context.descriptionFR;
      this.descriptionEN = context.descriptionEN;
    } else {
      this.titleFR = '';
      this.titleEN = '';
      this.descriptionFR = '';
      this.descriptionEN = '';
    }
  }

  private mounted(): void {
    this.initContexts();

  }

  private async initContexts(): Promise<void> {
    await this.fetchAnnualContext();
    this.onSelectedYearChange(this.selectedYear);

  }

  private onCloseDialog(): void {
    this.closeDialog();
  }

  private async onSaveContext(): Promise<void> {
    this.isValid = form(this.$refs.form).validate();
    if (this.isValid) {
      await this.postAnnualContext({
        id: this.selectedYear.toString(),
        year: this.selectedYear,
        titleFR: this.titleFR,
        titleEN: this.titleEN,
        descriptionFR: this.descriptionFR,
        descriptionEN: this.descriptionEN,
      });
      this.saveInfos();
    }
  }

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }
}
