import {DefinitionItem} from '@/store/models/definition_models';
import {DefinitionType} from '@/store/enums/defininition_enum';

export const lexiconBrands: DefinitionItem[] = [
  {
    label: 'disclaimer.lexicon.brands.content.da.label',
    description: 'disclaimer.lexicon.brands.content.da.description',
    type: DefinitionType.BRANDS,
  },
  {
    label: 'disclaimer.lexicon.brands.content.di.label',
    description: 'disclaimer.lexicon.brands.content.di.description',
    type: DefinitionType.BRANDS,
  },
  {
    label: 'disclaimer.lexicon.brands.content.dan.label',
    description: 'disclaimer.lexicon.brands.content.dan.description',
    type: DefinitionType.BRANDS,
  },
];

export const lexiconMedias: DefinitionItem[] = [
  {
    label: 'disclaimer.lexicon.medias.content.addressedDm.label',
    description: 'disclaimer.lexicon.medias.content.addressedDm.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.email.label',
    description: 'disclaimer.lexicon.medias.content.email.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.newspaper.label',
    description: 'disclaimer.lexicon.medias.content.newspaper.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.outdoor.label',
    description: 'disclaimer.lexicon.medias.content.outdoor.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.organic.label',
    description: 'disclaimer.lexicon.medias.content.organic.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.phone.label',
    description: 'disclaimer.lexicon.medias.content.phone.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.ppcBranded.label',
    description: 'disclaimer.lexicon.medias.content.ppcBranded.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.unbrandedPC.label',
    description: 'disclaimer.lexicon.medias.content.unbrandedPC.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.radio.label',
    description: 'disclaimer.lexicon.medias.content.radio.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.referrals.label',
    description: 'disclaimer.lexicon.medias.content.referrals.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.tv.label',
    description: 'disclaimer.lexicon.medias.content.tv.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.unaddressedDm.label',
    description: 'disclaimer.lexicon.medias.content.unaddressedDm.description',
    type: DefinitionType.MEDIA,
  },
  {
    label: 'disclaimer.lexicon.medias.content.webAd.label',
    description: 'disclaimer.lexicon.medias.content.webAd.description',
    type: DefinitionType.MEDIA,
  },
];

export const lexiconChannels: DefinitionItem[] = [
  {
    label: 'disclaimer.lexicon.quotesChannels.content.ccc.label',
    description: 'disclaimer.lexicon.quotesChannels.content.ccc.description',
    type: DefinitionType.QUOTES_CHANNEL,
  },
  {
    label: 'disclaimer.lexicon.quotesChannels.content.eax.label',
    description: 'disclaimer.lexicon.quotesChannels.content.eax.description',
    type: DefinitionType.QUOTES_CHANNEL,
  },
  {
    label: 'disclaimer.lexicon.quotesChannels.content.kan.label',
    description: 'disclaimer.lexicon.quotesChannels.content.kan.description',
    type: DefinitionType.QUOTES_CHANNEL,
  },
  {
    label: 'disclaimer.lexicon.quotesChannels.content.web.label',
    description: 'disclaimer.lexicon.quotesChannels.content.web.description',
    type: DefinitionType.QUOTES_CHANNEL,
  },
];

export const lexiconOthers: DefinitionItem[] = [
  {
    label: 'disclaimer.lexicon.others.content.fsa.label',
    description: 'disclaimer.lexicon.others.content.fsa.description',
    type: DefinitionType.OTHERS,
  },
  {
    label: 'disclaimer.lexicon.others.content.qos.label',
    description: 'disclaimer.lexicon.others.content.qos.description',
    type: DefinitionType.OTHERS,
  },
  {
    label: 'disclaimer.lexicon.others.content.qosAnswered.label',
    description: 'disclaimer.lexicon.others.content.qosAnswered.description',
    type: DefinitionType.OTHERS,
  },
  {
    label: 'disclaimer.lexicon.others.content.qosDropped.label',
    description: 'disclaimer.lexicon.others.content.qosDropped.description',
    type: DefinitionType.OTHERS,
  },
  {
    label: 'disclaimer.lexicon.others.content.totalQuotes.label',
    description: 'disclaimer.lexicon.others.content.totalQuotes.description',
    type: DefinitionType.OTHERS,
  },
  {
    label: 'disclaimer.lexicon.others.content.simulatedQuotes.label',
    description: 'disclaimer.lexicon.others.content.simulatedQuotes.description',
    type: DefinitionType.OTHERS,
  },
];
