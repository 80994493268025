


































import { Vue, Component, Prop } from 'vue-property-decorator';
import ChartFrame from '@/components/shared/amcharts/base/ChartFrame.vue';
import { BudgetOptimizationResult } from '@/store/models/pipeline_models';
import i18n from '@/plugins/i18n';

@Component({
    components: {
      ChartFrame,
    },
  })
export default class BudgetTable extends Vue {

    @Prop({
      required: true,
    })
    public budgetResult!: BudgetOptimizationResult;

    private trimestersDataTableHeaders: any = [
      {
        text: i18n.t('optimization.graphs.mediasLabel'),
        align: 'start',
        sortable: false,
        value: 'media',
        class: 'theme0--text text--darken-1',
      },
      {
        text: i18n.t('optimization.graphs.quarterLabel') + ' 1',
        value: 'trimestre1',
        align: 'end',
      },
      {
        text: i18n.t('optimization.graphs.quarterLabel') + ' 2',
        value: 'trimestre2',
        align: 'end',
      },
      {
        text: i18n.t('optimization.graphs.quarterLabel') + ' 3',
        value: 'trimestre3',
        align: 'end',
      },
      {
        text: i18n.t('optimization.graphs.quarterLabel') + ' 4',
        value: 'trimestre4',
        align: 'end',
      },
      {
        text: i18n.t('optimization.graphs.totalBudgetLabel'),
        value: 'trimestreTotal',
        align: 'end',
        class: 'theme0--text text--darken-1',
      },
    ];
}
