import BaseService from '@/store/services/BaseService';
import { StepType } from '@/store/enums/pipeline_enums';
import { ModellingTrainDatasetEvent, PipelineOutput } from '@/store/models/pipeline_models';
import { RequestErrorType } from '@/store/enums/api_enum';
import { StartETLRequest } from '@/store/models/upload_models';
import { RequestError } from '@/store/models/common_models';

export default class PipelineService extends BaseService {
  public static PIPELINE_ETL_URL= "/etl";
  public static PIPELINE_MODELLING_URL= "/modelling-dataset";
  public static PIPELINE_OUTPUT_URL= "/pipeline/output";
  public static OPTIMIZATION_LIST_URL= "/optimization/list";

  public static async getPipelineOutputById(id: string): Promise<PipelineOutput | null> {
    return await super.getJSON(`${PipelineService.PIPELINE_OUTPUT_URL}/${id}`);
  }

  public static async getPipelineOutputsByStepType(step: StepType): Promise<PipelineOutput[]> {
    let pipelineOutputs: PipelineOutput[] = [];
    const response: PipelineOutput[] | RequestErrorType = await super.getJSON(
      PipelineService.PIPELINE_OUTPUT_URL,
      {
        stepType: step.toString(),
      },
    );
    if(!(response in RequestErrorType)) {
      pipelineOutputs = response as PipelineOutput[];
    }

    return pipelineOutputs;

  }

  public static async getPipelineOutputSourceJobByStep(id: string, step: StepType): Promise<PipelineOutput | null> {
    const job: PipelineOutput = await PipelineService.getPipelineOutputById(id) as PipelineOutput;
    if((job as PipelineOutput).step === step) {
      return job;
    } else {
      if (job.sourceJobId.length >= 1) {
        return await PipelineService.getPipelineOutputSourceJobByStep(job.sourceJobId[0], step);
      } else {
        return null;
      }
    }
  }

  public static async getOptimizationEvents(): Promise<PipelineOutput[]> {
    let optimizationOutputs: PipelineOutput[] = [];

    const response: PipelineOutput[] | RequestErrorType = await super.getJSON(
      PipelineService.OPTIMIZATION_LIST_URL,
    );

    if(!(response in RequestErrorType)) {
      optimizationOutputs = response as PipelineOutput[];
    }

    return optimizationOutputs;
  }

  public static async startETL(request: StartETLRequest): Promise<PipelineOutput | RequestError> {
    return await super.postJSON(`${PipelineService.PIPELINE_ETL_URL}`, request);
  }

  public static async startAttributions(modellingJobs: ModellingTrainDatasetEvent[]): Promise<PipelineOutput | RequestError> {
    return await super.postJSON(`${PipelineService.PIPELINE_MODELLING_URL}`, modellingJobs);
  }
}
