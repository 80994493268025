


























































































import { Component } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import { namespace } from 'vuex-class';
import { Validate } from 'vuelidate-property-decorators';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { mixins } from 'vue-class-component';

import { UIContextualDrawerItem, UISnackBar } from '@/store/models/ui_models';

import Rules from '@/utils/rules';
import { form } from '@/utils/common';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import ContextualDrawerItem from '@/components/shared/contextualDrawer/ContextualDrawerItem.vue';
import ContextualDrawer from '@/components/shared/contextualDrawer/ContextualDrawer.vue';
import ContextualDrawerContainer from '@/components/shared/contextualDrawer/ContextualDrawerContainer.vue';
import { UserData } from '@/store/models/auth_models';
import AlertWidget from '@/components/shared/AlertWidget.vue';
import {conceptsThemes} from "@/plugins/themes";
import Schedule from "@/components/shared/Schedule.vue";

const auth = namespace('AuthStore');
const upload = namespace('UploadStore');
const globalUI = namespace('GlobalUIStore');

@Component({
  components: {
    LandingPageLayout,
    ContextualDrawerContainer,
    ContextualDrawer,
    ContextualDrawerItem,
    AlertWidget,
    Schedule,
  },
})
export default class SupportLandingPage extends mixins(validationMixin) {

  @auth.Getter
  private currentUserData!: UserData;

  @auth.Action
  private fetchUser!: () => Promise<void>;

  @upload.Action
  private getUrlForFile!: (key: string) => Promise<string>;

  @globalUI.Action
  private showSnackBar!: (payload: UISnackBar) => UISnackBar;

  @Validate({ required })
  private emailTitle: string = '';

  @Validate({ required })
  private emailBody: string = '';

  private contactEmail: string = 'support.mroi@levio.ca';
  private isValid: boolean = false;

  private isScheduleOpen:boolean = false;

  protected created(): void {
    this.fetchUser();
  }

  protected get userGuideDownload(): UIContextualDrawerItem {
    return {
      onClick: () => this.download(),
      icon: conceptsThemes.userGuide.icon,
      tooltip: this.$t('support.userGuide.tooltip') as string,
    };
  }

  protected get schedulePopup(): UIContextualDrawerItem {
    return {
      onClick: () => this.openSchedulePopup(),
      icon: conceptsThemes.schedule.icon,
      tooltip: this.$t('schedule.title') as string,
    };
  }

  private download() {
    const currentLocale = i18n.locale;
    const filePath = 'dgag-mroi-portal/user-guides/';
    const fileNaming = 'Levio-Menya-DGAG-MROI-Guide';
    const fileName = fileNaming + currentLocale.toUpperCase() + '.pdf';

    this.getUrlForFile(filePath + fileName).then((url) => window.open(url, '_blank'));
  }

  private openSchedulePopup() {
    this.isScheduleOpen = true;
  }

  private validate(): void {
    this.isValid = form(this.$refs['form']).validate();

    if (this.$v.$anyDirty && this.isValid) {
      this.mailTo();
    }
  }

  private mailTo() {
    window.open('mailto:' + this.contactEmail + '?' +
      'subject=' + this.emailTitle +
      '&body=' + this.emailBody,
    );

    this.showSnackBar({
      color: 'primary',
      text: this.$t('support.openMailingApp') as string,
      timeout: 600000,
    });
  }

  private copyText(text: string) {
    navigator.clipboard.writeText(text);
    this.showSnackBar({
      color: 'primary',
      text: this.$t('support.copyToClipboard') as string,
    });
  }

  private get isAllowed(): boolean {
    return this.currentUserData && this.currentUserData.roles.length > 0;
  }

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }
}
