import BaseService from '@/store/services/BaseService';
import { BudgetPlanExecutionRequest, PipelineOutput } from '@/store/models/pipeline_models';
import { RequestError } from '@/store/models/common_models';

export default class BudgetPlanificationService extends BaseService {
  public static PLAN_OPTIMIZATION = '/optimization/';

  public static async startPlanificationOptimization(request: BudgetPlanExecutionRequest): Promise<PipelineOutput | RequestError> {
    return await super.postJSON(`${BudgetPlanificationService.PLAN_OPTIMIZATION}`, request);
  }

}
