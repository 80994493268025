
























































import { Component, Emit, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import Rules from '@/utils/rules';
import { AnnualStoreMixin } from '@/mixins/store/AnnualStoreMixin';
import { ModellingTrainDatasetEvent } from '@/store/models/pipeline_models';
import { Validations } from 'vuelidate-property-decorators';
import { allAvailableYears } from '@/utils/common';

@Component({})
export default class ModellingFormItem extends AnnualStoreMixin {

  // Properties
  @Prop({
    required: true,
  })
  public value!: ModellingTrainDatasetEvent;

  // Properties
  @Prop({
    required: true,
  })
  public index!: number;

  // Local Variables

  @Validations()
  private validations = {
    currentItem: {
      year: { required },
      title: { required },
    },
  };

  public currentItem: ModellingTrainDatasetEvent = {
    year: null,
    title: null,
    description: '',
  };

  // Public Functions

  @Emit('onDeleteItem')
  public onDelete(): number {
    return this.index;
  }

  // Private Functions

  private created(): void {
    this.currentItem = this.value;
  }

  // Getters

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }

  private get yearsList(): number[] {
    return allAvailableYears().reverse();
  }



}
