





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import ContextualDrawerContainer from '@/components/shared/contextualDrawer/ContextualDrawerContainer.vue';
import ContextualDrawer from '@/components/shared/contextualDrawer/ContextualDrawer.vue';
import ContextualDrawerItem from '@/components/shared/contextualDrawer/ContextualDrawerItem.vue';
import { PBIElement } from '@/store/models/powerBi_models';
import { pbiVisualsList } from '@/utils/data/pbi_visuals';
import { PBIVisualViewType } from '@/store/enums/pbi_enums';
import { namespace } from 'vuex-class';
import { UIContextualDrawerItem } from '@/store/models/ui_models';
import PowerBIElement from '@/components/shared/PowerBIElement.vue';
import OptimizationCreationDialog from '@/components/budgetPlan/BudgetPlanCreationDialog.vue';
import { BudgetPlanType, StepType } from '@/store/enums/pipeline_enums';
import OfficializationDialog from '@/components/officialization/OfficializationDialog.vue';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';
import { conceptsThemes } from '@/plugins/themes';

const globalUI = namespace('GlobalUIStore');

@Component({
  components: {
    LevioLabTitle,
    LandingPageLayout,
    ContextualDrawerContainer,
    ContextualDrawer,
    ContextualDrawerItem,
    PowerBIElement,
    OptimizationCreationDialog,
    OfficializationDialog,
  },
})
export default class BudgetPlanLandingPage extends Vue {
  // Properties
  @Prop({
    required: false,
    default: '',
  })
  public value!: any;

  @globalUI.Getter
  private isDemoMode!: boolean;

  // Local Variables

  private isOptimizationCreationOpen: boolean = false;
  private isOptimizationOfficializationOpen: boolean = false;
  private isOptimizationPredictionOpen: boolean = false;

  // Public Functions
  // Private Functions

  private created(): void {
    // console.log(title, ' has been created.');
  }

  // Getters

  private get drawerOptimizationCreation(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOptimizationCreationOpen = true;
      },
      icon: conceptsThemes.budgetPlan.icon,
      class: 'mr-0',
      tooltip: this.$t('optimization.create') as string,
      badgeIcon: 'fa-plus',
      badgeColor: 'light-green',
    };
  }

  private get drawerPredictionOfficialize(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOptimizationPredictionOpen = true;
      },
      icon: conceptsThemes.officialization.icon,
      color: conceptsThemes.officialization.color,
      badgeIcon: conceptsThemes.prediction.icon,
      badgeColor: conceptsThemes.prediction.color,
      tooltip: this.$t('budgetPlan.prediction.ctaOfficialize') as string,
    };
  }

  private get drawerOptimizationOfficialize(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOptimizationOfficializationOpen = true;
      },
      icon: conceptsThemes.officialization.icon,
      color: conceptsThemes.officialization.color,
      badgeIcon: conceptsThemes.optimization.icon,
      badgeColor: conceptsThemes.optimization.color,
      tooltip: this.$t('budgetPlan.optimization.ctaOfficialize') as string,
    };
  }

  private get predictionReport(): PBIElement {
    return pbiVisualsList.budgetPlanReport;
  }

  private get viewType(): PBIVisualViewType {
    return PBIVisualViewType.VIEW_FILTER;
  }

  private get optimizationType(): StepType {
    return StepType.OPTIMIZATION;
  }

  private get predictionBudgetPlanType(): BudgetPlanType {
    return BudgetPlanType.PREDICTION;
  }

  private get optimizationBudgetPlanType(): BudgetPlanType {
    return BudgetPlanType.OPTIMIZATION;
  }
// Watches
}
