




















































import { Component, Prop, Mixins } from 'vue-property-decorator';
import DialogMixin from '@/mixins/DialogMixin';

@Component({})
export default class BottomDialog extends Mixins(DialogMixin) {

  @Prop({
    default: 'fa-times',
  })
  private icon!: string;

  @Prop({
    default: '',
  })
  private title!: string;

  private onBackClick(): void {
    this.closeDialog();
  }
}
