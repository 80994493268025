import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  FileInfo,
  FileWithName,
  UploadHistoryInfo,
  FileRequest,
  UploadFilesRequest,
} from '@/store/models/upload_models';
import axios from 'axios';
import { requiredFilesList } from '@/utils/data/upload_files_list';
import { RequestErrorType } from '@/store/enums/api_enum';
import { parseRequestErrorType } from '@/utils/common';

@Module({ namespaced: true, name: 'uploadModule' })
export default class UploadStore extends VuexModule {

  public static parsePrefixFromKey(key: string): string {
    let prefix = key;
    const fileName = UploadStore.parseFileNameFromKey(key);

    if (fileName) {
      prefix = prefix.replaceAll(fileName, '');
    }

    return prefix;
  }

  public static parseFileNameFromKey(key: string): string | undefined {
    return key.split('/').pop();
  }

  private $filesToBeUploaded: FileWithName[] = [];
  private $uploadProgress: number = 0;
  private $isUploading: boolean = false;
  private $uploadHistory: UploadHistoryInfo[] = [];
  private $fileData: FileWithName | null = null;

  public get requiredFileList(): FileInfo[] {
    return requiredFilesList;
  }

  // File Data
  public get fileData(): FileWithName | null {
    return this.$fileData;
  }

  public get filesToBeUploaded(): FileWithName[] {
    return this.$filesToBeUploaded;
  }

  public get uploadProgress(): number {
    return this.$uploadProgress;
  }

  public get isUploading(): boolean {
    return this.$isUploading;
  }

  public get uploadHistory(): UploadHistoryInfo[] {
    return this.$uploadHistory;
  }

  @Mutation
  public setFileData(data: FileWithName) {
    this.$fileData = data;
  }

  @Mutation
  public setFilesToBeUploaded(files: FileWithName[]) {
    this.$filesToBeUploaded = files;
  }

  @Mutation
  public setUploadProgress(progress: number) {
    this.$uploadProgress = progress;
  }

  @Mutation
  public setIsUploading(isUploading: boolean) {
    this.$isUploading = isUploading;
  }

  @Mutation
  public setUploadHistory(uploadHistory: UploadHistoryInfo[]) {
    this.$uploadHistory = uploadHistory;
  }

  @Mutation
  public clearUploadData() {
    this.$filesToBeUploaded = [];
  }

  @Action({ commit: 'setFilesToBeUploaded' })
  public addFileToUpload(file: FileWithName): FileWithName[] {
    const { filesToBeUploaded } = this.context.getters;
    filesToBeUploaded.push(file);
    return filesToBeUploaded;
  }

  @Action
  public async uploadFiles(uploadFileRequest: UploadFilesRequest): Promise<string | RequestErrorType | null> {
    const date = new Date();
    let jobId: string | null = null;

    try {
      this.context.commit('setUploadProgress', 0);
      this.context.commit('setIsUploading', true);

      const fileInfoMap = {};
      const formData = new FormData();
      const user = this.context.rootGetters['AuthStore/user'];

      const { filesToBeUploaded } = this.context.getters;
      filesToBeUploaded.forEach((fileWithName: FileWithName) => {
        fileInfoMap[fileWithName.name] = fileWithName.file.name;
        formData.append('files', fileWithName.file);
      });

      await axios.post(`/upload?`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          jobName: uploadFileRequest.jobName,
          jobDescription: uploadFileRequest.jobDescription,
          email: user.attributes.email,
          timestamp: date.getTime(),
          validated: uploadFileRequest.validated,
          filesName: fileInfoMap,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.context.commit('setUploadProgress', percentCompleted);
        },
      }).then((response) => {
        jobId = response.data.jobId;
      });

      this.context.commit('setIsUploading', false);
      this.context.commit('setFilesToBeUploaded', []);
      return jobId;

    } catch (e) {
      this.context.commit('setIsUploading', false);

      const { filesToBeUploaded } = this.context.getters;
      this.context.commit('setFilesToBeUploaded', filesToBeUploaded);
      return parseRequestErrorType(e.response.data.error);
    }
  }

  @Action
  public async getUrlForFile(key: string): Promise<string> {
    const { data } = await axios.get(`/upload/file/url?path=${key}`);
    return data as string;
  }

  @Action({ commit: 'setUploadHistory' })
  public async syncHistory(): Promise<UploadHistoryInfo[]> {
    const { data } = await axios.get('/upload/history');
    return data as UploadHistoryInfo[];
  }

  @Action
  public async deleteUpload(folderName: string): Promise<void> {
    await axios.delete(`/upload?folder=${folderName}`);
  }

  @Action({ commit: 'setFileData' })
  public async fetchFileData(request: FileRequest): Promise<FileWithName> {
    const { data } = await axios.get(`/upload/validation/${request.jobId}/file`, {
      params: {
        fileName: request.fileName,
      },
    });

    const file: File = data as File;
    return {
      name: request.fileName,
      file,
    };
  }
}
