import {PBIElement} from '@/store/models/powerBi_models';
import {PBIElementType} from '@/store/enums/pbi_enums';

const pbiVisualsListData: {[name: string]: PBIElement} = {
  attributionsFullReport: {
   id: process.env.VUE_APP_PBI_ATTRIBUTION_REPORT as string,
   type: PBIElementType.REPORT,
  },
  businessContextReport: {
    id: process.env.VUE_APP_PBI_BUSINESS_CONTEXT_REPORT as string,
    type: PBIElementType.REPORT,
  },
  daDashboard: {
   id: process.env.VUE_APP_PBI_DA_DASHBOARD as string,
   type: PBIElementType.DASHBOARD,
  },
  diDashboard: {
    id: process.env.VUE_APP_PBI_DI_DASHBOARD as string,
   type: PBIElementType.DASHBOARD,
  },
  danDashboard: {
    id: process.env.VUE_APP_PBI_DAN_DASHBOARD as string,
   type: PBIElementType.DASHBOARD,
  },
  budgetPlanReport: {
    id: process.env.VUE_APP_PBI_BUDGET_PLAN_REPORT as string,
    type: PBIElementType.REPORT,
  },
  comparativeReport: {
    id: process.env.VUE_APP_PBI_COMPARATIVE_VIEW_REPORT as string,
    type: PBIElementType.REPORT,
  },
  modelGovernanceReport: {
    id: process.env.VUE_APP_PBI_MODELGOVERNANCEREPORT as string,
    type: PBIElementType.REPORT,
  },
};

export const pbiVisualsList: {[name: string]: PBIElement} = pbiVisualsListData;
