import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import axios from 'axios';

@Module({ namespaced: true, name: 'dashboardModule' })
export default class DashboardStore extends VuexModule {

  private $dashboardUrls: string[] = [];

  public get dashboardUrls(): string[] {
    return this.$dashboardUrls;
  }

  @Mutation
  public setDashboardUrls(dashboardUrls: string[]) {
    this.$dashboardUrls = dashboardUrls;
  }

  @Action({commit: 'setDashboardUrls'})
  public async syncDashboardUrls(): Promise<string[]> {
    const {data} = await axios.get(`/quicksight/attribution`, {});

    if (data) {
      return data as string[];
    } else {
      return [];
    }
  }
}
