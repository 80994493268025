
























import {Component, Vue} from 'vue-property-decorator';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import {BudgetOptimizationInfo, PipelineOutput} from '@/store/models/pipeline_models';
import {namespace} from 'vuex-class';
import OptimizationResult from '@/components/budgetPlan/OptimizationResult.vue';
import PowerBIElement from '@/components/shared/PowerBIElement.vue';
import {pbiVisualsList} from '@/utils/data/pbi_visuals';
import {PBIElement} from '@/store/models/powerBi_models';
import AlertWidget from '@/components/shared/AlertWidget.vue';

const globalUI = namespace('GlobalUIStore');
const pipeline = namespace('PipelineStore');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pbiReports = namespace('PowerBIStore');

@Component({
  components: {
    LandingPageLayout,
    OptimizationResult,
    PowerBIElement,
    AlertWidget,
  },
})
export default class DashboardLandingPage extends Vue {

  @globalUI.Getter
  private isDemoMode!: boolean;

  @pipeline.Getter
  private currentOptimizationInfo!: BudgetOptimizationInfo | null;

  @pipeline.Getter
  private budgetOptimizationOutputs!: PipelineOutput[];

  @pipeline.Action
  private fetchBudgetOptimizationOutputs!: () => Promise<PipelineOutput[]>;

  @pipeline.Action
  private fetchBudgetOptimizationInfo!: (jobId: string) => Promise<BudgetOptimizationInfo>;

  private selectedTab: number = 0;

  private get visuals(): { [name: string]: PBIElement } {
    return pbiVisualsList;
  }

  private get dashboardDA(): PBIElement {
    return this.visuals.daDashboard;
  }

  private get dashboardDI(): PBIElement {
    return this.visuals.diDashboard;
  }

  private get dashboardDAN(): PBIElement {
    return this.visuals.danDashboard;
  }
}
