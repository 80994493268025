














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnnualOfficialData } from '@/store/models/annual_models';
import { DataTableHeader } from 'vuetify';
import { translateEnum } from '@/utils/localisation';
import { Brand, BudgetPlanType, StepType } from '@/store/enums/pipeline_enums';

@Component({})
export default class OfficializedDataCard extends Vue {
  // Properties

  @Prop({
    default: StepType.MODELLING_ATTRIBUTIONS,
  })
  private stepType!: StepType;

  @Prop()
  private budgetPlanType!: BudgetPlanType;

  @Prop({
    required: true,
  })
  public annualOfficialDataList!: AnnualOfficialData[];

  @Prop({
    default: false,
  })
  public isLoading!: boolean;

// Local Variables

// Public Functions

// Private Functions

  // Getters

  private get headers(): DataTableHeader[] {
    const tableHeaders: DataTableHeader[] =
      [
        {
          text: this.$t('common.brand') as string,
          value: 'brand',
          groupable: true,
        },
        {
          text: this.$t('common.year') as string,
          value: 'year',
          groupable: true,
        },
      ];

    this.neededColumns.forEach((columnItem: { column: string, label: string }) => {
      tableHeaders.push(
        {
          text: columnItem.label,
          value: columnItem.column,
          groupable: false,
        });
    });

    return tableHeaders;
  }

  private get neededColumns(): { column: string, label: string }[] {
    if (this.stepType === StepType.MODELLING_ATTRIBUTIONS) {
      return [
        {
          column: 'datasetJobId',
          label: translateEnum(StepType.MODELLING_DATASET, 'stepType') as string,
        },
        {
          column: 'attributionsJobId',
          label: translateEnum(StepType.MODELLING_ATTRIBUTIONS, 'stepType') as string,
        },
      ];
    } else if (this.stepType === StepType.ETL) {
      return [{
        column: 'etlJobId',
        label: translateEnum(this.stepType, 'stepType') as string,
      }];
    } else if (this.stepType === StepType.OPTIMIZATION && this.budgetPlanType) {
      if (this.budgetPlanType === BudgetPlanType.PREDICTION) {
        return [{
          column: 'predictionJobId',
          label: translateEnum(this.budgetPlanType, 'budgetPlanType') as string,
        }];
      } else if (this.budgetPlanType === BudgetPlanType.OPTIMIZATION) {
        return [{
          column: 'optimizationJobId',
          label: translateEnum(this.budgetPlanType, 'budgetPlanType') as string,
        }];
      }
    }
    return [];
  }

  private get filteredData(): AnnualOfficialData[] {
    return this.annualOfficialDataList.filter((annualData: AnnualOfficialData) => {
      return this.neededColumns.find((columnItem: { column: string, label: string }) => {
        return annualData[columnItem.column];
      });
    }).filter((item: AnnualOfficialData) => {
      return this.stepType !== StepType.ETL || item.brand !== Brand.SYNERGY;
    });
  }

// Watches
}
