













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ChartFrame extends Vue {
    @Prop({
      default: '',
    })
    public title!: string;
}
