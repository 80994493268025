






































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {UploadHistoryInfo} from '@/store/models/upload_models';
import {UISnackBar} from '@/store/models/ui_models';
import {DataTableHeader} from 'vuetify';
import UploadStore from '@/store/modules/UploadStore';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';

const globalUI = namespace('GlobalUIStore');
const upload = namespace('UploadStore');

@Component({
  components: {
    LevioLabTitle,
  },
})
export default class UploadHistory extends Vue {

  @upload.Getter
  private uploadHistory!: UploadHistoryInfo[];

  @upload.Action
  private getUrlForFile!: (key: string) => Promise<string>;

  @upload.Action
  private syncHistory!: () => Promise<UploadHistoryInfo[]>;

  @upload.Action
  private deleteUpload!: (folderName: string) => Promise<void>;

  @globalUI.Action
  private showSnackBar!: (payload: UISnackBar) => UISnackBar;

  private deleteHistoryDialog: boolean = false;
  private deleteHistoryDialogHistoryInfo: UploadHistoryInfo | null = null;
  private isLoading: boolean = true;
  private historyInfoDialog: boolean = false;
  private selectedHistoryInfo: UploadHistoryInfo | null = null;
  private syncInterval!: number;

  public mounted() {
    this.syncHistory().then(() => this.isLoading = false);
    this.syncInterval = window.setInterval(() => {
      this.syncHistory();
    }, 10000);
  }

  public destroyed() {
    if (this.syncInterval) {
      clearTimeout(this.syncInterval);
    }
  }

  private get subtitleDate(): string {
    return this.selectedHistoryInfo ? (new Date(this.selectedHistoryInfo.date)).toLocaleString() : '';
  }

  private get uploadHistoryHeaders(): DataTableHeader[] {
    return [
      {
        text: '',
        sortable: false,
        value: 'infos',
        width: '10',
      },
      {
        text: this.$t('upload.history.verified').toString(),
        sortable: true,
        value: 'verified',
        width: '100',
      },
      {
        text: this.$t('upload.history.uploader').toString(),
        value: 'uploaderName',
        sortable: true,
      },
      {
        text: this.$t('upload.history.folderName').toString(),
        sortable: true,
        value: 'folderKey',
      },
      {
        text: this.$t('upload.history.date').toString(),
        sortable: true,
        value: 'date',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
        width: '80',
      },
    ];
  }

  private showDeleteHistoryDialog(uploadHistoryInfo: UploadHistoryInfo) {
    this.deleteHistoryDialogHistoryInfo = uploadHistoryInfo;
    this.deleteHistoryDialog = true;
  }

  private async localDeleteUpload() {
    if (this.deleteHistoryDialogHistoryInfo) {
      this.deleteHistoryDialog = false;
      await this.deleteUpload(this.deleteHistoryDialogHistoryInfo.folderKey);
      await this.syncHistory();
      this.deleteHistoryDialogHistoryInfo = null;
    }
  }

  private localParseFileNameFromKey(key: string): string | undefined {
    return UploadStore.parseFileNameFromKey(key);
  }

  private async localDownloadFile(fileName: string): Promise<void> {
    const url = await this.getUrlForFile(fileName);

    if (url) {
      const fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('download', UploadStore.parseFileNameFromKey(fileName)!);
      document.body.appendChild(fileLink);

      fileLink.click();
    }
  }

  private getFolderNameByItem(item: UploadHistoryInfo): string {
    if(item.folderKey){
      return item.folderKey.split('/').pop() || ''
    }
    return '';
  }
}
