import Vue from 'vue';
import VueI18n from 'vue-i18n';

import {getBrowserLanguageShort} from '@/utils/common';

import FR_LOCALES from '@/locales/fr';
import EN_LOCALES from '@/locales/en';

Vue.use(VueI18n);

const messages = {
  en: EN_LOCALES,
  fr: FR_LOCALES,
};

const numberFormats = {
  en: {
    currency: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol',
    },
    integer: {
      style: 'decimal',
    },
  },
  fr: {
    currency: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol',
    },
    integer: {
      style: 'decimal',
    },
  },
};

const language: string = getBrowserLanguageShort();

const i18n = new VueI18n({
  locale: language,
  fallbackLocale: 'en',
  messages,
  numberFormats,
});

export default i18n;
