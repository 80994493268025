import axios from 'axios';
import { Auth } from 'aws-amplify';
import router from '@/router';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use((config) => {
  return Auth.currentSession().then((data) => {
    config.headers.Authorization = 'Bearer ' + data.getIdToken().getJwtToken();
    return Promise.resolve(config);
  }).catch((err) => {
    router.push('/signin');
    return Promise.reject(err);
  });
});
