



































































































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import LevioLabToolbarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';
import { namespace } from 'vuex-class';
import { PipelineOutput, UploadValidationData } from '@/store/models/pipeline_models';
import LevioLabToolBarCTA from '@/components/shared/levioToolBar/LevioLabToolBarCTA.vue';
import { StartETLRequest } from '@/store/models/upload_models';
import { allAvailableYears, form, isError } from '@/utils/common';
import Rules from '@/utils/rules';
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { translateEnum } from '@/utils/localisation';
import { RequestErrorType } from '@/store/enums/api_enum';
import { RequestError } from '@/store/models/common_models';

const pipeline = namespace('PipelineStore');

@Component({
  components: {
    LevioLabToolbar,
    closeButton: LevioLabToolbarCloseButton,
    ctaButton: LevioLabToolBarCTA,
  },
})
export default class ETLJobDialog extends mixins(validationMixin) {
  // Properties
  @Prop({
    required: true,
  })
  public isOpen!: boolean;

  // Local Variables

  private isValid: boolean = false;
  private isLoading: boolean = false;
  private requestError: string = '';

  @Validate({ required })
  private selectedUpload: PipelineOutput | null = null;

  @Validate({ required })
  private selectedYears: number[] | null = null;

  @Validate({ required })
  private jobTitle: string | null = null;

  private jobDescription: string = '';

  @pipeline.Getter
  private uploadValidationData!: UploadValidationData | null;

  @pipeline.Action
  private startETL!: (request: StartETLRequest) => Promise<void | RequestError>

  @pipeline.Getter
  private uploadOutputs!: PipelineOutput[];

  @pipeline.Action
  private fetchUploadOutputs!: () => Promise<PipelineOutput[]>;

  // Public Functions
  @Emit('closeDialog')
  public closeDialog(): void {
    this.resetForm();
    return;
  }

  // Private Functions
  private mounted(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.isLoading = true;
    await this.fetchUploadOutputs();

    if (!this.selectedUpload) {
      this.resetForm();
    }
    this.isLoading = false;

    if (this.uploadValidationData) {
      const selectedOutput = this.uploadOutputs.find((output: PipelineOutput) => {
        return output.jobId = this.uploadValidationData!.jobId;
      });
      if (selectedOutput) {
        this.selectedUpload = selectedOutput;
      }
    }
  }

  private resetForm(): void {
    this.isValid = false;
    this.selectedUpload = null;
    this.selectedYears = null;
    this.jobTitle = null;
    this.jobDescription = '';
    this.requestError = '';
    this.$v.$reset();
    if (form(this.$refs.etlJobForm)) {
      form(this.$refs.etlJobForm).reset();
      form(this.$refs.etlJobForm).resetValidation();
    }
  }

  private async startJob() {
    form(this.$refs.etlJobForm).validate();
    if (this.isValid && this.etlRequest) {
      // eslint-disable-next-line
      const error: RequestError | void = await this.startETL(this.etlRequest);
      if (error && isError(error)) {
        if ((error as RequestError).type === RequestErrorType.TITLE_DUPLICATE) {
          this.requestError = translateEnum((error as RequestError).type as RequestErrorType, 'requestErrorType') as string;
        }
      } else {
        this.closeDialog();
        await this.$router.push('/processing');
      }
    }
  }

  private removeFromSelectedYears(year: number): void {
    this.selectedYears!.splice(this.selectedYears!.indexOf(year), 1)
    this.selectedYears = [...this.selectedYears!]
  }

  private getRequiredErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getRequiredMessages(field);
  }

  private getTitleErrorMessages(field: Validation & ValidationProperties<any>): string[] {
    if (this.requestError !== '') {
      return [this.requestError];
    } else {
      return this.getRequiredErrors(field);
    }
  }

  // Getters

  private get etlRequest(): StartETLRequest | null {
    // years: this.selectedYears!,
    if (this.selectedUpload && this.selectedYears && this.jobTitle) {
      return {
        title: this.jobTitle!,
        description: this.jobDescription,
        outputId: this.selectedUpload!.id,
      };
    } else {
      return null;
    }
  }

  private get availableYears(): number[] {
    return allAvailableYears();
  }

  private get validUploadOutputs(): PipelineOutput[] {
    return this.uploadOutputs.filter((output: PipelineOutput) => {
      let hasInvalidFile: boolean = false;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(output.additionalProperties['validation_results'])) {
        hasInvalidFile = !(value as any)['success'] as boolean;
        if(hasInvalidFile) {
          break;
        }
      }
      return !hasInvalidFile;
    });
  }

  // Watches
  @Watch('isOpen')
  private onIsOpenChange(): void {
    this.fetchData();
  }
}
