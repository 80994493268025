import { render, staticRenderFns } from "./BudgetMediaBreakdown.vue?vue&type=template&id=049b30f4&scoped=true&"
import script from "./BudgetMediaBreakdown.vue?vue&type=script&lang=ts&"
export * from "./BudgetMediaBreakdown.vue?vue&type=script&lang=ts&"
import style0 from "./BudgetMediaBreakdown.vue?vue&type=style&index=0&id=049b30f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049b30f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VRow,VSkeletonLoader})
