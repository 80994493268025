










import { Component, Vue, Watch } from 'vue-property-decorator';
import DialogContentNone from '@/components/globalUI/DialogContentNone.vue';
import DialogContentInactivity from '@/components/globalUI/DialogContentInactivity.vue';
import DialogContentLoggedOut from '@/components/globalUI/DialogContentLoggedOut.vue';
import { DialogContentType, UIDialog } from '@/store/models/ui_models';
import { namespace } from 'vuex-class';
import DialogContentAgreement from '@/components/globalUI/DialogContentAgreement.vue';

const globalUI = namespace('GlobalUIStore');

@Component({
  components: {
    DialogContentNone,
    DialogContentInactivity,
    DialogContentLoggedOut,
    DialogContentAgreement,
  },
})

export default class GlobalDialog extends Vue {
  @globalUI.Getter
  private isDialogVisible!: boolean;

  @globalUI.Mutation
  private setDialogVisible!: (isVisible: boolean) => void;

  @globalUI.Getter
  private dialog!: UIDialog;

  @globalUI.Mutation
  private setDialogByContent!: (newContent: DialogContentType) => void;

  public get localIsDialogVisible(): boolean {
    return this.isDialogVisible;
  }

  public set localIsDialogVisible(isVisible: boolean) {
    this.setDialogVisible(isVisible);
  }

  @Watch('localIsDialogVisible')
  public localIsDialogVisibleHandler(isVisible: boolean) {
    this.localIsDialogVisible = isVisible;
    if (!isVisible) {
      this.setDialogByContent(DialogContentType.NONE);
    }
  }

}
