

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class IconTooltip extends Vue {

  @Prop({
    default: 'medium',
  })
  public iconSize!: string;

  @Prop({
    required: true,
  })
  public label!: string;

  @Prop({
    default: false,
  })
  public isFloating!: boolean;

  private show: boolean = false;

}
