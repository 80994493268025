






























import {Component, Prop, Vue} from 'vue-property-decorator';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';

@Component({
  components: {
    LevioLabTitle,
  },
})
export default class LandingPageLayout extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private caption!: string;

  @Prop()
  private overline!: string;

  @Prop()
  private description!: string;
}
