import { Component, Prop, Vue } from 'vue-property-decorator';
import { mainTheme } from '@/plugins/themes';
import { AmChartSerie } from '@/store/models/amcharts_models';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component
class ChartToolsMixin extends Vue {

  // ------ DATA REPRESENTATION ------//

  @Prop({
    required: true,
  })
  public category!: any;

  @Prop({
    required: true,
  })
  public series!: AmChartSerie[];

  @Prop({
    required: true,
  })
  public data!: any;

  @Prop({
    default: '',
  })
  public title!: string;

  @Prop({
    default: 'amBarChart',
  })
  public refName!: string;

  // ------ COLORS ------//
  @Prop({
    default: () => [],
  })
  public colorScheme!: string[];

  // ------ OTHERS ------//

  public chart!: any;

  public beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public setDefaultAmChartsColorTheme(): void {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(this.am4themes_myTheme);
  }

  public setComparativeColorTheme(): void {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(this.am4themes_idleTheme);
  }

  public am4themes_myTheme(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color(mainTheme.light.theme0),
        am4core.color(mainTheme.light.theme1),
        am4core.color(mainTheme.light.theme2),
        am4core.color(mainTheme.light.theme3),
        am4core.color(mainTheme.light.theme4),
        am4core.color(mainTheme.light.theme5),
        am4core.color(mainTheme.light.theme6),
      ];
    }
  }

  public am4themes_idleTheme(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color(mainTheme.light.themeIdle),
        am4core.color(mainTheme.light.theme0),
      ];
    }
  }

}

export default ChartToolsMixin;
