import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AnnualContext, AnnualOfficialData } from '@/store/models/annual_models';
import AnnualService from '@/store/services/AnnualService';
import PipelineService from '@/store/services/PipelineService';
import { StepType } from '@/store/enums/pipeline_enums';
import { PipelineOutput } from '@/store/models/pipeline_models';

@Module({ namespaced: true, name: 'annualModule' })
export default class AnnualStore extends VuexModule {

  private $annualContexts: AnnualContext[] = [];
  private $annualOfficialDataItems: AnnualOfficialData[] = [];

  public get annualContexts(): AnnualContext[] {
    return this.$annualContexts;
  }

  public get annualOfficialDataItems(): AnnualOfficialData[] {
    return this.$annualOfficialDataItems;
  }

  public get officialYears(): number[] {
    return this.$annualOfficialDataItems.map((dataItem: AnnualOfficialData) => {
      return dataItem.year;
    }).sort((a, b) => a > b ? 1 : -1);
  }

  // ******** //
  // Mutation //
  // ******** //

  @Mutation
  public setAnnualContexts(contexts: AnnualContext[]) {
    this.$annualContexts = contexts;
  }

  @Mutation
  public setAnnualOfficialDataItems(data: AnnualOfficialData[]) {
    this.$annualOfficialDataItems = data;
  }

  // ******* //
  // Actions //
  // ******* //

  // Context //

  @Action({ commit: 'setAnnualContexts' })
  public async fetchAnnualContext(): Promise<AnnualContext[]> {
    return AnnualService.getAnnualContext();
  }

  @Action
  public async fetchAnnualContextById(id: string): Promise<AnnualContext | null> {
    return AnnualService.getAnnualContextById(id);
  }

  @Action
  public async postAnnualContext(context: AnnualContext): Promise<void> {
    return AnnualService.postAnnualContext(context);
  }

  // Official //

  @Action({ commit: 'setAnnualOfficialDataItems' })
  public async fetchAnnualOfficialData(): Promise<AnnualOfficialData[]> {
    const data = await AnnualService.getOfficialAnnualData();
    const officialData: AnnualOfficialData[] = [];
    data.forEach((item: any) => {
      officialData.push({
        id: item.id,
        brand: item.brand,
        year: parseInt(item.year),
        attributionsJobId: item.attributionsJobId,
        etlJobId: item.etlJobId,
        datasetJobId: item.datasetJobId,
        predictionJobId: item.predictionJobId,
        optimizationJobId: item.optimizationJobId,
      });
    })
    return officialData;
  }

  @Action
  public async fetchOfficialAnnualDataById(id: string): Promise<AnnualOfficialData | null> {
    const data = await AnnualService.getOfficialAnnualDataById(id);
    if(data && (data as AnnualOfficialData).datasetJobId) {
      return data as AnnualOfficialData;
    } else {
      return null;
    }
  }

  @Action
  public async postOfficialAnnual(request: AnnualOfficialData): Promise<void> {
    let currentAnnualData: AnnualOfficialData | null = await AnnualService.getOfficialAnnualDataById(request.id);

    if(currentAnnualData) {
      if(request.attributionsJobId) {
        const source_modelling_dataset: PipelineOutput | null = await PipelineService.getPipelineOutputSourceJobByStep(request.attributionsJobId!, StepType.MODELLING_DATASET);
        if(source_modelling_dataset) {
          request.datasetJobId = source_modelling_dataset.jobId;
        }
      }
    }

    if(currentAnnualData && currentAnnualData['fullError']) {
      currentAnnualData = request;
    } else {
      currentAnnualData = {...currentAnnualData, ...request};
    }

    await AnnualService.postOfficialAnnualData(currentAnnualData);
  }

}
