import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import axios from 'axios';
import {ProcessingJob, ProcessingJobDto} from '@/store/models/processing_jobs_models';

@Module({ namespaced: true, name: 'processingJobModule' })
export default class ProcessingJobStore extends VuexModule {

  public get processingJobs(): ProcessingJob[] {
    return this.$processingJobs;
  }

  private $processingJobs: ProcessingJob[] = [];

  @Mutation
  public setProcessingJobs(processingJobs: ProcessingJob[]) {
     this.$processingJobs = processingJobs;
  }

  @Action({commit: 'setProcessingJobs'})
  public async syncProcessingJob(): Promise<ProcessingJob[]> {
    const {data} = await axios.get('/processing');
    const processingJobs: ProcessingJob[] = [];

    for (const dto of data as ProcessingJobDto[]) {
      processingJobs.push({
        jobId: dto.jobId,
        jobName: dto.jobName,
        jobType: dto.jobType,
        jobStatus: dto.jobStatus,
        failureReason: dto.failureReason,
        creationTime: new Date(dto.creationTime),
        endTime: dto.endTime ? new Date(dto.endTime) : undefined,
        author: dto.author,
      });
    }

    return processingJobs;
  }

  @Action
  public async stopProcessingJob(jobName: string): Promise<void> {
    await axios.delete('/processing', {
      params: {
        jobName,
      },
    });
  }
}
