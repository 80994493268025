
export const getAttributionYearBrandSuffix = (item): string => {
  if (item.additionalProperties.attributionsResultsSummary) {
    const brand: string = item.additionalProperties.attributionsResultsSummary.brands[0];
    const year: string = item.additionalProperties.attributionsResultsSummary.years[0];

    return '(' + year + ', ' + brand + ')';
  } else {
    return '';
  }
};

export const getAttributionYear = (item): number => {
  if (item.additionalProperties && item.additionalProperties.attributionsResultsSummary) {
    return item.additionalProperties.attributionsResultsSummary.years[0];
  } else if (item.stepParams && item.stepParams['CONFIG']) {
      return JSON.parse(item.stepParams['CONFIG']).year;
  } else {
    return 0;
  }
};

export const getAttributionBrand = (item): string => {
  if (item.additionalProperties && item.additionalProperties.attributionsResultsSummary) {
    return item.additionalProperties.attributionsResultsSummary.brands[0];
  } else if (item.stepParams && item.stepParams['CONFIG']) {
    return JSON.parse(item.stepParams['CONFIG']).brand;
  } else {
    return '';
  }
};

export const getJobTitleLabel = (
    jobId: string, name: string | undefined): string => {
  let label = jobId;
  if (name && name !== 'NA') {
    label = name;
  }
  return label;
};
