









































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import LevioLabToolbar from '@/components/shared/levioToolBar/LevioLabToolbar.vue';
import LevioLabToolbarCloseButton from '@/components/shared/levioToolBar/LevioLabToolbarCloseButton.vue';
import LevioLabToolBarCTA from '@/components/shared/levioToolBar/LevioLabToolBarCTA.vue';
import SimpleInfo from '@/components/shared/SimpleInfo.vue';
import ModellingFormItem from '@/components/attributions/modellingTrain/ModellingFormItem.vue';
import { ModellingTrainDatasetEvent } from '@/store/models/pipeline_models';
import { AnnualStoreMixin } from '@/mixins/store/AnnualStoreMixin';
import { Validations } from 'vuelidate-property-decorators';
import { minLength, required } from 'vuelidate/lib/validators';
import { form, getDuplicates, hasDuplicates, isError } from '@/utils/common';
import { cloneDeep } from 'lodash';
import { namespace } from 'vuex-class';
import { RequestError } from '@/store/models/common_models';
import AlertWidget from '@/components/shared/AlertWidget.vue';

const pipeline = namespace('PipelineStore');

@Component({
  components: {
    LevioLabToolbar,
    closeButton: LevioLabToolbarCloseButton,
    ctaButton: LevioLabToolBarCTA,
    SimpleInfo,
    ModellingFormItem,
    AlertWidget,
  },
})
export default class ModellingAttributionsDialog extends AnnualStoreMixin {
  // Properties
  @Prop({
    required: true,
  })
  public isOpen!: boolean;

  @pipeline.Action
  private startModellingAttributions!: (modellingJobs: ModellingTrainDatasetEvent[]) => Promise<void | RequestError>;

  // Local Variables

  private isValid: boolean = false;
  private formError: string = '';

  private static modellingDataItem: ModellingTrainDatasetEvent = {
    year: null,
    title: null,
    description: '',
  };

  private modellingDataItems: ModellingTrainDatasetEvent[] = [];

  @Validations()
  private validations = {
    modellingDataItems: {
      required,
      minLength: minLength(1),
      $each: {
        year: { required },
        title: { required },
      },
    },
  };

  public async onModellingClick(): Promise<void> {
    this.isValid = this.validateForm();
    if (this.isValid) {
      const modellingDataItems: ModellingTrainDatasetEvent[] = cloneDeep(this.modellingDataItems);
      const request: void | RequestError = await this.startModellingAttributions(modellingDataItems);
      if (request && isError(request)) {
        this.formError = this.$t('common.validation.usedTitleInList', {
          valueType: (this.$t('common.title') as string).toLowerCase(),
          value: (request as RequestError)!.erroredValue,
        }) as string;
      } else {
        this.startModelling(modellingDataItems);
        this.resetForm();
      }
    }
  }

  private validateForm(): boolean {
    this.formError = '';
    const hasNoDuplicateYear: boolean = this.validateUnique('year', (this.$t('common.year') as string).toLowerCase());
    const hasNoDuplicateTitles: boolean = this.validateUnique('title', (this.$t('common.title') as string).toLowerCase());
    return hasNoDuplicateYear && hasNoDuplicateTitles;
  }

  private validateUnique(property: string, valueType): boolean {
    const labelList: any[] = this.modellingDataItems.map(item => item[property] as any);
    const hasNoDuplicate: boolean = !hasDuplicates(labelList);

    if (!hasNoDuplicate && !this.formError) {
      this.formError = this.$t('common.validation.duplicateTitleInList', {
        valueType: valueType,
        value: getDuplicates(labelList),
      }) as string;
    }
    return hasNoDuplicate;
  }

  // Public Functions
  @Emit('onStartModelling')
  public startModelling(modellingDataItems: ModellingTrainDatasetEvent[]): ModellingTrainDatasetEvent[] | void {
    this.resetForm();
    return modellingDataItems;
  }

  @Emit('closeDialog')
  public closeDialog(): void {
    return;
  }

  // Private Functions
  private created(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    await this.fetchAnnualOfficialData();
    this.resetForm();
    await this.$nextTick();
    this.isValid = false;
  }

  private resetForm(): void {
    this.modellingDataItems = [];
    this.addNewYear();
    this.isValid = false;
    this.$v.$reset();
    if (form(this.$refs.trainingForm)) {
      form(this.$refs.trainingForm).resetValidation();
      form(this.$refs.trainingForm).reset();
    }
  }

  private async addNewYear(): Promise<void> {
    this.modellingDataItems.push(Object.assign({}, ModellingAttributionsDialog.modellingDataItem));
    await this.$nextTick();
    this.isValid = false;
  }

  private async onDeleteItem(index: number): Promise<void> {
    const modellingDataItems: ModellingTrainDatasetEvent[] = [...this.modellingDataItems];
    modellingDataItems.splice(index, 1);
    this.$set(this, 'modellingDataItems', []);
    await this.$nextTick();
    this.$set(this, 'modellingDataItems', modellingDataItems);
    this.isValid = false;
    form(this.$refs.trainingForm).validate();
  }

// Getters

// Watches

  @Watch('modellingDataItems', { deep: true })
  private async onModellingDataItemsChange(): Promise<void> {
    if (form(this.$refs.trainingForm)) {
      form(this.$refs.trainingForm).validate();
    }
    await this.$nextTick();
    this.isValid = !this.$v.modellingDataItems.$invalid;
  }
}
