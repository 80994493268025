









import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadFilesCard from '@/components/upload/UploadFilesCard.vue';
import { UploadHistoryInfo } from '@/store/models/upload_models';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';

const upload = namespace('UploadStore');

@Component({
  components: {
    LandingPageLayout,
    UploadFilesDialog: UploadFilesCard,
  },
})
export default class UploadLandingPage extends Vue {

  @upload.Action
  public syncHistory!: () => Promise<UploadHistoryInfo[]>;

  @upload.Getter
  private uploadProgress!: number;

  @upload.Getter
  private isUploading!: boolean;

  public mounted() {
    this.syncHistory();
  }

  @Watch('uploadProgress')
  private onUpload(val: number) {
    if (val === 100) {
      this.$router.replace('/cloudsharing/files');
    }
  }
}
