import * as pbi from 'powerbi-client';
import { PBIVisualViewType } from '@/store/enums/pbi_enums';

const models = pbi.models;

const basicFilter = (table: string, column: string, values:(string | number | boolean)[] ): pbi.models.IBasicFilter => {
  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table,
      column,
    },
    operator: 'In',
    values,
    filterType: pbi.models.FilterType.Basic,
    requireSingleSelection: true,
  }
};


const settingsByViewType = (type): any => {
  switch (type) {
    case PBIVisualViewType.VIEW_ONLY:
      return {
        filterPaneEnabled: false,
        navContentPaneEnabled: true,
        background: models.BackgroundType.Transparent,
        // START Report specific settings
        layoutType: models.LayoutType.Custom,
        customLayout: {
          // displayOption: models.DisplayOption.FitToWidth,
        },
      };
    case PBIVisualViewType.VIEW_FILTER:
      return {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        background: models.BackgroundType.Transparent,
        // START Report specific settings
        layoutType: models.LayoutType.Custom,
        customLayout: {
          // displayOption: models.DisplayOption.FitToWidth,
        },
      };
    case PBIVisualViewType.EDIT:
      return {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        background: models.BackgroundType.Transparent,
        // START Report specific settings
        layoutType: models.LayoutType.Custom,
        customLayout: {
          // displayOption: models.DisplayOption.FitToWidth,
        },
      };
    default:
      return {};
  }
}

export {
  basicFilter,
  settingsByViewType,
};
