import i18n from '@/plugins/i18n';
import {Brand} from '@/store/enums/pipeline_enums';

// Translate Enum

export const enumToStringArray = (anEnum: any): string[] => {
  return Object.keys(anEnum)
      // .filter(isStringNumber)
      .map((key) => anEnum[key]);
};

// Regex
export const camelCaseToTile = (camel: string): string => {
  return camel.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export const removeBrandFromJobId = (jobId: string): string | null => {
  let rJobId: string | null = null;
  enumToStringArray(Brand).forEach((brand) => {
    if (jobId.includes(brand)) {
      rJobId = jobId.slice(0, -brand.length - 1);
    }
  });

  return rJobId;
};

const toCamelCase = (anEnum: any) => {
  const enumString = anEnum.toString();

  if (enumString.includes('_')) {
    const array = anEnum.toString().split('_');
    return array[0].toLowerCase() + array[1][0].toUpperCase() +
        array[1].slice(1).toLowerCase();

  } else {
    return enumString.toLowerCase();
  }
};

export const translateEnum = (enumElement: any, path: string) => {
  const finalKey: string = toCamelCase(enumElement);
  return i18n.t(`enum.${path}.${finalKey}`);
};

export const translateEnumVSelect = (anEnum: any, path: any)
    : { text: string, value: any }[] => {
  const labelsAndValues: Array<{ text: string, value: any }> = [];
  const enumArray = enumToStringArray(anEnum);

  enumArray.forEach((enumElement) => {
    labelsAndValues.push(
        {
          text: translateEnum(enumElement, path) as string,
          value: anEnum[enumElement],
        },
    );
  });
  return labelsAndValues;
};
