




















































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CSVDownloadMixin from '@/mixins/CSVDownloadMixin';
import { BudgetOptimizationInfo, PipelineOutput } from '@/store/models/pipeline_models';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import AmBarChart from '@/components/shared/amcharts/AmBarChart.vue';
import BudgetTable from '@/components/budgetPlan/specificInfos/BudgetTable.vue';
import QuotesTable from '@/components/budgetPlan/specificInfos/QuotesTable.vue';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';
import AlertWidget from '@/components/shared/AlertWidget.vue';
import OptimizationResult from '@/components/budgetPlan/OptimizationResult.vue';

import { UIContextualDrawerItem } from '@/store/models/ui_models';
import ContextualDrawer from '@/components/shared/contextualDrawer/ContextualDrawer.vue';
import ContextualDrawerItem from '@/components/shared/contextualDrawer/ContextualDrawerItem.vue';
import ContextualDrawerContainer from '@/components/shared/contextualDrawer/ContextualDrawerContainer.vue';
import OptimizationCreationDialog from '@/components/budgetPlan/BudgetPlanCreationDialog.vue';
import { PBIElement } from '@/store/models/powerBi_models';
import { pbiVisualsList } from '@/utils/data/pbi_visuals';
import PowerBIElement from '@/components/shared/PowerBIElement.vue';
import { PBIVisualViewType } from '@/store/enums/pbi_enums';
import { BudgetPlanType, StepType } from '@/store/enums/pipeline_enums';
import OfficializationDialog from '@/components/officialization/OfficializationDialog.vue';

const globalUI = namespace('GlobalUIStore');
const pipeline = namespace('PipelineStore');

@Component({
  components: {
    PowerBIElement,
    LandingPageLayout,
    LevioLabTitle,
    OptimizationResult,
    OptimizationCreationDialog,
    AlertWidget,
    AmBarChart,
    BudgetTable,
    QuotesTable,
    ContextualDrawer,
    ContextualDrawerItem,
    ContextualDrawerContainer,
    OfficializationDialog,
  },
})
export default class ComparativeViewLandingPage extends Mixins(CSVDownloadMixin) {
  @globalUI.Getter
  private isDemoMode!: boolean;

  @pipeline.Getter
  private budgetOptimizationOutputs!: PipelineOutput[];

  @pipeline.Getter
  private currentOptimizationInfo!: BudgetOptimizationInfo | null;

  @pipeline.Mutation
  private setCurrentOptimizationInfo!: (jobResult: BudgetOptimizationInfo | null) => void;

  @pipeline.Mutation
  private clearChildrenOptimization!: () => void;

  @pipeline.Action
  private fetchBudgetOptimizationOutputs!: () => Promise<PipelineOutput[]>;

  @pipeline.Action
  private fetchBudgetOptimizationInfo!: (jobId: string) => Promise<BudgetOptimizationInfo>;

  private selectJob: PipelineOutput | null = null;
  private syncInterval!: number;

  private isOptimizationCreationOpen: boolean = false;
  private isOptimizationOfficializationOpen: boolean = false;
  private displayResult: boolean = false;

  @Watch('selectJob')
  private async onJobSelected() {
    this.displayResult = false;

    await this.fetchBudgetOptimizationInfo(this.selectJob!.jobId);
    this.displayResult = true;
  }

  // Contextual Drawer
  private download(): void {
    this.downloadFileFromJob(this.selectJob);
  }

  private get drawerOptimizationCreation(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOptimizationCreationOpen = true;
      },
      icon: 'fa-chart-line',
      tooltip: this.$t('optimization.create') as string,
      badgeIcon: 'fa-plus',
      badgeColor: 'light-green',
    };
  }

  private get drawerOptimizationOfficialize(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOptimizationOfficializationOpen = true;
      },
      icon: 'fa-certificate',
      tooltip: this.$t('optimization.create') as string,
    };
  }

  private get drawerDownload(): UIContextualDrawerItem {
    return {
      onClick: this.download,
      icon: 'fa-cloud-download',
      tooltip: this.$t('optimization.downloadButton') as string,
    };
  }

  private get comparativeReport(): PBIElement {
    return pbiVisualsList.comparativeReport;
  }

  private get optimizationType(): StepType {
    return StepType.OPTIMIZATION;
  }

  private get optimizationBudgetPlanType(): BudgetPlanType {
    return BudgetPlanType.OPTIMIZATION;
  }

  private get viewType(): PBIVisualViewType {
    return PBIVisualViewType.VIEW_FILTER;
  }
}
