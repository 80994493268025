





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PBIDashboardDTO, PBIElement, PBIRenderInfo, PBIReportDTO } from '@/store/models/powerBi_models';

import * as pbi from 'powerbi-client';
import { Embed, IEmbedConfiguration } from 'embed';
import { PBIElementType, PBIVisualViewType } from '@/store/enums/pbi_enums';
import { settingsByViewType } from '@/utils/data/pbi_settings';

const models = pbi.models;

const pbiReports = namespace('PowerBIStore');

const powerBI: pbi.service.Service = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);

@Component({})
export default class PowerBIElement extends Vue {

  @pbiReports.Action
  private getPowerBIEmbeddedElementById!: (pbiElementInfo: PBIElement) => Promise<PBIRenderInfo | void>;

  @Prop({
    required: true,
  })
  private pbiElementInfo!: PBIElement;

  @Prop({
    default: () => [],
  })
  private filters!: pbi.models.IFilter[];

  @Prop({
    default: () => [],
  })
  private slicersToBeReset!: string[];

  @Prop({
    default: () => [],
  })
  private hiddenTabs!: string[];

  @Prop({
    default: PBIVisualViewType.VIEW_ONLY,
  })
  private reportViewType!: PBIVisualViewType;

  @Prop({
    default: false,
  })
  private isDemoMode!: boolean;

  private demoGraph: any = {
    fill: true,
    selectedGradient: ['#134074', '#85BD03'],
    radius: 10,
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
  }

  private currentPBIElement!: PBIReportDTO | PBIDashboardDTO;
  private pbiEmbed!: Embed;
  private tempLocalAT!: string;

  private mounted(): void {
    this.loadPowerBIElement();
  }

  private updated(): void {
    this.loadPowerBIElement();
  }

  private async loadPowerBIElement(): Promise<void> {

    if (!this.isDemoMode) {
      const pbiRenderInfo: PBIRenderInfo | void = await this.getPowerBIEmbeddedElementById(this.pbiElementInfo);

      if (pbiRenderInfo) {
        this.currentPBIElement = pbiRenderInfo.pbiDTO;
        this.tempLocalAT = pbiRenderInfo.accessToken;

        await this.renderPowerBI();
      }
    } else {
      const iFrameElement = window.document.getElementsByTagName('iframe')[0] as HTMLElement;
      iFrameElement.remove();
    }
  }

  private async renderPowerBI(): Promise<void> {

    const elementContainer = window.document.getElementById('pbi_' + this.pbiElementInfo.id) as HTMLElement;
    powerBI.reset(elementContainer);

    const tokenType = models.TokenType.Embed;
    const permissions = models.Permissions.Read;

    const config: IEmbedConfiguration = {
      type: this.pbiElementInfo.type.toLowerCase().toString(),
      tokenType,
      accessToken: this.tempLocalAT,
      embedUrl: this.currentPBIElement.embedUrl,
      id: this.currentPBIElement.id,
      pageView: 'fitToWidth',
      filters: this.filters,
    };

    if (this.pbiElementInfo.type === PBIElementType.REPORT) {
      config.settings = this.settingsByViewType;
      config.permissions = permissions;
    }

    this.pbiEmbed = powerBI.embed(elementContainer, config);

    this.pbiEmbed.off('loaded');
    this.pbiEmbed.off('saved');
    this.pbiEmbed.off('rendered');
    this.pbiEmbed.off('error');

    // Report.on will add an event handler which prints to Log window.
    this.pbiEmbed.on('loaded', async () => {
      // console.log('Loaded', this.filters);
      await this.manageSlicers();
      await this.manageHiddenPages();

    });

    // Report.on will add an event handler which prints to Log window.
    this.pbiEmbed.on('pageChanged', async (event: pbi.service.ICustomEvent<unknown>) => {
      // eslint-disable-next-line
      console.log('PBI pageChanged', (event.detail as any).newPage.displayName);
    });

    // Report.on will add an event handler which prints to Log window.
    this.pbiEmbed.on('rendered', async () => {
      // eslint-disable-next-line
      console.log('PBI Rendered');
    });

    this.pbiEmbed.on('error', (event) => {
      // this.pbiEmbed.off('error');
      // eslint-disable-next-line
      console.log(event.detail);
    });

    this.pbiEmbed.on('saved', (event: any) => {
      // console.log(event.detail);
      if (event.detail.saveAs) {
        // console.log('In order to interact with the new report, create a new token and load the new report');
      }
    });
  }

  private get settingsByViewType(): any {
    return settingsByViewType(this.reportViewType);
  }

  private async manageSlicers(): Promise<void> {
    if (this.slicersToBeReset.length > 0) {
      const currentPage: pbi.Page = await (this.pbiEmbed as pbi.Report).getActivePage();
      const visuals = await currentPage.getVisuals();
      for (const slicerName of this.slicersToBeReset) {
        const slicer: pbi.VisualDescriptor = visuals.filter((visual) => {
          return visual.type === 'slicer' && visual.title === slicerName;
        })[0];

        await slicer.setSlicerState({
          filters: this.filters as pbi.models.ISlicerFilter[],
        });
        continue;
      }
    }
  }

  private async manageHiddenPages(): Promise<void> {
    if (this.hiddenTabs.length > 0) {
      const pages: pbi.Page[] = await (this.pbiEmbed as pbi.Report).getPages();
      for (const tabName of this.hiddenTabs) {
        for (const page of pages) {
          if (page.displayName === tabName) {
            await page.delete();
          }
        }
      }
    }
  }
}
