var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"etl-job__dialog",attrs:{"max-width":"60vw","persistent":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(_vm.isOpen)?_c('v-card',[_c('levio-lab-toolbar',{scopedSlots:_vm._u([{key:"close-button",fn:function(){return [_c('close-button',{on:{"onClick":_vm.closeDialog}})]},proxy:true},{key:"cta",fn:function(){return [_c('cta-button',{attrs:{"disabled":!_vm.isValid,"icon":"fa-calculator"},on:{"onClick":function($event){return _vm.startJob()}}},[_vm._v(" "+_vm._s(_vm.$t('etl.jobDialog.ctaButton'))+" ")])]},proxy:true}],null,false,2058078589)},[_vm._v(" "+_vm._s(_vm.$t('etl.jobDialog.title'))+" ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-form',{ref:"etlJobForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-select',{attrs:{"items":_vm.validUploadOutputs,"label":_vm.$t('etl.jobDialog.uploadsSelect'),"hide-details":"auto","solo":"","required":"","return-object":"","item-value":"id","item-text":"name","error-messages":_vm.getRequiredErrors(_vm.$v.selectedUpload)},on:{"blur":function($event){return _vm.$v.selectedUpload.$touch()},"change":function($event){return _vm.$v.selectedUpload.$touch()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{staticClass:"max-width"},[(item.name)?_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-list-item-title',[_vm._v(" "+_vm._s(item.jobId)+" ")]),(item.name)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.jobId)+" ")]):_vm._e()],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"append-outer",fn:function(){return [(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"theme7"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.fetchData()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" fa-refresh ")])],1)]}}],null,false,4181771528)},[_c('span',[_vm._v(_vm._s(_vm.$t('etl.jobDialog.tooltip')))])])]},proxy:true}],null,false,848372859),model:{value:(_vm.selectedUpload),callback:function ($$v) {_vm.selectedUpload=$$v},expression:"selectedUpload"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-combobox',{attrs:{"items":_vm.availableYears,"label":_vm.$t('common.years'),"chips":"","multiple":"","clearable":"","hide-details":"auto","solo":"","required":"","error-messages":_vm.getRequiredErrors(_vm.$v.selectedYears)},on:{"blur":function($event){return _vm.$v.selectedYears.$touch()},"change":function($event){return _vm.$v.selectedYears.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeFromSelectedYears(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,3313194231),model:{value:(_vm.selectedYears),callback:function ($$v) {_vm.selectedYears=$$v},expression:"selectedYears"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('common.name'),"hide-details":"auto","type":"text","required":"","dense":"","filled":"","error-messages":_vm.getTitleErrorMessages(_vm.$v.jobTitle)},on:{"keydown":function($event){_vm.$v.jobTitle.$touch();_vm.requestError = '';},"blur":function($event){return _vm.$v.jobTitle.$touch()}},model:{value:(_vm.jobTitle),callback:function ($$v) {_vm.jobTitle=$$v},expression:"jobTitle"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('common.description'),"required":"","filled":""},model:{value:(_vm.jobDescription),callback:function ($$v) {_vm.jobDescription=$$v},expression:"jobDescription"}})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }