


































import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class LevioLabTitle extends Vue {

  @Prop({
    default: false,
  })
  private canGoBack!: boolean;

  @Prop()
  private title!: string;

  @Prop()
  private caption!: string;

  @Prop()
  private overline!: string;

  @Prop()
  private description!: string;

  @Prop({
    required: false,
    default: 'primary',
  })
  private color!: string;

  get colorTextClass(): string {
    return this.color + '--text';
  }

  private goPrevious(): void {
    this.$router.back();
  }

}
