import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { PBIDashboardDTO, PBIElement, PBIRenderInfo, PBIReportDTO } from '@/store/models/powerBi_models';
import axios from 'axios';
import { PBIElementType } from '@/store/enums/pbi_enums';

@Module({ namespaced: true, name: 'powerBiModule' })
export default class PowerBIStore extends VuexModule {

  private $accessToken!: string | null;
  private $powerBIElements!: PBIReportDTO[] | PBIDashboardDTO[];

  public get accessToken(): string | null {
    return this.$accessToken;
  }

  public get powerBIElements(): PBIReportDTO[] | PBIDashboardDTO[] {
    return this.$powerBIElements;
  }

  @Mutation
  public setAccessToken(token: string): void {
    this.$accessToken = token;
  }

  @Mutation
  public setPowerBIElements(elements: PBIReportDTO[] | PBIDashboardDTO[]): void {
    this.$powerBIElements = elements;
  }

  @Action({ commit: 'setAccessToken' })
  public async getAccessToken(): Promise<string | void> {
    try {
      const { data } = await axios.get(`/powerbi/accesstoken`);
      return data.accessToken;
    } catch (e) {
      // console.log(e as string);
    }
  }

  @Action
  public async getPowerBIEmbeddedElementById(pbiElementInfo: PBIElement): Promise<PBIRenderInfo | void> {
    try {
      const typeString = pbiElementInfo.type.toString().toLowerCase();
      const { data } = await axios.get(`/powerbi/${typeString}/${pbiElementInfo.id}/embedurl`);
      let elements: PBIReportDTO[] | PBIDashboardDTO[];
      switch (pbiElementInfo.type) {
        case PBIElementType.REPORT:
          elements = data.embedReports;
          break;
        case PBIElementType.DASHBOARD:
          elements = data.embedDashboards;
          break;
        default:
          elements = [];
      }
      return {
        accessToken: data.embedToken.token,
        pbiDTO: elements[0],
      };
    } catch (e) {
      // console.log(e as string);
    }
  }
}
