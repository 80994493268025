













































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import CSVDownloadMixin from '@/mixins/CSVDownloadMixin';
import {BudgetOptimizationInfo} from '@/store/models/pipeline_models';
import AlertWidget from '@/components/shared/AlertWidget.vue';
import AmBarChart from '@/components/shared/amcharts/AmBarChart.vue';
import BudgetTable from '@/components/budgetPlan/specificInfos/BudgetTable.vue';
import QuotesTable from '@/components/budgetPlan/specificInfos/QuotesTable.vue';
import InformationSummary from '@/components/shared/InformationSummary.vue';

@Component({
  components: {
    InformationSummary,
    AlertWidget,
    AmBarChart,
    BudgetTable,
    QuotesTable,
  },
})
export default class OptimizationResult extends Mixins(CSVDownloadMixin) {

  @Prop({
    default: false,
  })
  private hideSummary!: boolean;

  @Prop({
    default: true,
  })
  private rightSummary!: boolean;

  @Prop({
    required: true,
  })
  private mainOptimizationInfo!: BudgetOptimizationInfo | null;

  @Prop({
    default: null,
  })
  private parentOptimizationInfo!: BudgetOptimizationInfo | null;

  private get hasDescription(): boolean {
    return (this.mainOptimizationInfo!.output!.description && this.mainOptimizationInfo!.output!.description !== 'NA')
        || false;
  }

}
