















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PipelineOutput } from '@/store/models/pipeline_models';

import { getAttributionBrand, getAttributionYear } from '@/utils/easy-getters';
import { StepType } from '@/store/enums/pipeline_enums';

@Component({})
export default class OfficializationOutputItem extends Vue {
  // Properties
  @Prop({
    required: true,
  })
  public item!: PipelineOutput;

  @Prop({
    required: true,
  })
  public on!: any;

  @Prop({
    required: true,
  })
  public attrs!: any;

  @Prop({
    required: true,
  })
  public active!: any;

// Local Variables

// Public Functions

// Private Functions

// Getters

  private getAttributionYear(item): number {
    return getAttributionYear(item);
  }

  private getAttributionBrand(item): string {
    return getAttributionBrand(item);
  }

  private get isAttribution(): boolean {
    return this.item.step === StepType.MODELLING_ATTRIBUTIONS;
  }

  private get isETL(): boolean {
    return this.item.step === StepType.ETL;
  }

  private get isOptimization(): boolean {
    return this.item.step === StepType.OPTIMIZATION;
  }

  private get itemName(): string {
    return this.item.name ? this.item.name : this.item.jobName ? this.item.jobName : 'NA';
  }

// Watches
}
