export interface NavigationSection {
  text: string;
  icon?: string;
  url: string;
  items?: NavigationSection[];
  disabled?: boolean;
  permission?: string;
}

export interface AgreementContract {
  timestamp: string;
  accept: boolean;
}

export interface UISnackBar {
  text?: string;
  color?: string;
  visible?: boolean;
  timeout?: number;
}

export enum DialogContentType {
  NONE = 'none',
  INACTIVITY = 'inactivity',
  AGREEMENT = 'agreement',
  LOGGED_OUT = 'loggedOut',
}

export interface UIDialog {
  width?: number;
  component?: string;
  isPersistent?: boolean;
}

export interface UIContextualDrawerItem {
  onClick: () => void | null;
  icon: string;
  tooltip: string;
  class?: string;
  color?: string;
  badgeColor?: string;
  badgeIcon?: string;
  badgeValue?: string;
  badgeIsTile?: boolean;
  isDisabled?: boolean;
  hasDivider?: boolean;
}

export interface ConceptTheme {
  icon: string;
  color?: string;
}
