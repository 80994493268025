var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('LevioLabToolbar',{scopedSlots:_vm._u([{key:"close-button",fn:function(){return [_c('close-button',{on:{"onClick":_vm.closeDialog}})]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]},proxy:true}])}),_c('v-card-title'),_c('v-card-text',[(_vm.hasTabs)?_c('v-tabs',{model:{value:(_vm.dialogTabs),callback:function ($$v) {_vm.dialogTabs=$$v},expression:"dialogTabs"}},[(_vm.summaryCSV)?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('common.summary'))+" ")]):_vm._e(),(_vm.csv)?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('csv.sample'))+" ")]):_vm._e(),(_vm.errorCSV)?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('common.error'))+" ")]):_vm._e()],1):_vm._e(),(_vm.hasTabs)?_c('v-tabs-items',{model:{value:(_vm.dialogTabs),callback:function ($$v) {_vm.dialogTabs=$$v},expression:"dialogTabs"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"loading":_vm.isSummaryLoading,"headers":_vm.summaryHeaders,"footer-props":{
            'items-per-page-options': [20, 30, 50],
          },"items":_vm.summaryRows.slice(1),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',_vm._l((item),function(data,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(data)+" ")])}),0)]}}],null,false,1276981208)})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"loading":_vm.isSampleLoading,"headers":_vm.sampleHeaders,"footer-props":{
            'items-per-page-options': [20, 30, 50],
          },"items":_vm.sampleRows.slice(1),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',_vm._l((item),function(data,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(data)+" ")])}),0)]}}],null,false,1276981208)})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"loading":_vm.isErrorLoading,"headers":_vm.errorHeaders,"footer-props":{
            'items-per-page-options': [20, 30, 50],
          },"items":_vm.errorRows.slice(1),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',_vm._l((item),function(data,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(data)+" ")])}),0)]}}],null,false,1276981208)})],1)],1):[(_vm.csv)?_c('v-data-table',{attrs:{"loading":_vm.isSampleLoading,"headers":_vm.sampleHeaders,"footer-props":{
          'items-per-page-options': [20, 30, 50],
        },"items":_vm.sampleRows.slice(1),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',_vm._l((item),function(data,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(data)+" ")])}),0)]}}],null,false,1276981208)}):_vm._e(),(_vm.errorCSV)?_c('v-data-table',{attrs:{"loading":_vm.isErrorLoading,"headers":_vm.errorHeaders,"footer-props":{
          'items-per-page-options': [20, 30, 50],
        },"items":_vm.errorRows.slice(1),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',_vm._l((item),function(data,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(data)+" ")])}),0)]}}],null,false,1276981208)}):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }