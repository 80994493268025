






































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {isProd} from '@/utils/common';
import {NavigationSection} from '@/store/models/ui_models';
import LevioLabLoader from '@/components/shared/LevioLabLoader.vue';
import LanguageSwitch from '@/components/shared/LanguageSwitch.vue';
import {conceptsThemes} from '@/plugins/themes';

const globalUI = namespace('GlobalUIStore');
const auth = namespace('AuthStore');
const upload = namespace('UploadStore');

@Component({
  components: {
    LevioLabLoader,
    LanguageSwitch,
  },
})
export default class MainNavigation extends Vue {

  @globalUI.Getter
  private isDemoMode!: boolean;

  @globalUI.Mutation
  private setDemoMode!: (mode: boolean) => void;

  @auth.Getter
  private authorized!: boolean;

  @auth.Action
  private logout!: () => Promise<boolean>;

  @upload.Getter
  private uploadProgress!: number;

  @upload.Getter
  private isUploading!: boolean;

  private drawer: boolean = true;

  private created(): void {
    const demoMode: string | null = localStorage.getItem('demoMode');
    if (demoMode) {
      this.setDemoMode(JSON.parse(demoMode));
    }
  }

  private get isSwitchOn(): boolean {
    return this.isDemoMode;
  }

  private set isSwitchOn(value: boolean) {
    localStorage.setItem('demoMode', JSON.stringify(value));
    this.setDemoMode(value);
  }

  private get navigationItems(): NavigationSection[] {
    return [
      {
        icon: conceptsThemes.dashboard.icon,
        text: this.$t('dashboard.title') as string,
        url: '/dashboard',
        permission: 'view Dashboard',
      },
      {
        icon: conceptsThemes.attribution.icon,
        text: this.$t('attributions.title') as string,
        url: '/attributions',
        permission: 'view Attribution',
        items: [
          {
            url: '/attributions/businesscontext',
            text: this.$t('attributions.businessContext.title') as string,
            permission: 'view Attribution',
          },
        ],
      },
      {
        icon: conceptsThemes.budgetPlan.icon,
        text: this.$t('budgetPlan.title') as string,
        url: '/budgetplan',
        permission: 'view Optimization',
        items: [
          {
            url: '/budgetplan/comparativeview',
            text: this.$t('budgetPlan.comparativeView.title') as string,
            permission: 'view Optimization',
          },
        ],
      },
      // {
      //   icon: 'fa-calculator',
      //   text: this.$t('advancedTools.title') as string,
      //   url: '/advanced-tools',
      //   permission: 'view AdvancedTools',
      //   disabled: !this.isDemoMode,
      // },
      {
        url: '/processing',
        icon: conceptsThemes.processing.icon,
        text: this.$t('processing.title') as string,
        permission: 'view Processing',
      },
      {
        url: '/cloudsharing',
        icon: conceptsThemes.cloudSharing.icon,
        text: this.$t('cloudSharing.title') as string,
        permission: 'view Upload',
        items: [
          {
            url: '/cloudsharing/files',
            text: this.$t('cloudSharing.files.title') as string,
            permission: 'view Upload',
          },
          {
            url: '/cloudsharing/upload',
            text: this.$t('common.upload') as string,
            permission: 'create Upload',
          },
        ],
      },
      {
        url: '/modelgovernance',
        icon: conceptsThemes.modelGovernance.icon,
        text: this.$t('modelGovernance.title') as string,
        permission: 'view ModelGovernance',
      },
      {
        url: '/support',
        icon: conceptsThemes.support.icon,
        text: this.$t('support.title') as string,
        permission: 'view Support',
      },
    ];
  }

  private localLogout() {
    this.logout().then(() => {
      this.$router.replace('/signin');
    });
  }

  private title() {
    return this.findTitle(this.navigationItems, this.$route.path);
  }

  private findTitle(navItems: NavigationSection[], path: string): string {

    const splitPath = path.split('/');

    const navItem = navItems.find((item) => splitPath[1] === item.url.replace('/', ''));

    if (navItem) {
      return navItem.text;
    }

    return '';
  }

  private get isProd(): any {
    return isProd();
  }

  private get concepts(): any {
    return conceptsThemes;
  }
}
