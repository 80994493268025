
































































































import { Component, Vue } from 'vue-property-decorator';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import ContextualDrawerContainer from '@/components/shared/contextualDrawer/ContextualDrawerContainer.vue';
import ContextualDrawer from '@/components/shared/contextualDrawer/ContextualDrawer.vue';
import { isProd } from '@/utils/common';
import { UIContextualDrawerItem } from '@/store/models/ui_models';
import ContextualDrawerItem from '@/components/shared/contextualDrawer/ContextualDrawerItem.vue';
import UploadFilesCard from '@/components/upload/UploadFilesCard.vue';
import OfficializationDialog from '@/components/officialization/OfficializationDialog.vue';
import { PBIElement } from '@/store/models/powerBi_models';
import { pbiVisualsList } from '@/utils/data/pbi_visuals';
import PowerBIElement from '@/components/shared/PowerBIElement.vue';
import AttributionAnnualContextDialog from '@/components/attributions/AttributionAnnualContextForm.vue';
import { namespace } from 'vuex-class';
import AlertWidget from '@/components/shared/AlertWidget.vue';
import ContextualDrawerDivider from '@/components/shared/contextualDrawer/ContextualDrawerDivider.vue';
import { PipelineOutput } from '@/store/models/pipeline_models';
import AttributionDisclaimerDialog from '@/components/attributions/AttributionDisclaimerDialog.vue';
import pbi from 'powerbi-client';
import { basicFilter } from '@/utils/data/pbi_settings';
import { UserData } from '@/store/models/auth_models';
import ETLJobDialog from '@/components/etl/ETLJobDialog.vue';
import { StepType } from '@/store/enums/pipeline_enums';
import { conceptsThemes } from '@/plugins/themes';

const auth = namespace('AuthStore');
const pipeline = namespace('PipelineStore');
const upload = namespace('UploadStore');
const globalUI = namespace('GlobalUIStore');

@Component({
  components: {
    LandingPageLayout,
    ContextualDrawerContainer,
    ContextualDrawerDivider,
    ContextualDrawerItem,
    ContextualDrawer,
    UploadFilesCard,
    OfficializationDialog,
    AttributionAnnualContextDialog,
    AttributionDisclaimerDialog,
    PowerBIElement,
    AlertWidget,
    etlJobDialog: ETLJobDialog,
  },
})
export default class BusinessContextLandingPage extends Vue {
  @pipeline.Action
  private fetchLatestAttributionOutputs!: () => Promise<PipelineOutput[]>;

  @pipeline.Getter
  private latestAttributionOutputs!: PipelineOutput[];

  @pipeline.Mutation
  private clearUploadValidationData!: () => void;

  @globalUI.Getter
  private isDemoMode!: boolean;

  @upload.Action
  private getUrlForFile!: (key: string) => Promise<string>;

  @auth.Getter
  private currentUserData!: UserData;

  // Drawer Dialogs
  private isUploadDialogOpen: boolean = false;
  private isETLJobDialogOpen: boolean = false;
  private isAnnualContextDialogOpen: boolean = false;
  private isOfficializationDialogOpen: boolean = false;
  private isShowAttributionDisclaimerDialogOpen: boolean = false;


  private filters: pbi.models.IFilter[] = [];

  private created() {
    if (this.currentUserData.brands!.length > 0) {
      this.filters = [
        basicFilter('DIM_brand', 'brand/network', this.currentUserData.brands),
      ];
    }
  }

  private get uploadFile(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isUploadDialogOpen = true;
      },
      icon: 'fa-cloud-upload-alt',
      tooltip: this.$t('attributions.upload.title') as string,
      badgeIcon: 'fa-plus',
      badgeColor: 'light-green',
    };
  }

  private get etlCalculation(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isETLJobDialogOpen = true;
      },
      icon: 'fa-calculator',
      tooltip: this.$t('etl.jobDialog.title') as string,
      badgeIcon: 'fa-plus',
      badgeColor: 'light-green',
    };
  }

  private get annualContextCreation(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isAnnualContextDialogOpen = true;
      },
      icon: 'fa-pen-fancy',
      tooltip: this.$t('annualContext.title') as string,
    };
  }

  private get etlOfficialization(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOfficializationDialogOpen = true;
      },
      icon: conceptsThemes.officialization.icon,
      color: conceptsThemes.officialization.color,
      tooltip: this.$t('attributions.officialization.etl') as string,
    };
  }

  private get attributionDisclaimer(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isShowAttributionDisclaimerDialogOpen = true;
      },
      icon: 'fa-exclamation-circle',
      tooltip: this.$t('disclaimer.title') as string,
      hasDivider: true,
      class: 'mt-2 mb-3',
    };
  }

  private openELTJobDialog(): void {
    this.isUploadDialogOpen = false;
    this.isETLJobDialogOpen = true;
  }

  private closeELTJobDialog(): void {
    this.isETLJobDialogOpen = false;
    this.clearUploadValidationData();
  }

  private get officializationType(): StepType {
    return StepType.ETL;
  }

  private get isProd(): any {
    return isProd();
  }

  private get businessContextReport(): PBIElement {
    return pbiVisualsList.businessContextReport;
  }
}
