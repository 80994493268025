























import Component from 'vue-class-component';
import Vue from 'vue';
import OptimizationResult from '@/components/budgetPlan/OptimizationResult.vue';
import { Prop } from 'vue-property-decorator';
import {BudgetOptimizationInfo} from '@/store/models/pipeline_models';
import {namespace} from 'vuex-class';

const pipeline = namespace('PipelineStore');

@Component({
  components: {
    OptimizationResult,
  },
})
export default class ProcessingResultDialog extends Vue {

  @pipeline.Getter
  private currentOptimizationInfo!: BudgetOptimizationInfo | null;

  @Prop()
  private closeDialog!: () => void | null;

  @Prop()
  private jobTitle!: string;
}
