

















import { namespace } from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';

const globalUI = namespace('GlobalUIStore');

@Component
export default class DialogContentLoggedOut extends Vue {

  @globalUI.Mutation
  public setDialogVisible!: (isVisible: boolean) => void;

}
