import i18n from '@/plugins/i18n';
import { helpers } from 'vuelidate/lib/validators'
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';

export default class Rules {

  public static getRequiredMessages(validatedItem: Validation & ValidationProperties<any>): string[] {
    const errors: string[] = [];
    if (validatedItem.$dirty) {
      if (!validatedItem.required) {
        errors.push(i18n.t('common.validation.fieldRequired') as string);
      }
    }
    return errors;
  }

  public static getHigherThanZeroMessages(validatedItem: Validation & ValidationProperties<any>): string[] {
    const errors: string[] = [];
    if (validatedItem.$dirty) {
      if (!validatedItem.minValue) {
        errors.push(i18n.t('common.validation.number.higherThanZero') as string);
      }
      if (!validatedItem.required) {
        errors.push(i18n.t('common.validation.fieldRequired') as string);
      }
    }
    return errors;
  }

  public static getEmailValidationMessages(validatedItem: Validation & ValidationProperties<any>): string[] {
    const errors: string[] = [];
    if (validatedItem.$dirty) {
      if (!validatedItem.email) {
        errors.push(i18n.t('common.validation.email.invalid') as string);
      }
      if (!validatedItem.required) {
        errors.push(i18n.t('common.validation.email.required') as string);
      }
    }
    return errors;
  }

  public static getPasswordValidationMessages(validatedItem: Validation & ValidationProperties<any>): string[] {
    const errors: string[] = [];
    if (validatedItem && validatedItem.$dirty) {
      if (!validatedItem.passwordValid) {
        errors.push(i18n.t('common.validation.password.invalid') as string);
      }
      if (!validatedItem.minLength) {
        errors.push(i18n.t('common.validation.password.minLength') as string);
      }
      if (!validatedItem.required) {
        errors.push(i18n.t('common.validation.password.required') as string);
      }
    }
    return errors;
  }

  public static getCodeValidationMessages(validatedItem: Validation & ValidationProperties<any>): string[] {
    const errors: string[] = [];
    if (validatedItem && validatedItem.$dirty) {
      if (!validatedItem.required) {
        errors.push(i18n.t('common.validation.confirmationCode.required') as string);
      }
      if (!validatedItem.numeric) {
        errors.push(i18n.t('common.validation.confirmationCode.numeric') as string);
      }
    }
    return errors;
  }

  public static getConfirmPasswordValidationMessages(validatedItem: Validation & ValidationProperties<any>): string[] {
    const errors: string[] = [];
    if (validatedItem && validatedItem.$dirty) {
      if (!validatedItem.required) {
        errors.push(i18n.t('common.validation.password.confirm') as string);
      }
      if (!validatedItem.sameAs) {
        errors.push(i18n.t('common.validation.password.matchPassword') as string);
      }
    }
    return errors;
  }

}

export const passwordValid = helpers.regex('password', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/);



