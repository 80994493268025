

export enum PipelineStepType {
  UPLOAD = 'UPLOAD',
  ETL = 'ETL',
  MARKETING_EXPENSES = 'MARKETING_EXPENSES',
  MODELLING_ATTRIBUTIONS = 'MODELLING_ATTRIBUTIONS',
  MODELLING_INFLEXIONS = 'MODELLING_INFLEXIONS',
  TABLEAU_PREPARATION = 'TABLEAU_PREPARATION',
  BUDGET_OPTIMIZATION = 'BUDGET_OPTIMIZATION',
  UPLOAD_VALIDATION = 'UPLOAD_VALIDATION',
}

export enum AttributionType {
  abs_values = 'abs_values',
}

export enum Channel {
  EAX_quotes = 'EAX_quotes',
  CCC_quotes = 'CCC_quotes',
  WEB_quotes = 'WEB_quotes',
  KAN_quotes = 'KAN_quotes',
}

export enum GeoTimeMode {
  geo_time = 'geo_time',
}

export enum Media {
  ADDRESSED_DM = 'ADDRESSED_DM',
  UNADDRESSED_DM = 'UNADDRESSED_DM',
  AD_WEB = 'AD_WEB',
  EMAIL = 'EMAIL',
  NEWSPAPER = 'NEWSPAPER',
  OUTDOOR = 'OUTDOOR',
  PHONE = 'PHONE',
  PPC_BRANDED = 'PPC_BRANDED',
  PPC_UNBRANDED = 'PPC_UNBRANDED',
  RADIO = 'RADIO',
  REFERENCES = 'REFERENCES',
  TV = 'TV',
}

export enum Province {
  QC = 'QC',
  ON = 'ON',
  NB = 'NB',
  AB = 'AB',
  OTHER = 'OTHER',
}

export enum Brand {
  DA = 'DA',
  DI = 'DI',
  DAN = 'DAN',
  SYNERGY = 'SYNERGY',
}

export enum OptimizationBudgetType {
  FIXED = 'FIXED',
  MEDIA = 'MEDIA',
}

export enum OptimizationObjectiveType {
  MAX_QUOTES = 'MAX_QUOTES',
  MIN_BUDGET = 'MIN_BUDGET',
}

export enum StepType {
  TABLEAU_PREPARATION = 'TABLEAU_PREPARATION',
  MODELLING_ATTRIBUTIONS = 'MODELLING_ATTRIBUTIONS',
  MODELLING_DATASET = 'MODELLING_DATASET',
  ETL = 'ETL',
  UPLOAD = 'UPLOAD',
  UPLOAD_VALIDATION = 'UPLOAD_VALIDATION',
  OPTIMIZATION = 'BUDGET_OPTIMIZATION',
}

export enum BudgetPlanType {
  PREDICTION = 'PREDICTION',
  OPTIMIZATION = 'OPTIMIZATION',
}
