import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {DialogContentType, UIDialog, UISnackBar} from '@/store/models/ui_models';
import {DialogComponentFactory} from '@/store/factories/DialogComponentFactory';

@Module({ namespaced: true, name: 'globalUIModule' })
export default class GlobalUIStore extends VuexModule {

  private $isDemoMode: boolean = false;

  private $snackBar: UISnackBar = {
    visible: false,
  };

  private $dialog: UIDialog = {};
  private $isDialogVisible: boolean = false;

  // Inactivity Vars
  private $inactivityBaseAmount: number = 1800;
  private $inactivityThreshold: number = 150;
  private $inactivityInterval: any = null;


  public get isDemoMode(): boolean {
    return this.$isDemoMode && process.env.NODE_ENV === 'development';
  }

  public get snackBar(): UISnackBar {
    return this.$snackBar;
  }

  public get isDialogVisible(): boolean {
    return this.$isDialogVisible;
  }

  public get dialog() {
    return this.$dialog;
  }

  public get inactivityBaseAmount() {
    return this.$inactivityBaseAmount;
  }

  public get inactivityThreshold() {
    return this.$inactivityThreshold;
  }

  public get inactivityInterval() {
    return this.$inactivityInterval;
  }

  @Mutation
  public setDemoMode(mode: boolean) {
    this.$isDemoMode = mode;
  }

  @Mutation
  public setSnackBar(snack: UISnackBar) {
    this.$snackBar = snack;
  }

  @Mutation
  public setDialog(newDialog: UIDialog) {
    this.$dialog = newDialog;
  }

  @Mutation
  public setDialogByContent(newContent: DialogContentType) {
    this.$dialog = DialogComponentFactory.getDialogByType(newContent);
  }

  @Mutation
  public setDialogVisible(isVisible: boolean) {
    this.$isDialogVisible = isVisible;
  }

  @Action({commit: 'setSnackBar'})
  public showSnackBar(payload: UISnackBar): UISnackBar {
    const snack: UISnackBar = payload;
    snack.visible = true;

    if (payload.timeout) {
      snack.timeout = payload.timeout;
    } else {
      snack.timeout = 5000;
    }

    return snack;
  }

  @Action({commit: 'setSnackBar'})
  public hideSnackBar(): UISnackBar {
    return {
      visible: false,
      text: '',
      color: '',
    };
  }

  @Action({commit: 'setDialogVisible'})
  public showDialog(dialog: UIDialog): boolean {
    this.context.commit('setDialog', dialog);
    return true;
  }

  @Action({commit: 'setDialogVisible'})
  public showDialogByContent(content: DialogContentType): boolean {
    this.context.commit('setDialogByContent', content);
    return true;
  }

  @Action({commit: 'setDialog'})
  public hideDialog(): UIDialog {
    this.context.commit('setDialogVisible', false);
    return {};
  }
}
