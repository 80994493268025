import Vue from 'vue';
import Vuex from 'vuex';

import GlobalUIStore from '@/store/modules/GlobalUIStore';
import UploadStore from '@/store/modules/UploadStore';
import ProcessingJobStore from '@/store/modules/ProcessingJobStore';
import AuthStore from '@/store/modules/AuthStore';
import DashboardStore from '@/store/modules/DashboardStore';
import PipelineStore from '@/store/modules/PipelineStore';
import AnnualStore from '@/store/modules/AnnualStore';
import PowerBIStore from '@/store/modules/PowerBIStore';
import { getModule } from 'vuex-module-decorators';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    AuthStore,
    DashboardStore,
    GlobalUIStore,
    PipelineStore,
    AnnualStore,
    ProcessingJobStore,
    UploadStore,
    PowerBIStore,
  },
  state: {},
  mutations: {},
  getters: {},
  actions: {},
});

export default store;
export const authModule = getModule(AuthStore, store);
export const dashboardModule = getModule(DashboardStore, store);
export const globalUIModule = getModule(GlobalUIStore, store);
export const pipelineModule = getModule(PipelineStore, store);
export const annualModule = getModule(AnnualStore, store);
export const processingJobModule = getModule(ProcessingJobStore, store);
export const uploadModule = getModule(UploadStore, store);
export const powerBiModule = getModule(PowerBIStore, store);

