var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"5"}},[_c('v-data-table',{staticClass:"mt-1",attrs:{"headers":_vm.headers,"items":_vm.filteredData,"loading":_vm.isLoading,"loading-text":"Loading... Please wait","group-by":"brand","sort-by":['brand','year'],"show-group-by":"","dense":"","items-per-page":_vm.filteredData.length,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref ){
var headers = ref.props.headers;
var on = ref.on;
return [_c('tr',[_vm._l((headers),function(header){return [_c('th',{key:'data_header_'+header.value,staticClass:"text-left pl-4 py-2"},[_vm._v(" "+_vm._s(header.text)+" "),(!!header.groupable)?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return on.group(header.value)}}},[_vm._v(" fa-layer-group ")]):_vm._e()],1)]})],2)]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"text-left",attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v(" fa-plus ")]):_c('v-icon',[_vm._v(" fa-minus ")])],1),_c('span',{staticClass:"ml-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }