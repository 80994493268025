







































import {Component, Prop, Vue} from 'vue-property-decorator';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';
import { namespace } from 'vuex-class';
import {UISnackBar} from '@/store/models/ui_models';
import LevioLabLoader from '@/components/shared/LevioLabLoader.vue';
import {DataTableHeader} from 'vuetify';
import {PipelineOutput} from '@/store/models/pipeline_models';

const pipeline = namespace('PipelineStore');
const globalUI = namespace('GlobalUIStore');

@Component({components: {LevioLabLoader, LevioLabTitle}})
export default class ProcessingOutputHistory extends Vue {
  @Prop()
  public search!: string;

  @pipeline.Getter
  public outputs!: PipelineOutput[];

  @pipeline.Action
  public fetchOutputs!: () => Promise<PipelineOutput[]>;

  @globalUI.Action
  private showSnackBar!: (payload: UISnackBar) => UISnackBar;

  private get processingOutputHeaders(): DataTableHeader[] {
    return [
      {
        text: this.$t('common.id').toString(),
        value: 'id',
        sortable: true,
      },
      {
        text: this.$t('common.name').toString(),
        value: 'name',
        sortable: true,
      },
      {
        text: this.$t('processing.output.step').toString(),
        value: 'step',
        sortable: true,
      },
      {
        text: this.$t('processing.output.creationTime').toString(),
        value: 'creationTime',
        sortable: true,
      },
    ];
  }

  private isLoading: boolean = true;
  private syncInterval!: number;

  public created() {
    this.fetchOutputs().then(() => this.isLoading = false);
    this.syncInterval = window.setInterval(() => {
      this.fetchOutputs();
    }, 10000);
  }

  public destroyed() {
    if (this.syncInterval) {
      clearTimeout(this.syncInterval);
    }
  }

  private getLocalDateStringFromJobId(jobId: string) {
    let dateString = jobId;
    if (!dateString.endsWith('Z')) {
      dateString += 'Z';
    }

    const timestamp = Date.parse(dateString);

    if (isNaN(timestamp)) {
      return jobId;
    } else {
      return new Date(timestamp).toLocaleString();
    }
  }
}
