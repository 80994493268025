


















































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import SimpleInfo from '@/components/shared/SimpleInfo.vue';
import CurrencyField from '@/components/shared/currency/CurrencyField.vue';
import Rules from '@/utils/rules';
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { Validate } from 'vuelidate-property-decorators';
import { minValue, required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate/vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { OptimizationBudgetType, OptimizationObjectiveType } from '@/store/enums/pipeline_enums';
import { BudgetDefinitionData } from '@/store/models/pipeline_models';

@Component({
  components: {
    SimpleInfo,
    CurrencyField,
  },
})
export default class BudgetDefinition extends mixins(validationMixin) {
  @Prop({
    required: true,
  })
  public value!: BudgetDefinitionData;

  @Prop({
    default: false,
  })
  public isCaisseAssurances!: boolean;

  @Prop({
    required: true,
  })
  public budgetType!: OptimizationBudgetType;

  @Prop({
    required: true,
  })
  public objective!: OptimizationObjectiveType;

  @Validate({ required, minValue: minValue(0.01) })
  private acquisitionBudget: number = 0;

  @Validate({ required, minValue: minValue(0.01) })
  private caisseAssurancesBudget: number = 0;

  @Validate({ required, minValue: minValue(1) })
  private growthPlan: number = 0;

  private commBudget: number = 0;

  private growthPlanOptional: number = 0;

  protected created(): void {
    this.acquisitionBudget = this.value.acquisitionBudget! * 100 | 0;
    this.caisseAssurancesBudget = this.value.caisseAssurance! * 100 | 0;
    this.commBudget = this.value.communicationBudget! * 100 | 0;
    this.growthPlan = this.value.growthPlan! | 0;
    this.growthPlanOptional = this.value.growthPlan! | 0;
  }

  @Emit('input')
  public emitValue(): BudgetDefinitionData {
    return {
      communicationBudget: this.commBudget,
      acquisitionBudget: this.acquisitionBudget,
      growthPlan: this.objective === OptimizationObjectiveType.MIN_BUDGET ? Number(this.growthPlan) : Number(this.growthPlanOptional),
      caisseAssurance: this.caisseAssurancesBudget,
    };
  }

  @Watch('acquisitionBudget')
  private onAcquisitionBudgetValueChanged(): void {
    this.emitValue();
  }

  @Watch('commBudget')
  private onCommBudgetValueChanged(): void {
    this.emitValue();
  }

  @Watch('requiredBudget')
  private onRequiredBudgetValueChanged(): void {
    this.emitValue();
  }

  @Watch('growthPlan')
  private onGrowthPlanValueChanged(): void {
    this.emitValue();
  }

  @Watch('growthPlanOptional')
  private onGrowthPlanOptionalValueChanged(): void {
    this.emitValue();
  }

  private get totalBudget(): number {
    return Number(this.commBudget) + Number(this.acquisitionBudget) + Number(this.caisseAssurancesBudget);
  }

  private getHigherThanZeroErrors(field: Validation & ValidationProperties<any>): string[] {
    return Rules.getHigherThanZeroMessages(field);
  }

  private get isMaxQuotes(): boolean {
    return this.budgetType === OptimizationBudgetType.FIXED && this.objective === OptimizationObjectiveType.MAX_QUOTES;
  }

  private get isMinBudget(): boolean {
    return this.budgetType === OptimizationBudgetType.FIXED && this.objective === OptimizationObjectiveType.MIN_BUDGET;
  }

  private get isMedia(): boolean {
    return this.budgetType === OptimizationBudgetType.MEDIA;
  }
}
