import Vue from 'vue';
import Router, {NavigationGuardNext, Route} from 'vue-router';
import store from '@/store/store';
import SignIn from '@/components/auth/SignIn.vue';
import BaseRouterView from '@/components/views/BaseRouterView.vue';
import CompletePassword from '@/components/auth/CompletePassword.vue';
import Error404 from '@/components/views/Error404.vue';
import UploadLandingPage from '@/components/upload/UploadLandingPage.vue';
import ProcessingLandingPage from '@/components/processing/ProcessingLandingPage.vue';
import DashboardLandingPage from '@/components/dashboard/DashboardLandingPage.vue';
import ConfigurationLandingPage from '@/components/configuration/ConfigurationLandingPage.vue';
import AttributionsLandingPage from '@/components/attributions/AttributionsLandingPage.vue';
import UploadHistoryLandingPage from '@/components/history/UploadHistoryLandingPage.vue';
import OptimizationLandingPage
  from '@/components/budgetPlan/comparitiveView/ComparativeViewLandingPage.vue';
import SupportLandingPage from '@/components/SupportLandingPage.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import BusinessContextLandingPage
  from '@/components/attributions/businessContext/BusinessContextLandingPage.vue';
import BudgetPlanLandingPage from '@/components/budgetPlan/BudgetPlanLandingPage.vue';
import ModelGovernanceLandingPage
  from "@/components/modelgovernance/ModelGovernanceLandingPage.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      beforeEnter(to, from, next) {
        if (!store.getters['AuthStore/authorized']) {
          next('/signin');
        } else {
          next('/dashboard');
        }
      },
    },
    {
      path: '/dashboard',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: DashboardLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/attributions',
      component: BaseRouterView,
      children: [
        {
          path: '/',
          component: AttributionsLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'businesscontext',
          component: BusinessContextLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/cloudsharing',
      component: BaseRouterView,
      redirect: '/cloudsharing/files',
      children: [
        {
          path: 'files',
          component: UploadHistoryLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'upload',
          component: UploadLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/support',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: SupportLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/processing',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: ProcessingLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/budgetplan',
      component: BaseRouterView,
      children: [
        {
          path: '/',
          component: BudgetPlanLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'comparativeview',
          component: OptimizationLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/configuration',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: ConfigurationLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/modelgovernance',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: ModelGovernanceLandingPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/signin',
      beforeEnter(to, from, next) {
        if (store.getters['AuthStore/authorized']) {
          next('/dashboard');
        } else {
          store.dispatch('AuthStore/fetchUser').then(() => {
            if (!store.getters['AuthStore/authorized']) {
              next();
            } else {
              next('/dashboard');
            }
          });
        }
      },
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: SignIn,
        },
      ],
    },
    {
      path: '/completepassword',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: CompletePassword,
        },
      ],
    },
    {
      path: '/forgot-password',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: ForgotPassword,
        },
      ],
    },
    {
      path: '*',
      component: BaseRouterView,
      children: [
        {
          path: '',
          component: Error404,
        },
      ],
    },
  ],
});

function verifyAuthentication(
    to: Route, next: NavigationGuardNext, callback: () => void): void {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    if (!store.getters['AuthStore/currentUserData']) {
      store.dispatch('AuthStore/fetchUser').then(() => {
        store.dispatch('AuthStore/fetchCurrentUserData').then(() => {
          if (store.getters['AuthStore/authorized']) {
            callback();
          } else {
            next('/signin');
          }
        });
      });
    } else {
      if (!store.getters['AuthStore/authorized']) {
        next('/signin');
      } else {
        callback();
      }
    }
  } else {
    callback();
  }
}

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  verifyAuthentication(to, next, () => {
        next();
      },
  );
});

export default router;
