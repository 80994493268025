








































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import pbi from 'powerbi-client';

import { UIContextualDrawerItem } from '@/store/models/ui_models';
import { PipelineOutput } from '@/store/models/pipeline_models';
import { PBIElement } from '@/store/models/powerBi_models';
import { UserData } from '@/store/models/auth_models';
import { StepType } from '@/store/enums/pipeline_enums';

import { basicFilter } from '@/utils/data/pbi_settings';
import { pbiVisualsList } from '@/utils/data/pbi_visuals';
import { reportsURL } from '@/config/app.config';

import AlertWidget from '@/components/shared/AlertWidget.vue';
import AttributionAnnualContextDialog from '@/components/attributions/AttributionAnnualContextForm.vue';
import ContextualDrawer from '@/components/shared/contextualDrawer/ContextualDrawer.vue';
import ContextualDrawerContainer from '@/components/shared/contextualDrawer/ContextualDrawerContainer.vue';
import ContextualDrawerItem from '@/components/shared/contextualDrawer/ContextualDrawerItem.vue';
import LandingPageLayout from '@/components/shared/LandingPageLayout.vue';
import OfficializationDialog from '@/components/officialization/OfficializationDialog.vue';
import PowerBIElement from '@/components/shared/PowerBIElement.vue';
import UploadFilesCard from '@/components/upload/UploadFilesCard.vue';
import ContextualDrawerDivider from '@/components/shared/contextualDrawer/ContextualDrawerDivider.vue';
import AttributionDisclaimerDialog from '@/components/attributions/AttributionDisclaimerDialog.vue';
import ModellingAttributionsDialog from '@/components/attributions/modellingTrain/ModellingAttributionsDialog.vue';
import { conceptsThemes } from '@/plugins/themes';

const auth = namespace('AuthStore');
const pipeline = namespace('PipelineStore');
const upload = namespace('UploadStore');
const globalUI = namespace('GlobalUIStore');

@Component({
  components: {
    LandingPageLayout,
    ContextualDrawerContainer,
    ContextualDrawerDivider,
    ContextualDrawerItem,
    ContextualDrawer,
    UploadFilesCard,
    OfficializationDialog,
    AttributionAnnualContextDialog,
    AttributionDisclaimerDialog,
    ModellingAttributionsDialog,
    PowerBIElement,
    AlertWidget,
  },
})
export default class AttributionsLandingPage extends Vue {
  @pipeline.Action
  private fetchLatestAttributionOutputs!: () => Promise<PipelineOutput[]>;

  @pipeline.Getter
  private latestAttributionOutputs!: PipelineOutput[];

  @pipeline.Mutation
  private clearUploadValidationData!: () => void;

  @globalUI.Getter
  private isDemoMode!: boolean;

  @upload.Action
  private getUrlForFile!: (key: string) => Promise<string>;

  @auth.Getter
  private currentUserData!: UserData;

  // Drawer Dialogs
  private isOfficializationDialogOpen: boolean = false;
  private isShowAttributionDisclaimerDialogOpen: boolean = false;
  private isAttributionTrainingDialogOpen: boolean = false;

  private filters: pbi.models.IFilter[] = [];

  private created() {
    if (this.currentUserData.brands!.length > 0) {
      this.filters = [
        basicFilter('DIM_brand', 'brand/network', this.currentUserData.brands),
      ];
    }
  }

  private get attributionModelling(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isAttributionTrainingDialogOpen = true;
      },
      icon: conceptsThemes.attribution.icon,
      tooltip: this.$t('attributions.modellingDialog.title') as string,
      badgeIcon: 'fa-plus',
      badgeColor: 'light-green',
    };
  }

  private get attributionOfficialization(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isOfficializationDialogOpen = true;
      },
      icon: conceptsThemes.officialization.icon,
      color: conceptsThemes.officialization.color,
      tooltip: this.$t('attributions.officialization.attribution') as string,
    };
  }

  private get attributionPowerBIFiles(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.getUrlForFile(reportsURL as string).then((url) => window.open(url, '_self'));
      },
      icon: 'fab fa-microsoft',
      color: '#FFBA02',
      badgeIcon: 'fa-arrow-down',
      badgeColor: 'grey',
      tooltip: this.$t('attributions.downloadPBIFile') as string,
    };
  }

  private get attributionDisclaimer(): UIContextualDrawerItem {
    return {
      onClick: () => {
        this.isShowAttributionDisclaimerDialogOpen = true;
      },
      icon: conceptsThemes.disclaimers.icon,
      tooltip: this.$t('disclaimer.title') as string,
      hasDivider: true,
      class: 'mt-2 mb-3',
    };
  }

  private async onStartModelling(): Promise<void> {
    this.isAttributionTrainingDialogOpen = false;
    await this.$router.push('/processing');
  }

  private get officializationType(): StepType {
    return StepType.MODELLING_ATTRIBUTIONS;
  }

  private get mainReport(): PBIElement {
    return pbiVisualsList.attributionsFullReport;
  }
}
