import {ConceptTheme} from '@/store/models/ui_models';

export const themeDesjardins = {
  light: {
    primary: '#134074',
    secondary: '#0E3158',
    accent: '#04874E',
    error: '#e63946',
    warning: '#FEA355',
    idle: '#707377',
    info: '#54DEFD',
    success: '#85BD03',
    theme0: '#8cc641',
    theme1: '#134074',
    theme2: '#2c7fdd',
    theme3: '#54DEFD',
    theme4: '#F37748',
    theme5: '#B8336A',
    theme6: '#F9A620',
    theme7: '#a525d5',
    themeIdle: '#cacaca',
  },
  dark: {
    primary: '#134074',
    secondary: '#54DEFD',
    accent: '#04874E',
    error: '#e63946',
    warning: '#FEA355',
    idle: '#707377',
    info: '#54DEFD',
    success: '#85BD03',
    theme0: '#8cc641',
    theme1: '#134074',
    theme2: '#2c7fdd',
    theme3: '#54DEFD',
    theme4: '#F37748',
    theme5: '#B8336A',
    theme6: '#F9A620',
    theme7: '#a525d5',
    themeIdle: '#cacaca',
  },
};

export const mainTheme = themeDesjardins;

export const conceptsThemes: { [key: string]: ConceptTheme } = {
  attribution: {
    icon: 'fa-chart-simple',
  },
  budgetPlan: {
    icon: 'fa-bullseye',
  },
  cloudSharing: {
    icon: 'fa-cloud',
  },
  dashboard: {
    icon: 'fa-tachometer-alt',
  },
  disclaimers: {
    icon: 'fa-exclamation-circle',
  },
  logOut: {
    icon: 'fa-eject',
  },
  officialization: {
    icon: 'fa-certificate',
    color: 'success',
  },
  modelGovernance: {
    icon: 'fa-circle-nodes',
  },
  optimization: {
    icon: 'fa-arrow-up-right-dots',
    color: 'theme1',
  },
  prediction: {
    icon: 'fa-binoculars',
    color: 'theme0 darken-2',
  },
  processing: {
    icon: 'fa-spinner',
  },
  schedule: {
    icon: 'fa-clock',
  },
  support: {
    icon: 'fa-question-circle',
  },
  userGuide: {
    icon: 'fa-book',
  },
}


// Couleurs cools de AMCHARTS
// am4core.color("#1BA68D"),
// am4core.color("#E7DA4F"),
// am4core.color("#E77624"),
// am4core.color("#DF3520"),
// am4core.color("#64297B"),
// am4core.color("#232555")
