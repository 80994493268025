import i18n from '@/plugins/i18n';
import {
  Brand, BudgetPlanType,
  Media,
  OptimizationBudgetType,
  OptimizationObjectiveType,
  Province, StepType,
} from '@/store/enums/pipeline_enums';
import {AmChartSerie} from '@/store/models/amcharts_models';
import {translateEnum} from '@/utils/localisation';

export interface Attribution {
  summary: AttributionSummary;
}

export interface AttributionSummary {
  years: number[];
  province: string;
  totalQuotes: number;
}

export interface ModellingTrainDatasetEvent {
  year: number | null;
  title: string | null;
  description?: string;
}

// Attribution Validation
export interface UploadValidationData {
  id: string;
  jobId: string;
  additionalProperties: UploadValidationDataAP;
}

export interface UploadValidationDataAP {
  validation_results: Record<string, UploadValidationResults>;
}

export interface UploadValidationResults {
  success: boolean;
  filename: string;
  error_codes: string[];
  error_filename: string;
}

export interface PipelineOutput {
  id: string;
  jobId: string;
  name?: string;
  jobName?: string;
  description?: string;
  step: StepType;
  filePath: string;
  folderName: string;
  fileName: string;
  sourceJobId: string[];
  additionalProperties: PipelineOutputAdditionalProperties;
  stepParams?: any;
}

export interface PipelineOutputAdditionalProperties {
  valid?: boolean;
  git_commit?: string;
  isOfficial?: boolean;
  attributionsResultsSummary?: PipelineOutputAdditionalPropertiesAttributionResultsSummary;
}

export interface PipelineOutputAdditionalPropertiesAttributionResultsSummary {
  jobId: string;
  years: number[];
  brands: Brand[];
  province: Province;
  trainYears: number[];
  totalQuotes: number;
}

export interface BudgetPlanExecutionRequest {
  outputId?: string;
  jobName?: string;
  jobDescription?: string;
  config?: BudgetPlanConfig;
  dockerImageTag?: string;
}

export interface BudgetPlanConfig {
  configName?: string;
  category: BudgetPlanType;
  year: number;
  evaluatedYear: number;
  brand: Brand;
  method: OptimizationBudgetType;
  objective: OptimizationObjectiveType;
  budgetDefinition: BudgetDefinitionData;
  budgetAllocation?: { [key: string]: number; };
  mediasBreakdown?: { [key: string]: number; };
  province?: string;
}

export interface BudgetDefinitionData {
  communicationBudget?: number;
  acquisitionBudget?: number;
  caisseAssurance?: number;
  growthPlan?: number;
}


export interface PipelineJobSummary {
  year: number;
  province: Province;
  objective: string[];
  totalBudget: number;
  totalQuotes: number;
}

export interface PipelineJobResultData {
  model: number; // NO
  province: string;
  geoTime: string; // NO
  trainYear: string;
  time: Date; // NO
  objName: OptimizationObjectiveType; // NO
  success: boolean; // Oui, optimitization n'a pas réussi
  nbSuccess: number; // Oui
  nbFail: number; // 8 fail = MAL
  objValue: number; // No
  nbIt: number; // No
  attributionType: string; // Constant, No
  maxBudget: number; // Constant, Summary
  minNQuotes: number; // Constant, Summary
  objValueMin: number; // No
  objValueMax: number; // No
  objValueMean: number; // No
  nbItMin: number; // No
  nbItMax: number; // No
  nbItMean: number; // No
  duration: number; // No, Job
  mediaName: Media;
  periode: number; // Janvier
  budget: number; // Medias Actuel
  totalQuotes: number; // Quotes Media
  cccInflexions: number; // No
  webInflexions: number; // No
  eaxInflexions: number; // No
  cccQuoteReturns: number; // No
  webQuoteReturns: number; // No
  eaxQuoteReturns: number; // No
}

export interface BudgetOptimizationMediaDetail {
  media?: string;
  trimestre1?: number;
  trimestre2?: number;
  trimestre3?: number;
  trimestre4?: number;
  trimestreTotal?: number;
  trimestrePercent1?: number;
  trimestrePercent2?: number;
  trimestrePercent3?: number;
  trimestrePercent4?: number;
  quotes1?: number;
  quotes2?: number;
  quotes3?: number;
  quotes4?: number;
  quotesTotal?: number;
  isSuccess?: boolean;
  hasWarning?: boolean;
}

export interface BudgetOptimizationInfo {
  output?: PipelineOutput;
  config?: BudgetPlanConfig;
  children?: string[];
  results?: BudgetOptimizationResult;
}

export class BudgetOptimizationResult {
  public summary!: PipelineJobSummary;
  public data!: PipelineJobResultData[];

  public get isSuccess(): boolean {
    return this.data[0] ? this.data[0].success : false;
  }

  public get summaryInfosList(): Array<{ label: string, value: string }> {
    return [
      {
        label: i18n.t('common.time.year') as string,
        value: this.summary.year.toString(),
      },
      {
        label: i18n.t('common.location.province') as string,
        value: this.summary.province,
      },
      {
        label: i18n.t('common.objective') as string,
        value: translateEnum(this.summary.objective[0], 'objective') as string,
      },
      {
        label: i18n.t('common.totalBudget') as string,
        value: i18n.n(this.summary.totalBudget, 'currency'),
      },
      {
        label: i18n.t('common.totalQuotes') as string,
        value: Math.round(this.summary.totalQuotes).toString(),
      },
    ];
  }

  public get dataBudgetTrimestreMedia(): BudgetOptimizationMediaDetail[] {
    const chartData: BudgetOptimizationMediaDetail[] = [];
    this.data.forEach((item: PipelineJobResultData) => {
      let chartEntry: any = chartData.find(
          (chartItem) => chartItem.media === item.mediaName);
      if (chartEntry) {
        const index: number = chartData.findIndex(
            (chartItem) => chartItem.media === item.mediaName)!;
        chartEntry['trimestre' + item.periode] = item.budget;
        chartEntry['quotes' + item.periode] = item.totalQuotes;
        chartEntry.isSuccess = chartEntry.isSuccess ?
            item.success :
            chartEntry.isSuccess;
        chartEntry.hasWarning = chartEntry.hasWarning ?
            item.nbFail < item.nbSuccess :
            chartEntry.hasWarning;
        chartData[index] = chartEntry;
      } else {
        chartEntry = {};
        chartEntry.media = item.mediaName;
        chartEntry['trimestre' + item.periode] = item.budget;
        chartEntry['quotes' + item.periode] = item.totalQuotes;
        chartEntry.isSuccess = item.success;
        chartEntry.hasWarning = item.nbFail < item.nbSuccess;
        chartData.push(chartEntry);
      }
    });
    chartData.forEach((item) => {
      item.trimestrePercent1 = item.trimestre1! / item.trimestreTotal! * 100;
      item.trimestrePercent2 = item.trimestre2! / item.trimestreTotal! * 100;
      item.trimestrePercent3 = item.trimestre3! / item.trimestreTotal! * 100;
      item.trimestrePercent4 = item.trimestre4! / item.trimestreTotal! * 100;
    });
    return chartData;
  }

  public getSerieMediasTrimestresSerie(
      symbolTooltip: string = '',
      valuePrefix: string = 'trimestre'): AmChartSerie[] {
    return [
      {
        name: i18n.t('optimization.graphs.quarterLabel') + ' 1',
        valueY: valuePrefix + '1',
        stacked: true,
        pointerOrientation: 'vertical',
        toolTipText: '{name}: {valueY.formatNumber(\'#,###.00\')}' +
            symbolTooltip,
      },
      {
        name: i18n.t('optimization.graphs.quarterLabel') + ' 2',
        valueY: valuePrefix + '2',
        stacked: true,
        pointerOrientation: 'vertical',
        toolTipText: '{name}: {valueY.formatNumber(\'#,###.00\')}' +
            symbolTooltip,
      },
      {
        name: i18n.t('optimization.graphs.quarterLabel') + ' 3',
        valueY: valuePrefix + '3',
        stacked: true,
        pointerOrientation: 'vertical',
        toolTipText: '{name}: {valueY.formatNumber(\'#,###.00\')}' +
            symbolTooltip,
      },
      {
        name: i18n.t('optimization.graphs.quarterLabel') + ' 4',
        valueY: valuePrefix + '4',
        stacked: true,
        pointerOrientation: 'vertical',
        toolTipText: '{name}: {valueY.formatNumber(\'#,###.00\')}' +
            symbolTooltip,
      },
    ];
  }

  public getComparativeData(
      parent: BudgetOptimizationMediaDetail[],
      category: string,
      fieldToCompare: string,
      nameCurrent: string,
      nameCompared: string,
  ): any[] {
    const comparativeData: any[] = [];
    const currentData = this.dataBudgetTrimestreMedia;

    currentData.forEach((data: BudgetOptimizationMediaDetail) => {
      const comparedItem: BudgetOptimizationMediaDetail | undefined =
          parent.find((parentItem) => parentItem.media === data.media);
      if (comparedItem) {
        const item: any = {};
        item[category] = data[category];
        item[nameCurrent] = data[fieldToCompare];
        item[nameCompared] = comparedItem[fieldToCompare];
        comparativeData.push(item);
      }
    });

    return comparativeData;
  }

  public getSerieMediasTotalSerie(): AmChartSerie[] {
    return [
      {
        name: i18n.t('optimization.graphs.previousOptimizationLabel') as string,
        valueY: 'optimization',
        stacked: false,
        pointerOrientation: 'vertical',
        toolTipText: '{name}: {valueY.formatNumber(\'#,###.00\')}$',
      },
    ];
  }
}
