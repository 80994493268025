const EN_LOCALES = {
  app: {
    title: 'High Impact Marketing',
  },
  common: {
    brand: 'Brand',
    default: 'Default',
    description: 'Description',
    error: 'Error',
    id: 'ID',
    jobId: 'Job ID',
    message: 'Message',
    name: 'Name',
    objective: 'Objective',
    optional: 'optional',
    quotes: 'quotes',
    results: 'Results',
    status: 'Status',
    summary: 'Summary',
    title: 'Title',
    titleExample: 'This is how your title will appear in the reports:',
    totalBudget: 'Total Budget',
    totalQuotes: 'Total Quotes',
    type: 'Type',
    upload: 'Upload',
    year: 'Year',
    years: 'Years',
    author: 'Author',
    cta: {
      accept: 'Accept',
      close: 'Close',
      continue: 'Continue',
      create: 'Create',
      delete: 'Delete',
      officialize: 'Officialize',
      proceed: 'Proceed',
      redo: 'Go Back',
      select: 'Select an item',
      save: 'Save',
      send: 'Send',
      submit: 'Submit',
      upload: 'Upload Files',
    },
    table: {
      itemsPerPage: 'Rows per page',
    },
    location: {
      province: 'Province',
    },
    time: {
      year: 'Year',
      referenceYear: 'Reference Year',
    },
    validation: {
      fieldRequired: 'Missing field',
      duplicateTitleInList: 'There are multiple instances of \'{value}\'. The <strong>{valueType}</strong> needs to be unique.',
      usedTitleInList: '\'{value}\' has already been used for another job. The <strong>{valueType}</strong> needs to be unique.',
      email: {
        required: 'Your e-mail is required.',
        invalid: 'E-mail must be valid.',
      },
      confirmationCode: {
        required: 'Confirmation code is required.',
        numeric: 'Confirmation code is numeric.',
      },
      password: {
        required: 'Your password is required.',
        minLength: 'Password must have at least eight characters.',
        invalid: 'Password must have at least one uppercase letter, one lowercase letter and one number.',
        confirm: 'You must confirm the password',
        matchPassword: 'The passwords should match.',
      },
      number: {
        notZero: 'The value cannot be 0.',
        higherThanZero: 'This value has to be higher than 0.',
      },
    },
  },
  advancedTools: {
    title:'Advanced Tools',
    perspective: {
      createLabel: 'New monthly perspective',
      dialog: {
        title: 'Global quotes objectives put into MROI perspective',
        objective: 'Global Quote Objective',
        attributedTitle: 'Attributed Percentage',
        fillInstructions: 'Fill the Year, Brand and Global Quote Objective fields for more options.',
      },
      results: {
        title: 'Monthly Perspective for Quotes Objectives',
      },
    },
  },
  agreementWarning: {
    title: 'Warning',
    content: '<ul>\n' +
        '      <li>\n' +
        '          The following insights focus on short term effects of marketing investments on quotes and does not take in\n' +
        '          consideration other aspects that are also key to marketing efforts:\n' +
        '        <ul class="grey--text text--darken-1">\n' +
        '            <li>Raising awareness (brand, product, solutions);</li>\n' +
        '            <li>Building on consideration to the brand;</li>\n' +
        '            <li>\n' +
        '                Nuances between advertising campaigns (e.g. campaign objectives, types of creative used, media and placement\n' +
        '                strategies used, etc.).\n' +
        '            </li>\n' +
        '        </ul>\n' +
        '      </li>\n' +
        '      <li>\n' +
        '          The data presented in the High Impact Marketing platform should be used as a tool and guideline to inform\n' +
        '          marketing and investment decision, and should be coordinated with other research.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '          The results of the Marketing Mix Modeling does not replace the need for business experts and is not a “silver\n' +
        '          bullet” to address every business question.\n' +
        '      </li>\n' +
        '    </ul>',
  },
  annualContext: {
    form: {
      titleEN: 'English Title',
      titleFR: 'French Title',
      descriptionEN: 'English Description',
      descriptionFR: 'French Description',
    },
    title: 'Edit annual contexts',
  },
  attributions: {
    title: 'Allocations and Attributions',
    subtitle: 'Results of last budget allocations and generated attributions, the level of investment made by channel and media as well as the performance of the various marketing efforts.',
    current: 'Attributions Results',
    create: 'Create a new attribution',
    yearlyData: 'Yearly Attributions',
    downloadPBIFile: 'Download PBI File',
    filterYear: 'Officialization Year',
    filterBrand: 'Brand Filter',
    notFound: 'No Matching Attribution!',
    analysis: {
      title: 'Attribution in Analysis',
    },
    businessContext: {
      title: 'Business Context',
    },
    upload: {
      button: 'Processing job',
      title: 'Upload the required files',
      subtitle: 'Upload all required files to compute an ETL and process an attribution modelling job.',
    },
    modellingDialog: {
      title: 'Model an attribution',
      countLabel: 'Number of planned modelling jobs',
    },
    officialization: {
      attribution: 'Officialize an Attribution',
      etl: 'Officialize an ETL',
      attributionTooltip: 'Select the budget attributions you want to officialize.',
      dialog: {
        title: 'Are you sure?',
        text1: 'Do you wish to officialize this job?',
        text2: 'This action will impact the reports and graphics.',
        cancel: 'Cancel',
      },
    },
  },
  auth: {
    username: 'Email',
    password: 'Password',
    newPassword: 'Password',
    confirmPassword: 'Confirm Password',
    login: {
      cta: 'Login',
    },
    completePassword: {
      title: 'Complete Password',
    },
    forgotPassword: {
      title: 'Forgot Password',
      sendCode: 'Send Code',
      code: 'Confirmation Code',
      alert: {
        title: 'Don\'t close this tab.',
        text: 'An email with a reset code will be sent to your inbox. This can take up to 2 minutes. ' +
            'Enter your code in the field below and type your new password.',
        junkMailCaption: 'If you didn\'t receive the email, please check your junk mail.',
      },
    },
    error: {
      NotAuthorizedException: 'Incorrect username or password.',
      Unknown: 'An error occured while logging in.',
    },
  },
  budgetPlan: {
    title: 'Budget Planning',
    prediction: {
      ctaOfficialize: 'Officialize a Predicted Budget Plan',
    },
    optimization: {
      ctaOfficialize: 'Officialize an Optimized Budget Plan',
    },
    comparativeView: {
      title: 'Comparative View',
    },
  },
  cloudSharing: {
    title: 'File Sharing',
    subtitle: 'Upload files that need to be shared with the team.',
    files: {
      title: 'Files',
      description: 'List of uploaded files',
    },
  },
  csv: {
    sample: 'Sample',
  },
  dashboard: {
    title: 'Dashboard',
    logout: 'Logout',
  },
  disclaimer: {
    title: 'Disclaimers',
    modeling: {
      title: 'Modeling disclaimer',
      content: '<ul class="text-body-1">\n' +
          '       <li>\n' +
          '         The following insights focus on short term effects of marketing spending on quotes and does not take' +
          '         in consideration other aspects that are also key to marketing efforts:\n' +
          '         <ul class="text-body-1 grey--text text--darken-2">\n' +
          '           <li>Raising awareness (brand, product, solutions);</li>\n' +
          '           <li>Building on consideration to the brand;</li>\n' +
          '           <li>\n' +
          '             Nuances between advertising campaigns (e.g. campaign objectives, types of creative used, media and' +
          '             placement strategies used, etc.).\n' +
          '           </li>\n' +
          '         </ul>\n' +
          '       </li>\n' +
          '       <li>\n' +
          '         The data presented in the High Impact Marketing platform should be used as a tool and guideline to' +
          '         inform marketing and investment decision, and should be coordinated with other research.\n' +
          '       </li>\n' +
          '       <li>\n' +
          '         The results of the Marketing Mix Modeling does not replace the need for business experts and is not a' +
          '         “silver bullet” to address every business question.\n' +
          '       </li>\n' +
          '     </ul>',
    },
    modelDetails: {
      title: 'Model details',
      content: '       <h4>DA</h4>\n' +
          '            <ul class="text-body-1">\n' +
          '              <li>\n' +
          '                Quotes modeled for DA and CA marketing efforts includes:\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Auto and property quotes</li>\n' +
          '                  <li>For the CCC and Web channels</li>\n' +
          '                  <li>Weekly model beginning January 2015 through December 2020</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                Model dimensions are as follows:\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Base: FSA</li>\n' +
          '                  <li>Markets: MTL, Quebec and Rest of Quebec</li>\n' +
          '                  <li>Distribution channel: CCC and Web</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '            </ul>\n' +
          '            <h4>DI-Direct</h4>\n' +
          '            <ul class="text-body-1">\n' +
          '              <li>\n' +
          '                Quotes modeled for DI-Direct marketing efforts includes:\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Auto and property quotes</li>\n' +
          '                  <li>For the CCC, Web and KAN channels</li>\n' +
          '                  <li>Weekly model beginning January 2015 through December 2020</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                Model dimensions are as follows:\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Base: FSA</li>\n' +
          '                  <li>Markets: GTA, Ottawa, Rest of Ontario</li>\n' +
          '                  <li>Distribution channel: CCC, Web and KAN channels</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '            </ul>\n' +
          '            <h4>DI-DAN</h4>\n' +
          '            <ul class="text-body-1">\n' +
          '              <li>\n' +
          '                Quotes modeled for DI_DAN marketing efforts includes:\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Auto and property quotes</li>\n' +
          '                  <li>For the CCC and Web channels</li>\n' +
          '                  <li>Weekly model beginning January 2015 through December 2020</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                Model dimensions are as follows:\n' +
          '                <ul class="text-body-1 grey--text text--darken-2">\n' +
          '                  <li>Base: FSA</li>\n' +
          '                  <li>Markets: GTA, Ottawa, Rest of Ontario</li>\n' +
          '                  <li>Distribution channel: CCC and Web channels</li>\n' +
          '                </ul>\n' +
          '              </li>\n' +
          '            </ul>',
    },
    lexicon: {
      title: 'Definitions',
      brands: {
        title: 'Brands',
        content: {
          da: {
            label: 'DA',
            description: 'Desjardins Assurance',
          },
          dan: {
            label: 'DI-DAN',
            description: 'Desjardins Agent Network',
          },
          di: {
            label: 'DI-DIRECT',
            description: 'Desjardins Insurance',
          },
        },
      },
      medias: {
        title: 'Medias',
        content: {
          addressedDm: {
            label: 'Adressed_DM',
            description: 'Mail addressed to DGIG members and clients. The volume used in the platform, is the actual number of mails sent by RTA.',
          },
          email: {
            label: 'Email',
            description: 'Email sent to a database digitally. The volume used in the platform, is the actual number of emails sent by RTA.',
          },
          newspaper: {
            label: 'Newspaper',
            description: 'Media placement in magazines and newspapers. For these media, the cost of the media placement is used as volume.',
          },
          outdoor: {
            label: 'Outdoor',
            description: 'This media refers to billboards on highways and main roads. For these media, the cost of media placement is used as volume.',
          },
          organic: {
            label: 'Organic',
            description: 'Organic media combines the following media: Google trends, holidays, price variation (ON) and spatiotemporal data (temporal and demographic data)',
          },
          phone: {
            label: 'Phone',
            description: 'Number of calls made by the telemarketing team. The volume used in the platform is the actual number of calls made by RTA.',
          },
          ppcBranded: {
            label: 'PPC Branded',
            description: 'Pay per click (PPC) branded is the practice of bidding on terms or keywords branded by the organization or a competitor.',
          },
          unbrandedPC: {
            label: 'Unbranded PPC',
            description: 'Pay per click (PPC) unbranded is the practice of bidding on much more generic terms.',
          },
          radio: {
            label: 'Radio',
            description: 'This medium refers to the transmission of advertising messages via public radio. The volume used is the Gross Rating Point (GRP), which is an estimate of the proportion of the population reached with the message.',
          },
          referrals: {
            label: 'Referrals',
            description: 'Quotes made via the referral of another Desjardins service. The volume used in the platform is the actual number of referrals produced by RTA.',
          },
          tv: {
            label: 'TV',
            description: 'This medium is for the transmission of advertising messages via television. The volume used is the Gross Rating Point (GRP), which is an estimate of the proportion of the population reached by the message.',
          },
          unaddressedDm: {
            label: 'Unadressed_DM',
            description: 'Unaddressed mail. Unaddressed mail data is aggregated differently by province.',
          },
          webAd: {
            label: 'Web-ad',
            description: 'This media refers to web ads on interactive sites, social networks and search pages as well as other web campaigns using display ads. Since we do not have volume data for web ads, cost is used.',
          },
        },
      },
      quotesChannels: {
        title: 'Quotes Channels',
        content: {
          ccc: {
            label: 'CCC',
            description: 'Call Center',
          },
          eax: {
            label: 'EAX',
            description: 'Exclusive Agent Network',
          },
          kan: {
            label: 'KAN',
            description: 'Kanetix',
          },
          web: {
            label: 'WEB',
            description: 'DGIG website where you can request a quote online.',
          },
        },
      },
      others: {
        title: 'Others',
        content: {
          fsa: {
            label: 'FSA',
            description: 'Forward Sortation Area, it is the first 3 characters of the postal code. The FSA is the same as the RTA.',
          },
          totalQuotes: {
            label: 'Total Quotes',
            description: 'Actual number of quotes generated.',
          },
          simulatedQuotes: {
            label: 'Simulated Quotes',
            description: 'Number of quotes estimated by the modeling',
          },
        },
      },
    },
  },
  enum: {
    brands: {
      da: 'DA',
      dan: 'DI-DAN',
      di: 'DI-DIRECT',
      synergy: 'Synergy DI-Direct and DI-DAN',
    },
    budgetPlanType: {
      prediction: 'Prediction',
      optimization: 'Optimization',
    },
    media: {
      addressedDm: 'Addressed DM',
      unaddressedDm: 'Unaddressed DM',
      postal: 'Postal',
      email: 'Email',
      billboard: 'Billboard',
      phone: 'Phone',
      radio: 'Radio',
      tv: 'Television',
      newspaper: 'Newspaper',
      outdoor: 'Outdoor',
      ppcBranded: 'Branded PPC',
      ppcUnbranded: 'Unbranded PPC',
      references: 'References',
      adWeb: 'Web Advertising',
    },
    methodType: {
      fixed: 'Fixed Constraints',
      media: 'Budget per Media',
    },
    objective: {
      maxQuotes: 'Maximize the Quotes',
      minBudget: 'Minimize the Budget',
    },
    province: {
      qc: 'Quebec',
      on: 'Ontario',
      nb: 'New Brunswick',
      ab: 'Alberta',
      other: 'Other',
    },
    errorType: {
      test_cols: 'The file you provided is missing or has extra columns.',
      test_no_nan: 'The file you provided has missing data.',
      test_no_neg: 'The file you provided contains negative values.',
      test_isocalendar: 'The file you provided has weeks not respecting ISO specifications.',
      test_cat_values: 'The file you provided contains unknown categories.',
    },
    requestErrorType: {
      default: 'A server-side error occured.',
      titleDuplicate: 'This title/name is already used.',
    },
    stepType: {
      etl: 'ETL',
      modellingAttributions: 'Modelling Attributions',
      modellingDataset: 'Modelling Dataset',
      optimization: 'Optimization',
      tableauPreparation: 'Tableau Preparation',
      upload: 'Upload',
      uploadValidation: 'Upload Validation',
    },
  },
  etl: {
    jobDialog: {
      title: 'Start an ETL Calculation',
      ctaButton: 'Start Calculations',
      uploadsSelect: 'Uploads',
      tooltip: 'If your previous upload is not in the list, try refreshing in 10 seconds.',
    },
    suggestionDialog: {
      title: 'Do you want to start ETL calculations?',
      text: 'Would you like to compute a new dataset (ETL), save the upload or delete the files?',
      etlButton: 'Compute ETL',
    },
  },
  files: {
    addressedDM: 'CSV file that contains the volume of direct addressed mail sent to each FSA for every brand and product.',
    clv: 'CSV file containing the global CLV value for each brand and quote channel.',
    desjardinsHolidays: 'CSV file containing the number of public holidays for each week.',
    email: 'CSV file that contains the volume of emails sent to each FSA for every brand and product.',
    geoPopulation: 'CSV file containing the population of each FSA according to the last census.',
    googleTrends: 'CSV file that contains the search volume of specific search queries. The data is the same for every FSA within a same week.',
    marketingExpenses: 'CSV file that contains the production cost and media cost associated with each media for a specific year and province.',
    phone: 'CSV file that contains the phone volume and cost made for each FSA and presented by brand and product.',
    ppc: 'CSV file that contains the total clicks, prints and cost per PPC category and engine for each FSA based on the proportion of their population and presented by brand and product.',
    priceIndex: 'CSV file that presents the price variation for insurance based on the previous quarter. The price variation is the same for each FSA within a same period. This data is only available for DI products.',
    print: 'CSV file that contains the cost for a newspaper, magazine and outdoor media for each FSA based on the proportion of their population and presented by brand and product.',
    productionCosts: 'CSV file containing the production costs for each media.',
    quotes: 'CSV file that contains the volume of quotes generated for each FSA by quote channels, brand and product.',
    radio: 'CSV file that contains the GRP for each FSA based on the campaign exposure and population reached for a specific radio station. The GRP for each FSA is based on the proportion of their population.',
    references: 'CSV file that contains the volume of direct and deferred references made for each FSA presented by brand and product.',
    sales: 'CSV file that contains the volume of sales generated for each FSA by sale channels, brand and product.',
    television: 'CSV file that contains the GRP for each FSA based on the campaign exposure and population reached for a specific TV station. The GRP for each FSA is based on the proportion of their population.',
    unaddressedDM: 'CSV file that contains the volume of direct unaddressed mail sent to each FSA for every brand and product.',
    webAdvertising: 'CSV file that contains the web advertising clicks, prints and cost for interactive, social and search investments for each FSA. The volume for each FSA is based on the proportion of their population.',
  },
  inactivityDialog: {
    title: 'Are you there?',
    text1: 'Due to inactivity your login session will expire. ' +
        'Click continue to remain logged in.',
    text2: 'seconds remaining',
    text3: 'You\'ve been logged out due to inactivity.',
  },
  modelGovernance: {
    title: 'Model Performance',
    attributions: 'Attributions',
    optimizations: 'Optimizations',
  },
  optimization: {
    title: 'Optimization',
    create: 'Create a new budget plan',
    optimize: 'Optimize',
    downloadButton: 'Download CSV',
    dialog: {
      title: 'New Budget Plan',
      subtitle: 'Configure and create a budget plan.',
      job: 'Officialized Attribution Job',
      jobTooltip: 'Select an officialized attribution job to use for this budget plan.',
      budgetPlanType: 'Type of budget plan',
      referenceYearLabel: 'Year of reference for budget',
      referenceYearHint: 'The selected year of reference must represent the scenario that will be created.',
      method: 'Planning Method',
      parameters: 'Parameters',
      budgetDefinition: 'Budget Definition',
      modellingVariables: 'Modelling Variables',
      informativeVariables: 'Informative Variables',
      acquisitionBudget: 'Acquisition Budget',
      caisseAssurancesLabel: 'Caisse Assurances Budget',
      growthPlan: 'Growth Plan',
      commBudget: 'Client Communication Budget',
      withoutCA: 'Excluding Caissassurances',
      total: 'Total Marketing Budget',
      budgetAllocation: 'Percentage of Budget Allocation By Province',
      modelProvince: 'Modeled Province',
      errorAllocation: 'The sum of the allocations is <span class="font-weight-bold">{total}%</span>. It should be 100%.',
      remainingBudget: 'Remaining Acquisition Budget for {province}',
      remainingBudgetTooltipDA: 'Includes total Caisse Assurance budget + allocated budget for province of Quebec',
      budgetBreakdown: 'Budget Breakdown',
      remaining: 'Acquisition budget not allocated',
      requiredBudget: 'Required Budget (Optional)',
      totalQuotesObjectives: 'Total Quotes Objective',
      fillInstructionsPart1: 'Choose an optimization method and objective for more options',
      fillInstructionsAllocation: 'You need to provide an Acquisition Budget in order proceed further.',
      fillInstructionsBreakDown: 'You need to provide an allocation percentage for the modeled province in order proceed further.',
    },
    graphs: {
      quarterLabel: 'Quarter',
      mediasLabel: 'Medias',
      totalBudgetLabel: 'Total Budget',
      totalQuotesLabel: 'Total Quotes',
      titleMediasQuarters: 'Distribution of media by quarter',
      titleMediasQuartersPercent: 'Proportion of media allocation by quarter',
      titleTableBudget: 'Detailed distribution of medias',
      titleTableQuotes: 'Detailed quotes by medias',
      previousOptimizationLabel: 'Initial Optimization',
    },
  },
  processing: {
    title: 'Processing Jobs',
    subtitle: 'Inspect and manage processing jobs.',
    creationTime: 'Creation Time',
    duration: 'Duration',
    search: 'Search',
    tab: {
      jobs: 'Jobs',
      outputs: 'Outputs',
    },
    output: {
      step: 'Step',
      creationTime: 'Creation Time',
    },
  },
  schedule: {
    title: 'Refresh schedule for PowerBI reports',
  },
  support: {
    title: 'Support',
    subtitle: 'Documentation & Help For The Platform',
    userGuide: {
      tooltip: 'User Guide',
    },
    noRole: 'You have not been assigned a role. Please contact us or the representatives of this project at Desjardins.',
    copyToClipboard: 'The contact email has been copied to your clipboard.',
    openMailingApp: 'Your message to our support team has been transferred to your selected mailing app.',
  },
  upload: {
    title: 'File Upload',
    subtitle: 'Prepare files for uploading and inspect upload history.',
    text1: 'Drag and drop files here',
    text2: 'Missing files',
    tooltip: 'Example',
    validate: 'Accept',
    validating: 'Validating...',
    history: {
      date: 'Date',
      uploader: 'Author',
      action: 'Actions',
      fileName: 'Files',
      folderName: 'Folder Name',
      deleteDialogTitle: 'Delete Upload',
      deleteDialogText: 'Are you sure you want to delete this upload?',
      deleteDialogCancelButton: 'Cancel',
      deleteDialogConfirmButton: 'Confirm',
      verified: 'Validated',
      historyInfoTitle: 'Uploaded list',
    },
    type: {
      label: 'Upload Type',
      verified: 'Verified',
      unverified: 'Unverified',
    },
    error1: 'No match found for one or more selected file.',
    error2: 'One or more file was already selected.',
    error3: 'An error occured while uploading.',
    loading: {
      title: 'Uploading files...',
      done: 'Preparing files...',
    },
  },
};

export default EN_LOCALES;
