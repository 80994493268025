





























import {Vue, Component, Prop} from 'vue-property-decorator';
import LevioLabTitle from '@/components/shared/LevioLabTitle.vue';

@Component({
  components: {
    LevioLabTitle,
  },
})
export default class LevioLabLoader extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private progress!: string;

  @Prop()
  private icon!: string;

  @Prop()
  private color!: string;

  @Prop()
  private value!: string;

  private get getTitle(): string {
    if (this.progress) {
      return this.title + ' (' + this.progress + '%)';
    } else {
      return this.title;
    }
  }
}
