



















































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Rules, { passwordValid } from '@/utils/rules';
import { NewPasswordForm } from '@/store/models/auth_models';
import { Validate } from 'vuelidate-property-decorators';
import { email, minLength, numeric, required, sameAs } from 'vuelidate/lib/validators';

const auth = namespace('AuthStore');

@Component({
  components: {},
})
export default class ForgotPassword extends Vue {
  @auth.Action
  public forgotPassword!: (email: string) => Promise<void>;

  @auth.Action
  public submitNewPassword!: (newPasswordData: NewPasswordForm) => Promise<void>;

  private mainFormValid: boolean = false;
  private formValidCanSendCode: boolean = false;
  private confirmationCodeSent: boolean = false;

  @Validate({ required, email })
  private email: string = '';

  @Validate({ required, numeric })
  private code: string = '';

  @Validate({ required, minLength: minLength(8), passwordValid })
  private newPassword: string = '';

  @Validate({ required, sameAs: sameAs('newPassword') })
  private confirmPassword = '';

  public mounted() {
    if (this.$route.query.code && typeof this.$route.query.code === 'string') {
      this.code = this.$route.query.code as string;
      this.confirmationCodeSent = true;
    }
  }

  private goBack(): void {
    return this.$router.go(-1);
  }

  private validateForm(): void {
    const formToSend: NewPasswordForm = {
      email: this.email.toLowerCase(),
      code: this.code,
      newPassword: this.newPassword,
    };

    this.submitNewPassword(formToSend).then(() => {
      this.$router.push('/signin');
    }).catch(() => {
      // removing trace
    });
  }

  private resendCode(): void {
    const self = this;
    this.forgotPassword(this.email.toLowerCase()).then(() => {
      self.confirmationCodeSent = true;
    }).catch(() => {
      // removing trace
    });
  }

  private get emailErrors(): string[] {
    return Rules.getEmailValidationMessages(this.$v.email);
  }

  private get passwordErrors(): string[] {
    return Rules.getPasswordValidationMessages(this.$v.password);
  }

  private get codeErrors(): string[] {
    return Rules.getCodeValidationMessages(this.$v.code);
  }

  private get confirmPasswordErrors(): string[] {
    return Rules.getConfirmPasswordValidationMessages(this.$v.confirmPassword);
  }
}
