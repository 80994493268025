































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class LanguageSwitch extends Vue {

  get language(): string {
    return this.$i18n.locale;
  }

  set language(locale: string) {
    this.$i18n.locale = locale;
  }

  public items: any = {
    fr: {
      name: 'Français',
      locale: 'fr',
    },
    en: {
      name: 'English',
      locale: 'en',
    },
  };

  public chooseLanguage(language: any) {
    this.$i18n.locale = language.locale;
  }

  protected created(): void {
    // console.log('created', this.$i18n.locale);
  }
}
